import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getTheme, callAPI } from 'helpers'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Components
import { Horizontal, Vertical } from 'components/Centered'
import { Input, Icon, Button, message } from 'antd'

export default function () {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loggingIn, setLoggingIn] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState()
  const [passwordError, setPasswordError] = useState()

  useEffect(() => {
    if (Cookies.get('accessToken')) {
      history.replace('/online-classes')
    }
  })

  const _onLogin = async () => {
    if (loggingIn) return
    const _usernameError = !username && 'กรุณากรอกข้อมูลชื่อบัญชีผู้ใช้'
    const _passwordError = !password && 'กรุณากรอกข้อมูลรหัสผ่าน'

    setUsernameError(_usernameError)
    setPasswordError(_passwordError)

    if (![_usernameError, _passwordError].some(_error => _error)) {
      setLoggingIn(true)
      try {
        const { result: { token, ...me } } = await callAPI({
          method: 'POST',
          url: '/admins/login',
          body: { username, password }
        })
        Cookies.set('accessToken', token)

        dispatch({ type: 'UPDATE_ME', me })
        setLoggingIn(false)
        history.replace('/online-classes')
      } catch (error) {
        console.error('Error while trying to login.', error)
        setLoggingIn(false)
        message.error('เข้าสู่ระบบไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
      }
    }
  }

  const _onKeyPress = (e) => {
    if (e.key === 'Enter') {
      _onLogin()
    }
  }

  return (
    <Container>
      <Horizontal>
        <Vertical>
          <LoginBox>
            <LoginBoxLogo src='/images/logo_wide.jpg' />
            <LoginBoxForm>
              <LoginBoxFormGroup>
                <LoginBoxFormGroupLabel>ชื่อบัญชีผู้ใช้</LoginBoxFormGroupLabel>
                <Input
                  onKeyPress={_onKeyPress}
                  onChange={e => setUsername(e.target.value)}
                  value={username}
                  prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Username' />
                {usernameError && <ErrorText>{usernameError}</ErrorText>}
              </LoginBoxFormGroup>
              <LoginBoxFormGroup>
                <LoginBoxFormGroupLabel>รหัสผ่าน</LoginBoxFormGroupLabel>
                <Input
                  onKeyPress={_onKeyPress}
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type='password'
                  placeholder='Password' />
                {passwordError && <ErrorText>{passwordError}</ErrorText>}
              </LoginBoxFormGroup>
              <ButtonRow>
                <CustomButton type='primary'
                  onClick={_onLogin}
                  loading={loggingIn}>
                  Login
                </CustomButton>
              </ButtonRow>
            </LoginBoxForm>
          </LoginBox>
        </Vertical>
      </Horizontal>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${getTheme`colors.background`};
`
const LoginBox = styled.div`
  background-color: white;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid ${getTheme`colors.border`};
  padding: 12px;
  @media(min-width: 425px) {
    width: 400px;
  }
  @media(max-width: 424px) {
    width: 90vw;
  }
`
const LoginBoxLogo = styled.img`
  width: 100%;
  object-fit: cover;
`
const LoginBoxForm = styled.div`
  margin-top: 10px;
  text-align: left;
`
const LoginBoxFormGroup = styled.div`
  margin-bottom: 10px;
`
const LoginBoxFormGroupLabel = styled.strong`
  display: block;
  margin-bottom: 10px;
`
const ButtonRow = styled.div`
  text-align: right;
`
const CustomButton = styled(Button)`
  background-color: ${getTheme`colors.primary`};
`
const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
`
