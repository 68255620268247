import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import FormSelect from 'components/FormSelect'
import { callAPI, getTheme } from 'helpers'
import { Button, Checkbox, Modal, Empty, message, Popover, Spin } from 'antd'
import Spinner from 'components/Spinner'
import CustomPopconfirm from 'components/CustomPopconfirm'

// Data
// import template from 'data/seats_default.json'
import theme from 'theme'

// Hooks
import { useEditable } from 'hooks/useEditable'

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const semesterOptions = Array(6)
  .fill()
  .map(( _, index) => `${moment().year() + 543 + index - 1}`)
  .reduce((semesterCodes, year) => semesterCodes.concat([1, 2, 3, 4].map(i => `${year}/${i}`)), [])
const colors = {
  empty: '#cccccc',
  scholarship: '#ccffcc',
  vip: '#ffdddd'
}
const seatTypeMap = {
  vip: ' VIP',
  scholarship: 'นักเรียนทุน',
  regular: 'ธรรมดา'
}
  
const Seating = () => {
  const editable = useEditable()

  const [semesterCode, setSemesterCode] = useState()
  const [classOptions, setClassOptions] = useState([])
  const [classesLoading, setClassesLoading] = useState(false)
  useEffect(() => {
    const _fetchLiveClasses = async () => {
      try {
        setClassesLoading(true)
        const { result } = await callAPI({ url: '/live-class-options', query: { semesterCode } })
        setClassOptions(result)
        setClassesLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch live classes: ${error.reason}.`))
        setClassesLoading(false)
        setClassOptions([])
      }
    }

    if (semesterCode) { _fetchLiveClasses() }
  }, [semesterCode])

  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState([])
  useEffect(() => {
    const _fetchTemplate = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({ url: '/seat-template' })
        setTemplate(result)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch template: ${error.reason}.`))
        setLoading(false)
      }
    }

    _fetchTemplate()
  }, [])

  const templateByRow = _.groupBy(template, 'seatRow')

  const [classId, setClassId] = useState()
  const [seats, setSeats] = useState([])
  const [enrollments, setEnrollments] = useState([])
  const [enrollmentId, setEnrollmentId] = useState()
  const [seatingLoading, setSeatingLoading] = useState(false)
  const [upgradable, setUpgradable] = useState()
  const [downgradable, setDowngradable] = useState()
  const [currentSeatCode, setCurrentSeatCode] = useState()
  const [currentSeatType, setCurrentSeatType] = useState()
  const [selectedSeatCodes, setSelectedSeatCodes] = useState([])
  useEffect(() => {
    const _fetchSeating = async (_classId) => {
      try {
        setSeatingLoading(true)
        const { result: { seats, enrollments } } = await callAPI({ url: `/seating/${_classId}` })
        setSeats(seats)
        setEnrollments(enrollments)
        setSeatingLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch seating: ${error.reason}.`))
        setSeating([])
        setEnrollments([])
        setSeatingLoading(false)
      }
    }

    if (semesterCode && classId) { _fetchSeating(classId) }
  }, [semesterCode && classId])

  const [randomizing, setRandomizing] = useState(false)
  const _onRandomize = async () => {
    if (!editable || randomizing) return
    try {
      setRandomizing(true)
      const { result } = await callAPI({
        method: 'POST',
        url: `/seating/${classId}/random`
      })
      setSeats(result.seats)
      setTemplate(result.template)
      setRandomizing(false)
      message.success('Random ที่นั่งสำเร็จ ')
    } catch (error) {
      console.error(new Error(`Error while trying to random seats: ${error.reason}.`))
      setRandomizing(false)
      message.success(error.reason || 'Random ที่นั่งไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onSeatSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/seating/${classId}/enrollments`,
        body: {
          seatCode: currentSeatCode,
          seatType: currentSeatType,
          enrollmentId
        }
      })
      setSaving(false)
      setSeats(_seats => {
        const _index = _.findIndex(seats, seat => seat.seatCode === result.seatCode)
        if (_index === -1) {
          return _seats.map(_seat =>
            _seat.enrollmentId === result.enrollmentId
              ? { ..._seat, enrollmentId: null }
              : _seat
            ).concat([result])
        }
        return _seats.map((_seat, i) => {
          return i === _index
            ? result
            : _seat.enrollmentId === result.enrollmentId
              ? { ..._seat, enrollmentId: null }
              : _seat
        })
      })
      setCurrentSeatCode()
      setCurrentSeatType()
      setEnrollmentId()
    } catch (error) {
      console.error(new Error(`Error while trying to save seat: ${error.reason}.`))
      setSaving(false)
    }
  }

  const [upgrading, setUpgrading] = useState(false)
  const _onUpgrade = async () => {
    if (!editable || upgrading) return
    setUpgrading(true)
    try {
      const seatType = upgradable.slice(0, 1).toUpperCase()
      const { result } = await callAPI({
        method: 'PUT',
        url: `/seating/${classId}/types`,
        body: {
          seatType,
          seatCodes: selectedSeatCodes
        }
      })

      setSeats(_seats => {
        const _seatCodes = _seats.map(({ seatCode }) => seatCode)
        const newSeats = result.filter(({ seatCode }) => !_seatCodes.includes(seatCode))
        return _seats.map(_seat => _.find(result, ({ seatCode }) => seatCode === _seat.seatCode) || _seat).concat(newSeats)
      })
      message.success(`เพิ่มที่นั่ง${seatTypeMap[upgradable]} ${selectedSeatCodes.join(',')} สำเร็จ`)
      setUpgrading(false)
      setUpgradable()
    } catch (error) {
      console.error(new Error(`Error while trying to upgrade: ${error.reason}.`))
      setUpgrading(false)
      message.error(error.reason || `เพิ่มที่นั่ง${seatTypeMap[upgradable]} ${selectedSeatCodes.join(',')} ไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง`)
    }
  }

  const [downgrading, setDowngrading] = useState(false)
  const _onDowngrade = async () => {
    if (!editable || downgrading) return
    setDowngrading(true)
    try {
      const seatType = ({ vip: 'R', scholarship: 'R', regular: 'E' })[downgradable]
      const { result } = await callAPI({
        method: 'PUT',
        url: `/seating/${classId}/types`,
        body: {
          seatType,
          seatCodes: selectedSeatCodes
        }
      })

      setSeats(_seats => {
        const _seatCodes = _seats.map(({ seatCode }) => seatCode)
        const newSeats = result.filter(({ seatCode }) => !_seatCodes.includes(seatCode))
        return _seats.map(_seat => _.find(result, ({ seatCode }) => seatCode === _seat.seatCode) || _seat).concat(newSeats)
      })
      message.success(`ลบที่นั่ง${seatTypeMap[downgradable]} ${selectedSeatCodes.join(',')} สำเร็จ`)
      setDowngrading(false)
      setDowngradable()
    } catch (error) {
      console.error(new Error(`Error while trying to downgrade: ${error.reason}.`))
      setDowngrading(false)
      message.error(error.reason || `ลบที่นั่ง${seatTypeMap[downgradable]} ${selectedSeatCodes.join(',')} ไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง`)
    }
  }

  const [infoLoading, setInfoLoading] = useState(false)
  const [info, setInfo] = useState()
  const _onMouseEnter = async (enrollmentId) => {
    if (!enrollmentId) return
    try {
      setInfoLoading(true)
      const { result } = await callAPI({
        url: `/offline-enrollments/${enrollmentId}`
      })
      setInfo(result)
      setInfoLoading(false)
    } catch (error) {
      console.error(new Error(`Error while trying to fetch enrollment info: ${error.reason}`))
      setInfoLoading(false)
    }
  }

  useEffect(() => {
    if (!upgradable && !downgradable) {
      setSelectedSeatCodes([])
    }
  }, [upgradable, downgradable])

  const seatMap = _.keyBy(seats, 'seatCode')
  return (
    <section>
      <GridContainer>
        <Heading title='การจัดผังที่นั่ง'>
          <Filter>
            <SelectWrapper>
              <FormSelect
                style={{ width: 160 }}
                onChange={e => setSemesterCode(e.target.value)}
                value={semesterCode}>
                <option value=''>กรุณาเลือกภาคเรียน</option>
                {semesterOptions.map(semester => <option key={semester} value={semester}>{semester}</option>)}
              </FormSelect>
            </SelectWrapper>
            <SelectWrapper>
              <FormSelect
                disabled={!semesterCode || classesLoading}
                style={{ width: 400 }}
                onChange={e => setClassId(e.target.value)}
                value={classId}>
                <option value=''>กรุณาเลือกรอบวิชา</option>
                {classOptions.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
              </FormSelect>
            </SelectWrapper>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <Rectangle style={{ backgroundColor: 'white' }} />
              <SeatTypeName>ที่นั่งธรรมดา</SeatTypeName>
              <Rectangle style={{ marginLeft: 10, backgroundColor: colors.scholarship }} />
              <SeatTypeName>ที่นั่งทุน</SeatTypeName>
              <Rectangle style={{ marginLeft: 10, backgroundColor: colors.vip }} />
              <SeatTypeName>ที่นั่ง VIP</SeatTypeName>
              <Rectangle style={{ marginLeft: 10, backgroundColor: colors.empty }} />
              <SeatTypeName>ที่นั่งปิด</SeatTypeName>
            </div>
          </Filter>
        </Heading>
        {!classId ? (
          <Body>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>กรุณาเลือกภาคเรียนและรอบวิชาเพื่อแสดงข้อมูลผังที่น้อง</span>} />
          </Body>
        ) : seatingLoading ? (
          <Body><Spinner /></Body>
        ) : (
          <Content>
            {editable && (
              <>
                {upgradable ? (
                  <ContentHead>
                    <CustomPopconfirm
                      okType='primary'
                      disabled={upgrading || !selectedSeatCodes.length}
                      onClick={e => e.stopPropagation()}
                      title={`ยืนยันเพิ่มที่นั่ง${seatTypeMap[upgradable]} ${selectedSeatCodes.join(',')}`}
                      onConfirm={_onUpgrade}>
                      <Button disabled={upgrading || !selectedSeatCodes.length} loading={upgrading}>ยืนยัน</Button>
                    </CustomPopconfirm>
                    <Button onClick={() => setUpgradable()}>ยกเลิก</Button>
                  </ContentHead>
                ) : downgradable ? (
                  <ContentHead>
                    <CustomPopconfirm
                      disabled={downgrading || !selectedSeatCodes.length}
                      onClick={e => e.stopPropagation()}
                      title={`ยืนยันลบที่นั่ง${({ vip: ' VIP', scholarship: 'นักเรียนทุน', regular: 'ธรรมดา' })[downgradable]} ${selectedSeatCodes.join(',')}`}
                      onConfirm={_onDowngrade}>
                      <Button disabled={downgrading || !selectedSeatCodes.length} loading={downgrading}>ยืนยัน</Button>
                    </CustomPopconfirm>
                    <Button onClick={() => setDowngradable()}>ยกเลิก</Button>
                  </ContentHead>
                ) : (
                  <ContentHead>
                    <div style={{ marginBottom: 10 }}>
                      <Button icon='plus' style={{ backgroundColor: colors.vip }} onClick={() => setUpgradable('vip')}>เพิ่มที่นั่ง VIP</Button>
                      <Button icon='plus' style={{ backgroundColor: colors.scholarship }} onClick={() => setUpgradable('scholarship')}>เพิ่มที่นั่งนักเรียนทุน</Button>
                      <Button icon='plus' onClick={() => setUpgradable('regular')}>เพิ่มที่นั่งธรรมดา</Button>
                    </div>
                    <div>
                      <Button icon='close' style={{ backgroundColor: colors.vip }} onClick={() => setDowngradable('vip')}>ลบที่นั่ง VIP</Button>
                      <Button icon='close' style={{ backgroundColor: colors.scholarship }} onClick={() => setDowngradable('scholarship')}>ลบที่นั่งนักเรียนทุน</Button>
                      <Button icon='close' onClick={() => setDowngradable('regular')}>ลบที่นั่งธรรมดา</Button>
                      <Button
                        icon='redo'
                        loading={randomizing}
                        disabled={randomizing}
                        onClick={_onRandomize}
                        style={{ color: 'white', backgroundColor: theme.colors.secondary }}>
                          Random
                      </Button>
                    </div>
                  </ContentHead>
                )}
              </>
            )}
            <ContentBody>
              {Object.keys(templateByRow).map((key, i) => {
                const templateRow = templateByRow[key]
                return (
                  <SeatRow key={i}>
                    {templateRow.map(({ seatCode, seatType: _type }, j) => {
                      const _seat = seatMap[seatCode] || {}
                      const seatType = (_seat.seatType || _type)

                      const checkboxVisible = (['vip', 'scholarship'].includes(upgradable) && seatType === 'R') ||
                        upgradable === 'regular' && seatType === 'E' ||
                        upgradable === 'cancel' && seatType !== 'E' ||
                        downgradable === 'vip' && seatType === 'V' ||
                        downgradable === 'scholarship' && seatType === 'S' ||
                        downgradable === 'regular' && seatType === 'R'

                      return (
                        <SeatCol key={seatCode} className={`type-${seatType.toLowerCase()}`}>
                          {checkboxVisible &&
                            <Checkbox
                              style={{ marginRight: 5}}
                              checked={selectedSeatCodes.includes(seatCode)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setSelectedSeatCodes(_codes => _codes.concat([seatCode]))
                                } else {
                                  setSelectedSeatCodes(_codes => _codes.filter(_code => _code !== seatCode))
                                }
                              }} />
                          }
                          <span>{seatCode}</span>
                          {seatType !== 'E' && (
                            <EnrollmentId className={editable ? '' : 'disabled'}
                              onMouseEnter={() => _onMouseEnter(_seat.enrollmentId || 0)}
                              onMouseLeave={() => setInfo()}
                              onClick={!editable ? {} : () => {
                                setCurrentSeatCode(seatCode)
                                setCurrentSeatType(seatType)
                              }}>
                              <Popover
                                title='ข้อมูลนักเรียน'
                                content={
                                  infoLoading ? <Spin /> : <div>[{_.get(info, 'studentId')}] {_.get(info, 'firstName')} {_.get(info, 'lastName')}</div>
                                }>
                              {_seat.enrollmentId || 0}
                              </Popover>
                            </EnrollmentId>
                          )}
                        </SeatCol>
                      )
                    })}
                  </SeatRow>
                )
              })}
            </ContentBody>
          </Content>
        )}
        <Modal
          visible={editable && Boolean(currentSeatCode)}
          title='กรุณาเลือกนักเรียน'
          onCancel={() => setCurrentSeatCode()}
          footer={[
            <Button
              key='cancel'
              onClick={() => setCurrentSeatCode()}>ย้อนกลับ</Button>,
            <Button
              key='submit'
              type='primary'
              disabled={!enrollmentId}
              onClick={_onSeatSave}>
              บันทึกข้อมูล
            </Button>
          ]}>
          <FormSelect
            style={{ width: '100%' }}
            value={enrollmentId}
            onChange={e => setEnrollmentId(e.target.value)}>
            <option value=''>+++กรุณาเลือก+++</option>
            {enrollments.map(({ enrollmentId, studentId, firstName, lastName }) =>
              <option key={enrollmentId} value={enrollmentId}>{enrollmentId} - [{studentId}] {firstName} {lastName}</option>
            )}
          </FormSelect>
        </Modal>
      </GridContainer>
    </section>
  )
}

export default Seating

const Filter = styled.div`
  margin-top: 5px;
  position: relative;
`
const SelectWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
`
const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const Content = styled.div`
  background-color: white;
`
const ContentHead = styled.div`
  background-color: ${theme.colors.primary};
  padding: 8px 16px;
  position: relative;

  button {
    margin-right: 8px;
    border: none;
    &:hover { color: rgba(0,0,0,0.65); }
    &:active { color: rgba(0,0,0,0.65); }
  }
  button.text-white {
    &:hover { color: white; }
    &:active {color: white; }
  }
`
const ContentHeadRight = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
`
const ContentBody = styled.div`
  padding: 16px;
`
const SeatRow = styled.div`
  margin-bottom: 16px;
`
const SeatCol = styled.div`
  width: 60px;
  height: 36px;
  padding: 6px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: 700;

  position: relative;

  border-top: 1px solid ${colors.empty};
  border-bottom: 1px solid ${colors.empty};
  border-left: 1px solid ${colors.empty};
  &:last-of-type {
    border-right: 1px solid ${colors.empty};
  }

  &.type-s { background-color: ${colors.scholarship}; }
  &.type-v { background-color: ${colors.vip}; }
  &.type-e { background-color: ${colors.empty}; }
`
const EnrollmentId = styled.div`
  position: absolute;
  line-height: 1;
  bottom: -8px;
  left: 5px;
  border-radius: 10px;
  padding: 3px 7px;
  background-color: #cccccc;
  color: white;
  font-size: 8px;
  min-width: 10px;
  text-align: center;

  cursor: pointer;
  &.disabled { cursor: default; }
`
const Rectangle = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  margin-right: 5px;
`
const SeatTypeName = styled.div`
  display: inline-block;
  vertical-align: top;
`
