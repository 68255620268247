import React, { useState } from 'react'
import { callAPI, getTheme, formatThaiDuration } from 'helpers'

// Components
import styled from 'styled-components'
import Spinner from 'components/Spinner'
import { Button, Icon } from 'antd'
import CustomPopconfirm from 'components/CustomPopconfirm'

export default ({ index, item, onEdit, onClassItemsChange }) => {
  const [itemRemoving, setItemRemoving] = useState()

  const _onItemRemove = async (classItemId) => {
    try {
      setItemRemoving(true)
      onClassItemsChange(_classItems => _classItems.map(_item => _item.classItemId === classItemId ? ({ ..._item, removing: true }) : _item))
      await callAPI({
        method: 'DELETE',
        url: `/online-classes/${item.classId}/items/${classItemId}`
      })
      onClassItemsChange(_classItems => _classItems.filter(_item => _item.classItemId !== classItemId))
      setItemRemoving(false)
    } catch (error) {
      console.error('Error while trying to remove a class item.', error)
      setItemRemoving(false)
    }
  }

  const VideoItem = ({ item }) => {
    const { classItemId, videoTitle, thumbnailURL, duration } = item
    return (
      <div>
        <ClassItemIndex>{index + 1}</ClassItemIndex>
        <ClassItemThumbnailWrapper>
          <ClassItemThumbnail src={thumbnailURL} />
        </ClassItemThumbnailWrapper>
        <ClassItemInfo>
          <ClassItemTitle>
            <ClassItemLabel>ชื่อวิดีโอ</ClassItemLabel>
            <span>{videoTitle}</span>
          </ClassItemTitle>
          <ClassItemDuration>
            <ClassItemLabel>ความยาว</ClassItemLabel>
            <span>{formatThaiDuration(duration)}</span>
          </ClassItemDuration>
          <ClassItemButtons>
            <CustomButton style={{ marginRight: 10 }} icon='edit' onClick={onEdit}>
              <ButtonTitle>แก้ไขวิดีโอ</ButtonTitle>
            </CustomButton>
            <CustomPopconfirm
              disabled={itemRemoving}
              okText={itemRemoving ? 'กำลังลบ...' : 'ตกลง'}
              onConfirm={() => _onItemRemove(classItemId)}
              title={`บทเรียน ${videoTitle}`}
              icon={<Icon type='question-circle-o' />}>
              <CustomButton type='danger' icon='delete'>
                <ButtonTitle>ลบวิดีโอ</ButtonTitle>
              </CustomButton>
            </CustomPopconfirm>
          </ClassItemButtons>
        </ClassItemInfo>
      </div>
    )
  }

  const ExamItem = ({ item }) => {
    const { classItemId, examTitle, questionCount } = item
    return (
      <div>
        <ClassItemIndex>{index + 1}</ClassItemIndex>
        <ClassItemThumbnailWrapper>
          <ExamClassItemThumbnail>แบบทดสอบ</ExamClassItemThumbnail>
        </ClassItemThumbnailWrapper>
        <ClassItemInfo>
          <ClassItemTitle>
            <ClassItemLabel>ชื่อแบบทดสอบ</ClassItemLabel>
            <span>{examTitle}</span>
          </ClassItemTitle>
          <ClassItemDuration>
            <ClassItemLabel>จำนวนคำถาม</ClassItemLabel>
            <span>{questionCount} คำถาม</span>
          </ClassItemDuration>
          <ClassItemButtons>
            <CustomButton style={{ marginRight: 10 }} icon='edit' onClick={onEdit}>
              <ButtonTitle>แก้ไขแบบทดสอบ</ButtonTitle>
            </CustomButton>
            <CustomPopconfirm
              disabled={itemRemoving}
              okText={itemRemoving ? 'กำลังลบ...' : 'ตกลง'}
              onConfirm={() => _onItemRemove(classItemId)}
              title={`ยืนยันที่จะลบแบบทดสอบ ${examTitle}`}
              icon={<Icon type='question-circle-o' />}>
              <CustomButton type='danger' icon='delete'>
                <ButtonTitle>ลบแบบทดสอบ</ButtonTitle>
              </CustomButton>
            </CustomPopconfirm>
          </ClassItemButtons>
        </ClassItemInfo>
      </div>
    )
  }

  if (item.classVideoId) {
    return (
      <ClassItemRow key={item.classItemId}>
        {itemRemoving ? <Spinner text='กำลังลบ...'><VideoItem item={item} /></Spinner> : <VideoItem item={item} />}
      </ClassItemRow>
    )
  } else if (item.classExamId) {
    return (
      <ClassItemRow key={item.classItemId}>
        {itemRemoving ? <Spinner text='กำลังลบ...'><ExamItem item={item} /></Spinner> : <ExamItem item={item} />}
      </ClassItemRow>
    )
  }
  return null
}

const ClassItemRow = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  @media(min-width: ${getTheme`media.desktop`}) {
    line-height: 120px;
    margin-top: -1px;
    cursor: move;
  }
  @media(max-width: ${getTheme`media.mobile`}) {
    padding: 10px;
    position: relative;
  }
`
const ClassItemInfo = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    position: absolute;
    width: 100%;
    left: 30%;
    top: 0px;
    bottom: 0px;
  }
  @media(min-width: 425px) and (max-width: ${getTheme`media.mobile`}) {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 200px);
    margin-left: 20px;
  }
  @media(max-width: 424px) {
    display: block;
    margin-top: 10px;
  }
`
const ClassItemLabel = styled.label`
  @media(min-width: ${getTheme`media.desktop`}) { display: none; }
  @media(max-width: ${getTheme`media.mobile`}) {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: black;
    width: 50%;
  }
`
const ClassItemIndex = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    display: inline-block;
    vertical-align: top;
    width: 5%;
    text-align: center;
  }
  @media(max-width: ${getTheme`media.mobile`}) { display: none; }
`
const ClassItemThumbnailWrapper = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    text-align: center;
  }
  @media(max-width: ${getTheme`media.mobile`}) {
    display: inline-block;
    vertical-align: top;
    width: 180px;
  }
`
const ClassItemThumbnail = styled.img`
  height: 100px;
  @media(min-width: ${getTheme`media.desktop`}) {
    margin: 10px 0px;
    display: inline-block;
    object-fit: cover;
  }
`
const ClassItemTitle = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    display: inline-block;
    vertical-align: top;
    width: 35%;
  }
`
const ClassItemDuration = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 20%;
  }
`
const ClassItemButtons = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 15%;
  }
  @media(max-width: ${getTheme`media.mobile`}) {
    margin-top: 15px;
  }
`
const ExamClassItemThumbnail = styled.div`
  color: white;
  background-color: transparent;
  background-image: linear-gradient(70deg,#1d2d5c 0,#4d9ed5 100%);
  width: 177.77px;
  height: 100px;
  line-height: 100px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
`
const CustomButton = styled(Button)`
  @media(max-width: ${getTheme`media.mobile`}) {
    width: calc(50% - 5px);
    max-width: 160px;
  }
`
const ButtonTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  @media(min-width: ${getTheme`media.desktop`}) { display: none; }
`
