import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getTheme, callAPI } from 'helpers'
import { Route, Switch, Redirect, useLocation, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import theme from 'theme'

// Containers
import Admins from 'containers/Admins'
import Analytics from 'containers/analytics/Analytics'
import Answers from 'containers/Answers'
import Courses from 'containers/Courses'
import BookPickup from 'containers/BookPickup'
import Discounts from 'containers/Discounts'
import LiveFeedRounds from 'containers/LiveFeedRounds'
import OfflineClasses from 'containers/OfflineClasses'
import OnlineClasses from 'containers/OnlineClasses'
import PaidOrders from 'containers/PaidOrders'
import Permissions from 'containers/Permissions'
import Schools from 'containers/Schools'
import Seating from 'containers/Seating'
import Students from 'containers/students/Students'

// Components
import GridContainer from 'components/GridContainer'
import { Icon } from 'antd'

// Hooks
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

// Data
import menuConfigs from 'data/menus.json'

const MOBILE_MENU_WIDTH = 300

export default function () {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector(state => _.get(state, 'app.me'))
  const isOwner = _.get(profile, 'roleName') === 'Owner'
  const permissions = _.get(profile, 'permissions') || []
  const [menusVisible, setMenusVisible] = useState()

  const [verifying, setVerifying] = useState(true)
  useEffect(() => {
    const verify = async () => {
      try {
        setVerifying(true)
        const { result: me } = await callAPI({ url: '/admins/me' })
        dispatch({ type: 'UPDATE_ME', me })
        setVerifying(false)
      } catch (error) {
        Cookies.remove('accessToken')
        history.replace('/login')
      }
    }

    verify()
  }, [])

  useEffect(() => {
    console.log({ profile })
    if (profile) { setVerifying(false) }
  }, [profile])

  const [loggingOut, setLoggingOut] = useState(false)
  const _onLogout = async () => {
    if (loggingOut) return
    try {
      setLoggingOut(true)
      await callAPI({
        method: 'POST',
        url: '/admins/logout'
      })
      Cookies.remove('accessToken')
      window.location.href = '/'
      setLoggingOut(false)
    } catch (error) {
      console.error('Error while trying to log out.', error)
      setLoggingOut(false)
    }
  }

  if (location.pathname === '/login' || verifying) return null
  return (
    <section>
      <Container menusVisible={menusVisible}>
        {location.pathname !== '/login' && (
          <Navbar style={{ left: menusVisible ? MOBILE_MENU_WIDTH : 0 }}>
            <CustomGridContainer style={{ position: 'relative' }}>
              <NavLogo src='/images/logo-oplus.png' />
              <DesktopNavLinks>
                {menuConfigs.map(({ title, menus = [] }, index) =>
                  <NavGroup key={index}>
                    <GroupTitle>
                      <span>{title} </span>
                      <Icon type='down' />
                    </GroupTitle>
                    <div className='nav-menus'>
                      {menus.filter(({ to, submenus = [] }) => {
                        const ownerExclusive = ['/admins', '/permissions'].includes(to)
                        const pathnames = submenus.length ? submenus.map(({ to }) => to) : [to]
                        const accessible = pathnames.some(to => permissions.map(({ pathname }) => pathname).includes(to))
                        return isOwner || (!ownerExclusive && accessible)
                      })
                      .map((menu, mIndex) => {
                        const pathname = menu.activePathname || ((menu.submenus || []).length
                          ? isOwner ? _.get(menu, 'submenus.0.to') : _.get(_.find(menu.submenus || [], ({ to }) => permissions.map(({ pathname }) => pathname).includes(to)), 'to')
                          : menu.to)

                        return (
                          <Link key={mIndex} activeKey={menu.activeKey} to={pathname}>
                            <NavMenu isActive={location.pathname.includes(menu.activeKey || menu.to)}>{menu.title}</NavMenu>
                          </Link>  
                        )
                      })}
                    </div>
                  </NavGroup>
                )}
              </DesktopNavLinks>
              <NavRight>
                <strong style={{ marginRight: 20 }}>{profile.username}</strong>
                <BtnLogout onClick={_onLogout}>
                  {loggingOut ? <Icon type='loading' /> : <span>ออกจากระบบ</span>}
                </BtnLogout>
              </NavRight>
              <BtnMenuToggle onClick={() => setMenusVisible(visible => !visible)}>
                <Icon type='menu' />
              </BtnMenuToggle>
            </CustomGridContainer>
          </Navbar>
        )}
        <div style={{ width: '100vw' }}>
          <Switch>
            <Route path='/admins' component={Admins} />
            <Route path='/analytics' component={Analytics} />
            <Route path='/answers' component={Answers} />
            <Route path='/courses' component={Courses} />
            <Route path='/book-pickups' component={BookPickup} />
            <Route path='/discounts' component={Discounts} />
            <Route path='/live-feed-rounds' component={LiveFeedRounds} />
            <Route path='/offline-classes' component={OfflineClasses} />
            <Route path='/online-classes' component={OnlineClasses} />
            <Route path='/paid-orders' component={PaidOrders} />
            <Route path='/permissions' component={Permissions} />
            <Route path='/schools' component={Schools} />
            <Route path='/seating' component={Seating} />
            <Route path='/students' component={Students} />
            <Redirect to='/login' />
          </Switch>
        </div>
      </Container>
      {false && (
        <MobileNavLinks style={{ left: menusVisible ? 0 : -MOBILE_MENU_WIDTH }}>
          <MobileNavLinksHead>
            <BtnCloseMobileNavLinks onClick={() => setMenusVisible(false)}>
              <Icon type='close' />
            </BtnCloseMobileNavLinks>
          </MobileNavLinksHead>
          <Link to='/online-classes'>
            <NavLink
              onClick={() => setMenusVisible(false)}
              isActive={location.pathname.includes('/online-classes')}>คอร์สออนไลน์</NavLink>
          </Link>
          <Link to='/exams'>
            <NavLink
              onClick={() => setMenusVisible(false)}
              isActive={location.pathname.includes('/exams')}>แบบทดสอบ</NavLink>
          </Link>
        </MobileNavLinks>
      )}
    </section>
  )
}

const Container = styled.div`
  background-color: ${getTheme`colors.background`};
  padding-top: 50px;
  min-height: 100vh;
  transition: margin-left 0.3s ease;
  position: relative;
  ${props => props.menusVisible ? `
    margin-left: ${MOBILE_MENU_WIDTH}px;
    &:after {
      content: '';
      z-index: ${999 + 1};
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
    }
  ` : `
    margin-left: 0px;
  `}
`
const CustomGridContainer = styled(GridContainer)`
  @media(max-width: ${theme.media.mobile}) {
    text-align: center;
  }
`
const Navbar = styled.div`
  background-color: ${getTheme`colors.primary`};
  height: 50px;
  position: fixed;
  top: 0px;
  transition: left 0.3s ease;
  width: 100vw;
  z-index: 999;
`
const NavLogo = styled.img`
  height: 30px;
  object-fit: cover;
  filter: brightness(0) invert(1);
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
`
const DesktopNavLinks = styled.div`
  @media(min-width: ${theme.media.desktop}) {
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    white-space: nowrap;
    width: calc(100% - 110px);
  }
  @media(max-width: ${theme.media.mobile}) {
    display: none;
  }
`
const BtnMenuToggle = styled.div`
  @media(min-width: ${theme.media.desktop}) { display: none; }
  @media(max-width: ${theme.media.mobile}) {
    cursor: pointer;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    color: white;
    z-index: 1;

    > i {
      font-size: 26px;
      margin-top: 12px;
    }
  }
`
const NavRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  line-height: 50px;
  @media(max-width: ${theme.media.mobile}) {
    right: 10px;
  }
`
const BtnLogout = styled.div`
  display: inline-block;
  vertical-align: top;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid white;
  margin-top: 9px;
  width: 130px;
  
  @media(min-width: ${theme.media.desktop}) {
    padding: 0px 20px;
    text-align: center;
    ${props => props.isActive && `
      background-color: ${props.theme.colors.secondary}
      &:hover {
        color: white;
      }
    `}
  }
`
const NavGroup = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;

  .nav-menus {
    display: none;
    position: absolute;
    top: 50px;
    background-color: white;
    line-height: 40px;
  }
  &:hover {
    .nav-menus { display: block; }
  }
`
const GroupTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 50px;
  padding: 0px 20px;
  color: white;
  cursor: pointer;
`
const NavLink = styled.span`
  @media(min-width: ${theme.media.desktop}) {
    display: inline-block;
    vertical-align: top;
    line-height: 50px;
    padding: 0px 20px;
    color: white;
    transition: background-color 0.3s ease;
    ${props => props.isActive && `
      background-color: ${props.theme.colors.secondary};
      &:hover {
        color: white;
      }
    `}
  }
  @media(max-width: ${theme.media.mobile}) {
    display: block;
    height: 50px;
    line-height: 50px;
    color: black;
    border-bottom: ${getTheme`colors.border`};
    font-size: 18px;
    font-weight: 700;
    text-indent: 10px;
    ${props => props.isActive && `
      color: ${props.theme.colors.secondary};
    `}
  }
`
const MobileNavLinks = styled.div`
  background-color: white;
  width: ${MOBILE_MENU_WIDTH}px;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: ${999 + 1};
  transition: left 0.3s ease;
`
const NavMenu = styled.div`
  padding: 0px 20px;
  border-bottom: 1px solid ${theme.colors.border};
  ${props => props.isActive && `
    color: ${props.theme.colors.secondary};
  `}
`
const MobileNavLinksHead = styled.div`
  text-align: right;
`
const BtnCloseMobileNavLinks = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  > i {
    color: black;
    font-size: 26px;
    margin-top: 12px;
  }
`
