import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { get, keyBy, maxBy } from 'lodash'
import { callAPI, getTheme } from 'helpers'
import styled from 'styled-components'

// Components
import Spinner from 'components/Spinner'
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import ContentWrapper from 'components/ContentWrapper'
import { Empty, Button } from 'antd'
import GridLayout from 'react-grid-layout'
import ClassItem from 'components/classes/ClassItem'
import ClassItemCreateModal from 'components/classes/ClassItemCreateModal'
import ClassItemEditModal from 'components/classes/ClassItemEditModal'

import 'react-grid-layout/css/styles.css'

export default function () {
  const match = useRouteMatch()
  const history = useHistory()

  const containerRef = useRef()

  const classId = match.params.classId
  const [loading, setLoading] = useState(false)
  const [classDetail, setClassDetail] = useState({})
  const [classItems, setClassItems] = useState([])

  const [modalCreateVisible, setModalCreateVisible] = useState(false)
  const [currentClassItem, setCurrentClassItem] = useState()
  const [repositioning, setRepositioning] = useState()

  // const vimeoId = getVimeoId(vimeoURL)
  const classItemMap = keyBy(classItems, ({ classItemId }) => classItemId)
  const nextSequence = classItems.length
    ? Math.floor(maxBy(classItems, 'sequence').sequence + 1)
    : 1

  const _onDragStop = async (layout, { i: key1, y: pos1 }, { i: key2, y: pos2 }) => {
    const layoutMap = keyBy(layout, ({ y }) => y)
    const itemBefore = pos2 - 1 >= 0 ? classItemMap[layoutMap[pos2 - 1].i] : { sequence: 0 }
    const itemAfter = pos2 + 1 < classItems.length ? classItemMap[layoutMap[pos2 + 1].i] : { sequence: nextSequence }
    const newSequence = (itemBefore.sequence + itemAfter.sequence) / 2

    try {
      setRepositioning(true)
      await callAPI({
        method: 'PUT',
        url: `/online-classes/${classId}/items/${key1}`,
        body: { sequence: newSequence }
      })
      setRepositioning(false)
    } catch (error) {
      console.error('Error while trying to reposition a class item.', error)
      setRepositioning(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        // Fetch a class detail.
        const { result: _classDetail } = await callAPI({
          method: 'GET',
          url: `/online-classes/${classId}`
        })
        // Fetch class items.
        const { result: _classItems } = await callAPI({
          method: 'GET',
          url: `/online-classes/${classId}/items`,
          query: { limit: 1000 }
        })

        setLoading(false)
        setClassDetail(_classDetail)
        setClassItems(_classItems)
      } catch (error) {
        console.error('Error while trying to fetch a course detail.', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const currentClassCode = get(classDetail, 'classCode')
  const currentSemesterCode = get(classDetail, 'semesterCode')
  const currentCourseCode = get(classDetail, 'courseCode')
  const currentCourseName = get(classDetail, 'courseName')

  const layout = classItems.map(item => ({
    i: `${item.classItemId}`,
    x: 0,
    y: 0,
    w: 1,
    h: 1
  }))

  return (
    <GridContainer ref={containerRef}>
      {!loading && (
        <Heading
          title={`${currentCourseCode}: ${currentCourseName}`}
          description={`ภาคเรียนที่ ${currentSemesterCode} - ${currentClassCode}`}
          buttonProps={{ onClick: () => setModalCreateVisible(true) }}
          buttonTitle='เพิ่มบทเรียน / แบบทดสอบ' />
      )}
      {loading ? (
        <ContentWrapper style={{ marginTop: 30 }}><Spinner /></ContentWrapper>
      ) : classItems.length ? (
        <div style={{ paddingBottom: 60 }}>
          <ClassItemHead>
            <ClassItemIndex>ลำดับที่</ClassItemIndex>
            <ClassItemThumbnailWrapper />
            <ClassItemTitle>ชื่อ</ClassItemTitle>
            <ClassItemDuration>ความยาว</ClassItemDuration>
          </ClassItemHead>
          <GridLayout
            layout={layout}
            cols={1}
            rowHeight={120}
            margin={[0, 0]}
            isResizable={false}
            onDragStop={_onDragStop}
            onLayoutChange={_layout => setClassItems(_items => {
              const order = _layout.sort((a, b) => a.y - b.y).slice()
              const classItemMap = keyBy(_items, 'classItemId')
              return order.map(({ i }) => classItemMap[i])
            })}
            width={containerRef.current.offsetWidth}>
            {classItems.map((item, index) =>
              <div key={`${item.classItemId}`}>
                <ClassItem index={index}
                  item={item}
                  onEdit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (item.classExamId) {
                      history.push(`/exams/${item.classExamId}`)
                    } else {
                      setCurrentClassItem(item)
                    }
                  }}
                  onClassItemsChange={setClassItems} />
              </div>
            )}
          </GridLayout>
        </div>
      ) : (
        <ContentWrapper>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>ยังไม่มีข้อมูลบทเรียน / แบบทดสอบ</span>}>
            <Button type='primary' icon='plus' onClick={() => setModalCreateVisible(true)}>เพิ่มบทเรียน / แบบทดสอบ</Button>
          </Empty>
        </ContentWrapper>
      )}
      <ClassItemCreateModal
        visible={modalCreateVisible}
        classId={classId}
        nextSequence={nextSequence}
        onCreate={(newItem) => {
          setClassItems(_items => _items.concat([newItem]))
          setModalCreateVisible(false)
        }}
        onCancel={() => setModalCreateVisible(false)} />
      <ClassItemEditModal
        classItem={currentClassItem}
        onSave={(savedItem) => {
          setClassItems(_items => _items.map(_item => _item.classItemId === savedItem.classItemId ? savedItem : _item))
          setCurrentClassItem(null)
        }}
        onCancel={() => setCurrentClassItem(null)} />
    </GridContainer>
  )
}

const ClassItemRow = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  line-height: 120px;
  margin-top: -1px;
  cursor: move;
`
const ClassItemHead = styled(ClassItemRow)`
  @media(min-width: ${getTheme`media.desktop`}) {
    background-color: #fafafa;
    color: black;
    line-height: 54px;
  }
  @media(max-width: ${getTheme`media.mobile`}) {
    display: none;
  }
`
const ClassItemIndex = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 5%;
  text-align: center;
`
const ClassItemThumbnailWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 25%;
  text-align: center;
`
const ClassItemTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 35%;
`
const ClassItemDuration = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 20%;
  text-align: center;
`
