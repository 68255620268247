import styled from 'styled-components'
import theme from 'theme'

export const ChartSection = styled.section`
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 10px);
  background-color: white;
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
  &:nth-child(2n) { margin-right: 0px; }
`
export const SectionContent = styled.div`
  padding: 20px;
`
export const SectionHead = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: black;
`
export const SectionBody = styled.div`

`

export const Table = styled.div`
  border: 1px solid ${theme.colors.primary};
  margin-top: 20px;
  &:first-of-type { margin-top: 0px; }
`
export const TableRow = styled.div`
  .head-row {
    background-color: ${theme.colors.primary};
  }
  &.has-three-cols {
    > section > div {
      width: 33.33%;
    }
  }
  &.has-six-cols {
    > section > div {
      width: 16.66%;
    }
  }
`
export const TableCol = styled.div`
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 40px;
`
export const TableHead = styled(TableCol)`
  background-color: ${theme.colors.primary};
  color: white;
`
