import React, { useEffect, useState } from 'react'
import { callAPI, exportAsExcel } from 'helpers'
import { compact, padStart } from 'lodash'
import moment from 'moment'

// Components
import styled from 'styled-components'
import GridContainer from 'components/GridContainer'
import Spinner from 'components/Spinner'
import Heading from 'components/Heading'
import ContentWrapper from 'components/ContentWrapper'
import { Table, DatePicker, Button } from 'antd'

// Hooks
import { useEditable } from 'hooks/useEditable'

const LIMIT = 1000
const branchMap = {
  '1': 'ขอนแก่น',
  '2': 'นครราชสีมา',
  '3': 'วงเวียนใหญ่',
  '4': 'งามวงศ์วาน',
  '5': 'สยาม',
  '6': 'ชลบุรี',
  '7': 'สุราษฎร์ธานี',
  '8': 'หาดใหญ่'
}

export default () => {
  const editable = useEditable()
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState(false)
  const [orderCount, setOrderCount] = useState(0)

  const [[startDate, endDate], setDates] = useState([moment().add(-1, 'day'), moment().add(-1, 'day')])

  useEffect(() => {
    const initialFetch = async () => {
      try {
        setLoading(true)
        const [{ result: _paidOrderCount }, { result: _paidOrders }] = await Promise.all([
          callAPI({
            url: '/paid-orders/count',
            query: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD')
            }
          }),
          callAPI({
            url: '/paid-orders',
            query: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
              limit: LIMIT
            }
          })
        ])

        setLoading(false)
        setOrderCount(_paidOrderCount)
        setOrders(_paidOrders)
      } catch (error) {
        console.error('Error while trying to initial fetch.', error)
        setLoading(false)
        setOrders([])
      }
    }

    initialFetch()
  }, [startDate, endDate])

  const _columns = compact([{
    key: 'createdDate',
    title: 'วันที่สมัคร',
    align: 'center',
    render: ({ updatedAt, createdAt }) => moment(updatedAt || createdAt).format('DD/MM/YYYY')
  }, {
    key: 'orderId',
    title: 'หมายเลขการสั่งซื้อ',
    align: 'center',
    render: ({ orderId }) => orderId
  }, {
    key: 'studentId',
    title: 'รหัสนักเรียน',
    align: 'center',
    render: ({ studentId }) => padStart(studentId, 6, '0')
  }, {
    key: 'studentName',
    title: 'ชื่อนักเรียน',
    render: ({ studentId, firstName, lastName, nickName }) =>
      `${firstName} ${lastName}${nickName ? ` (${nickName})` : ''}`
  }, {
    key: 'address',
    title: 'ที่อยู่สำหรับจัดส่งหนังสือ',
    align: 'center',
    render: ({ deliveryMethod, deliveryAddress, deliveryBranchCode, pickupDate }) =>
      deliveryMethod === 'home'
        ? (deliveryAddress || '-')
        : deliveryMethod === 'branch' ? `รับหนังสือที่สาขา ${branchMap[deliveryBranchCode]} วันที่ ${moment(pickupDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}` : '-'
  }, {
    key: 'phoneNumber',
    title: 'เบอร์โทรศัพท์',
    align: 'center',
    render: ({ deliveryPhone, deliveryPhone2 }) => compact([deliveryPhone, deliveryPhone2]).join(', ')
  }, {
    key: 'semesterCode',
    title: 'ภาคเรียน',
    align: 'center',
    render: ({ semesterCode }) => semesterCode
  }, {
    key: 'classCode',
    title: 'รหัสรอบ',
    align: 'center',
    render: ({ classCode }) => classCode
  }, {
    key: 'branchName',
    title: 'สาขา',
    align: 'center',
    render: ({ branchName }) => branchName || '-'
  }, {
    key: 'classTypeName',
    title: 'ประเภทรอบ',
    align: 'center',
    render: ({ classTypeName }) => classTypeName || 'Online'
  }, {
    key: 'className',
    title: 'ชื่อรอบ',
    render: ({ className, classCode }) => className || classCode
  }])

  const _onExport = () => {
    exportAsExcel({
      columns: _columns,
      data: orders,
      filename: `orders-${new Date().getTime().toString(16)}.xlsx`,
      sheetName: 'Sheet1'
    })
  }

  return (
    <section>
      <GridContainer>
        <Heading title={`ข้อมูลการสมัครเรียน ${orderCount ? `(${orderCount})` : ''}`} />
        <SearchWrapper>
          <span style={{ marginRight: 10 }}>กรองด้วยวันที่</span>
          <DatePicker.RangePicker
            disabledDate={(current) => current.isAfter(moment())}
            value={[startDate, endDate]}
            onChange={setDates} />
          <Button
            icon='export'
            style={{ position: 'absolute', right: 0 }}
            onClick={_onExport}>Export</Button>
        </SearchWrapper>
        {loading ? (
          <ContentWrapper><Spinner /></ContentWrapper>
        ) : orders.length ? (
          <ContentWrapper style={{ padding: 0 }}>
            <Table
              rowKey={({ orderId }) => orderId}
              columns={_columns}
              dataSource={orders}
              pagination={false} />
          </ContentWrapper>
        ) : null}
      </GridContainer>
    </section>
  )
}

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`
