export default {
  media: {
    desktop: '768px',
    mobile: '767px'
  },
  colors: {
    background: '#e5e5e5',
    border: '#e8e8e8',
    primary: '#313e7e',
    secondary: '#00a1d6',
    green: '#5dae3a',
    orange: '#ffa940',
    red: '#d9534f',
    gray: '#8e929a'
  }
}
