import React, { useState, useEffect, useRef } from 'react'
import { getTheme, callAPI, formatComma } from 'helpers'
import styled from 'styled-components'
import qs from 'qs'
import moment from 'moment'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import FormGroup, { Label, SelectWrapper, SearchWrapper } from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'
import { Empty, Button, Table, Modal, Input, message, Checkbox, DatePicker, Icon } from 'antd'

// Hooks
import { useCourseOptions } from 'hooks/custom'
import { useHistory, useLocation } from 'react-router-dom'
import { useEditable } from 'hooks/useEditable'

const LIMIT = 100
const OfflineClasses = () => {
  const editable = useEditable()
  const history = useHistory()
  const location = useLocation()
  const { keyword = '', semesterCode: _sCode = '',  page = 1 } = qs.parse(location.search.slice(1))
  const inputRef = useRef()
  const { options: courseOptions, loading: optionsLoading } = useCourseOptions()

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [offlineClasses, setOfflineClasses] = useState([])
  const [classRecordCount, setClassRecordCount] = useState(0)

  const [searchKey, setSearchKey] = useState(keyword || '')
  useEffect(() => setSearchKey(keyword || ''), [keyword])

  const semesterOptions = Array(10)
    .fill()
    .map(( _, index) => `${moment().year() + 546 - index}`)
    .reduce((semesterCodes, year) => semesterCodes.concat([4,3,2,1].map(i => `${year}/${i}`)), [])

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const offset = ((page || 1) - 1) * LIMIT
        const query = { keyword, semesterCode: _sCode }
        const [{ result: count }, { result: classes }] = await Promise.all([
          callAPI({ url: '/offline-classes/count', query }),
          callAPI({ url: '/offline-classes', query: { ...query, offset, limit: LIMIT } })
        ])
        setClassRecordCount(count)
        setOfflineClasses(classes)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch offline classes: ${error.reason}`))
        setLoading(false)
        setOfflineClasses([])
      }
    }

    _initialFetch()
  }, [keyword, _sCode, page])

  const renderIcon = (bool) => bool
    ? <Icon style={{ color: 'green' }} type='check' />
    : <Icon style={{ color: 'red' }} type='close' />

  const columns = () => _.compact([
    { title: 'ชื่อรอบ', key: 'classInfo', render: ({ classCode, className }) => `${classCode}: ${className}` },
    { title: 'ภาคเรียน', key: 'semesterCode', align: 'center', render: ({ semesterCode }) => semesterCode },
    { title: 'ประเภท', key: 'classType', align: 'center', render: ({ classType }) => classType },
    { title: 'ค่าเรียน', key: 'classFee', align: 'center', render: ({ classFee }) => formatComma(classFee) },
    { title: 'จากวันที่', key: 'classStartDate', align: 'center', render: ({ classStartDate }) => moment(classStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') },
    { title: 'ถึงวันที่', key: 'classEndDate', align: 'center', render: ({ classEndDate }) => moment(classEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY') },
    { title: 'จากเวลา', key: 'classStartTime', align: 'center', render: ({ classStartTime }) => classStartTime || '-' },
    { title: 'ถึงเวลา', key: 'classEndTime', align: 'center', render: ({ classEndTime }) => classEndTime || '-' },
    { title: 'จำนวนชั่วโมงเรียน', key: 'totalHours', align: 'center', render: ({ totalHours }) => totalHours},
    { title: 'จำนวนชั่วโมงชดเชย', key: 'compHours', align: 'center', render: ({ compHours }) => compHours},
    { title: 'อา', key: 'classSunday', align: 'center', render: ({ classSunday }) => renderIcon(classSunday) },
    { title: 'จ', key: 'classMonday', align: 'center', render: ({ classMonday }) => renderIcon(classMonday) },
    { title: 'อ', key: 'classTuesday', align: 'center', render: ({ classTuesday }) => renderIcon(classTuesday) },
    { title: 'พ', key: 'classWednesday', align: 'center', render: ({ classWednesday }) => renderIcon(classWednesday) },
    { title: 'พฤ', key: 'classThursday', align: 'center', render: ({ classThursday }) => renderIcon(classThursday) },
    { title: 'ศ', key: 'classFriday', align: 'center', render: ({ classFriday }) => renderIcon(classFriday) },
    { title: 'ส', key: 'classSaturday', align: 'center', render: ({ classSaturday }) => renderIcon(classSaturday) },
    editable ? { title: '', key: 'buttons', width: 180, render: ({
      classId, semesterCode, classCode, className,
      courseCode, classType, classFee,
      classStartDate, classEndDate, classStartTime, classEndTime,
      classSunday, classMonday, classTuesday, classWednesday, classThursday, classFriday, classSaturday,
      compHours, totalHours
    }) =>
      <div>
        <Button onClick={() => {
          setModalVisible(true)
          setClassId(classId)
          setSemesterCode(semesterCode)
          setClassCode(classCode)
          setClassName(className)
          setCourseCode(courseCode)
          setClassType(classType)
          setClassFee(parseInt(classFee, 10))
          setClassPeriod({
            classStartDate: classStartDate
              ? moment(classStartDate, 'YYYY-MM-DD')
              : null,
            classEndDate: classEndDate
              ? moment(classEndDate, 'YYYY-MM-DD')
              : null
          })
          setClassStartTime(classStartTime)
          setClassEndTime(classEndTime)
          setClassSunday(classSunday)
          setClassMonday(classMonday)
          setClassTuesday(classTuesday)
          setClassWednesday(classWednesday)
          setClassThursday(classThursday)
          setClassFriday(classFriday)
          setClassSaturday(classSaturday)
          setCompHours(compHours)
          setTotalHours(totalHours)
        }}>
          แก้ไข
        </Button>
        <CustomPopconfirm
          onClick={e => e.stopPropagation()}
          title={`ยืนยันที่จะลบรอบวิชา ${classId}: ${className}`}
          onConfirm={() => _onClassRemove(classId)}>
          <Button type='danger' disabled={removing} loading={removing} style={{ marginLeft: 10 }}>
            ลบ
          </Button>
        </CustomPopconfirm>
      </div>
    } : null
  ])

  const [adding, setAdding] = useState(false)
  const [classId, setClassId] = useState()
  const [semesterCode, setSemesterCode] = useState('')
  const [classCode, setClassCode] = useState('')
  const [className, setClassName] = useState('')
  const [courseCode, setCourseCode] = useState('')
  const [classType, setClassType] = useState('')
  const [classFee, setClassFee] = useState('')
  const [{ classStartDate, classEndDate }, setClassPeriod] = useState({ classStartDate: null, classEndDate: null })
  const [classStartTime, setClassStartTime] = useState()
  const [classEndTime, setClassEndTime] = useState()
  const [classSunday, setClassSunday] = useState(false)
  const [classMonday, setClassMonday] = useState(false)
  const [classTuesday, setClassTuesday] = useState(false)
  const [classWednesday, setClassWednesday] = useState(false)
  const [classThursday, setClassThursday] = useState(false)
  const [classFriday, setClassFriday] = useState(false)
  const [classSaturday, setClassSaturday] = useState(false)
  const [totalHours, setTotalHours] = useState()
  const [compHours, setCompHours] = useState()

  const _onClassAdd = async () => {
    if (!editable || adding) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/offline-classes',
        body: {
          semesterCode,
          classCode,
          className,
          courseCode,
          classType,
          classFee,
          classStartDate: classStartDate
            ? classStartDate.format('YYYY-MM-DD')
            : null,
          classEndDate: classEndDate
            ? classEndDate.format('YYYY-MM-DD')
            : null,
          classStartTime,
          classEndTime,
          classSunday,
          classMonday,
          classTuesday,
          classWednesday,
          classThursday,
          classFriday,
          classSaturday,
          totalHours,
          compHours
        }
      })
      setOfflineClasses(_classes => [result].concat(_classes))
      setAdding(false)
      setModalVisible(false)
      message.success('เพิ่มข้อมูลวิชาสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add new class: ${error.reason}.`))
      setAdding(false)
      message.error(error.reason || 'เพิ่มข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onClassSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/offline-classes/${classId}`,
        body: {
          semesterCode,
          classCode,
          className,
          courseCode,
          classType,
          classFee,
          classStartDate: classStartDate
            ? classStartDate.format('YYYY-MM-DD')
            : null,
          classEndDate: classEndDate
            ? classEndDate.format('YYYY-MM-DD')
            : null,
          classStartTime,
          classEndTime,
          classSunday,
          classMonday,
          classTuesday,
          classWednesday,
          classThursday,
          classFriday,
          classSaturday,
          totalHours,
          compHours
        }
      })
      setOfflineClasses(_classes => _classes.map(_class => _class.classId === result.classId ? result : _class))
      setSaving(false)
      setModalVisible(false)
      message.success('บันทึกข้อมูลวิชาสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to save a class of ID: ${classId}: ${error.reason}.`))
      setSaving(false)
      message.error(error.reason || 'บันทึกข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onClassRemove = async (classId) => {
    if (!editable || removing) return
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/offline-classes/${classId}`
      })
      setRemoving(false)
      setOfflineClasses(_classes => _classes.filter(_class => _class.classId !== classId))
      message.success('ลบข้อมูลวิชาสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to remove a class of ID ${classId}: ${error.reason}`))
      setRemoving(false)
      message.error(error.reason || 'ลบข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  useEffect(() => {
    if (!modalVisible) {
      setClassId()
      setSemesterCode()
      setClassCode(classCode)
      setClassName(className)
      setCourseCode(courseCode)
      setClassType(classType)
      setClassFee(classFee)
      setClassPeriod({ classStartDate: null, classEndDate: null})
      setClassStartTime(classStartTime)
      setClassEndTime(classEndTime)
      setClassSunday(classSunday)
      setClassMonday(classMonday)
      setClassTuesday(classTuesday)
      setClassWednesday(classWednesday)
      setClassThursday(classThursday)
      setClassFriday(classFriday)
      setClassSaturday(classSaturday)
      setTotalHours(totalHours)
    }
  }, [modalVisible])

  const disabled = false
  const filteredClasses = offlineClasses
  const current = parseInt(page || 1, 10)
  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title='ข้อมูลรอบสด/ถ่ายทอดสด'
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มข้อมูลรอบ' />
        <div style={{ position: 'relative' }}>
          <SearchWrapper>
            <Input.Search
              ref={inputRef}
              placeholder='ค้นหาด้วยรหัสรอบ, ชื่อรอบ'
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
              onSearch={() => history.push(`/offline-classes?keyword=${searchKey}&semesterCode=${_sCode}`)} />
          </SearchWrapper>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <span>ค้นหาด้วยภาคเรียน</span>
            <FormSelect
              style={{ marginLeft: 10, paddingLeft: 5, width: 200 }}
              placeholder='ภาคเรียน'
              value={_sCode}
              onChange={e => history.push(`/offline-classes?keyword=${searchKey}&semesterCode=${e.target.value}`)}>
              <option value=''>แสดงทั้งหมด</option>
              {semesterOptions.map(value => <option key={value}>{value}</option>)}
            </FormSelect>
          </div>
        </div>
        {loading ? (
          <Body><Spinner /></Body>
        ) : filteredClasses.length ? (
          <Table
            rowKey={({ classId }) => classId}
            columns={columns()}
            dataSource={filteredClasses}
            pagination={{
              current,
              total: classRecordCount,
              pageSize: LIMIT,
              onChange: (page) => {
                history.push(`/offline-classes?keyword=${searchKey}&semesterCode=${_sCode}&page=${page}`)
              }
            }} />
        ) : offlineClasses.length ? (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ไม่พบข้อมูลรอบสด/ถ่ายทอดสดที่ค้นหา</span>}>
              <Button type='primary' icon='search' onClick={() => {
                inputRef.current.focus()
                setSearchKey('')
              }}>ค้นหาด้วยคีย์เวิร์ดอื่น</Button>
            </Empty>
          </Body>
        ) : (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลรอบสด/ถ่ายทอดสด</span>}>
              {editable && <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มข้อมูลรอบสด/ถ่ายทอดสดแรกของคุณ</Button>}
            </Empty>
          </Body>
        )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title={classId ? 'แก้ไขข้อมูลรอบ' : 'เพิ่มข้อมูลรอบ'}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={disabled}
            loading={classId ? saving : adding}
            onClick={classId ? _onClassSave : _onClassAdd}>
            {classId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}
          </Button>
        ]}>
         <FormGroup>
            <Label className='required'>รหัสรอบ</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกรหัสรอบ'
              value={classCode}
              onChange={e => {
                const value = e.target.value
                if (value.length > 15) return
                if (!value.match(/^[a-zA-Z0-9_]*$/)) return
                setClassCode(value)
              }} />
         </FormGroup>
         <FormGroup>
          <Label className='required'>ชื่อรอบ</Label>
          <Input
            type='text'
            placeholder='กรุณากรอกชื่อรอบ'
            value={className}
            onChange={e => {
              const value = e.target.value
              if (value.length > 31) return
              setClassName(value)
            }} />
         </FormGroup>
          <FormGroup>
            <Label className='required'>ภาคเรียน</Label>
            <SelectWrapper>
              <FormSelect
                style={{ width: '100%' }}
                placeholder='เลือกภาคเรียน'
                value={semesterCode}
                onChange={e => setSemesterCode(e.target.value)}>
                <option value=''></option>
                {semesterOptions.map(semester => <option key={semester} value={semester}>{semester}</option>)}
              </FormSelect>
            </SelectWrapper>
          </FormGroup>
         <FormGroup>
            <Label className='required'>ประเภท</Label>
            <SelectWrapper>
              <FormSelect
                style={{ width: '100%' }}
                placeholder='เลือกประเภท'
                value={classType}
                onChange={e => setClassType(e.target.value)}>
                <option value=''></option>
                <option value='Live'>Live</option>
                <option value='Feed'>Feed</option>
                {/* <option value='Private'>Private</option>
                <option value='Hour'>Feed</option>
                <option value='DVD'>DVD</option> */}
              </FormSelect>
            </SelectWrapper>
         </FormGroup>
         <FormGroup>
            <Label className='required'>วิชา</Label>
            <SelectWrapper>
              <FormSelect
                disabled={optionsLoading}
                loading={optionsLoading}
                style={{ width: '100%' }}
                placeholder='เลือกคอร์สเรียน'
                value={courseCode}
                onChange={e => setCourseCode(e.target.value)}>
                <option value=''></option>
                {courseOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
              </FormSelect>
            </SelectWrapper>
         </FormGroup>
         <FormGroup>
            <Label className='required'>ค่าเรียน</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกค่าเรียน'
              value={classFee}
              onChange={e => {
                const fee = e.target.value
                if (fee && isNaN(fee)) return
                setClassFee(fee)
              }} />
         </FormGroup>
         <FormGroup>
          <Label className='required'>วันที่เริ่มต้นการเรียน - วันที่สิ้นสุดการเรียน</Label>
          <div>
            <DatePicker.RangePicker
              style={{ width: '75%' }}
              format='DD/MM/YYYY'
              placeholder={['วันที่เริ่มต้นการเรียน', 'วันที่สิ้นสุดการเรียน']}
              value={[classStartDate, classEndDate]}
              onChange={([classStartDate, classEndDate]) => setClassPeriod({ classStartDate, classEndDate })} />
          </div>
         </FormGroup>
         <FormGroup>
          <Label>จำนวนชั่วโมงเรียน</Label>
          <div>
            <Input
              type='text'
              placeholder='กรุณากรอกจำนวนชั่วโมงเรียน'
              value={totalHours}
              onChange={e => {
                const value = e.target.value
                if (value && isNaN(value)) return
                setTotalHours(value)
              }} />
          </div>
         </FormGroup>
         <FormGroup>
          <Label>จำนวนชั่วโมงชดเชย</Label>
          <div>
            <Input
              type='text'
              placeholder='กรุณากรอกจำนวนชั่วโมงชดเชย'
              value={compHours}
              onChange={e => {
                const value = e.target.value
                if (value && isNaN(value)) return
                setCompHours(value)
              }} />
          </div>
         </FormGroup>
         <FormGroup>
          <Label>เวลาเริ่มต้น</Label>
          <div>
            <Input
              type='text'
              value={classStartTime}
              onChange={e => {
                const value = e.target.value.replace(':', '')
                if (value && isNaN(value)) return
                if (value.length > 4) return
                if (value.slice(0, 2) > 24) return
                setClassStartTime(`${value.slice(0, 2)}${value.length > 1 ? ':' : ''}${value.slice(2)}`)
              }} />
          </div>
         </FormGroup>
         <FormGroup>
          <Label>เวลาสิ้นสุด</Label>
          <div>
            <Input
              type='text'
              value={classEndTime}
              onChange={e => {
                const value = e.target.value.replace(':', '')
                if (value && isNaN(value)) return
                if (value.length > 4) return
                if (value.slice(0, 2) > 24) return
                setClassEndTime(`${value.slice(0, 2)}${value.length > 1 ? ':' : ''}${value.slice(2)}`)
              }} />
          </div>
         </FormGroup>
         <FormGroup>
          <Label>วันที่มีเรียน</Label>
          <div>
            <CheckboxWrapper>
              <Checkbox
                checked={classSunday}
                onChange={() => setClassSunday(checked => !checked)} />
              <CheckboxTitle>อาทิตย์</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classMonday}
                onChange={() => setClassMonday(checked => !checked)} />
              <CheckboxTitle>จันทร์</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classTuesday}
                onChange={() => setClassTuesday(checked => !checked)} />
              <CheckboxTitle>อังคาร</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classWednesday}
                onChange={() => setClassWednesday(checked => !checked)} />
              <CheckboxTitle>พุธ</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classThursday}
                onChange={() => setClassThursday(checked => !checked)} />
              <CheckboxTitle>พฤหัสบดี</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classFriday}
                onChange={() => setClassFriday(checked => !checked)} />
              <CheckboxTitle>ศุกร์</CheckboxTitle>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={classSaturday}
                onChange={(checked) => setClassSaturday(checked => !checked)} />
              <CheckboxTitle>เสาร์</CheckboxTitle>
            </CheckboxWrapper>
          </div>
         </FormGroup>
         <FormGroup>
          
         </FormGroup>
      </Modal>
    </section>
  )  
}

export default OfflineClasses

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const CheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
`
const CheckboxTitle = styled.div`
  margin-left: 5px;
  display: inline;
`
