import React, { useState, useEffect } from 'react'
import { callAPI } from 'helpers'
import { compact, keyBy, padStart, chunk, merge, find } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'

// Components
import theme from 'theme'
import styled from 'styled-components'
import GridContainer from 'components/GridContainer'
import Spinner from 'components/Spinner'
import Heading from 'components/Heading'
import ContentWrapper from 'components/ContentWrapper'
import { Table, DatePicker, Button, Modal } from 'antd'
import FormSelect from 'components/FormSelect'

const LIMIT = 1000
const branchMap = {
  // '0': 'space16',
  '1': 'ขอนแก่น',
  '2': 'นครราชสีมา',
  '3': 'วงเวียนใหญ่',
  '4': 'งามวงศ์วาน',
  '5': 'สยาม',
  '6': 'ชลบุรี',
  '7': 'สุราษฎร์ธานี',
  '8': 'หาดใหญ่'
}
const branchOptions = [{
  label: 'แสดงทุกสาขา', value: 'all'
}].concat(Object.keys(branchMap)
  .map(key => ({ label: branchMap[key], value: key })))

const BookPickup = () => {
  const history = useHistory()
  const location = useLocation()
  const { startDate: _startDate, endDate: _endDate, branchCode: _branchCode } = qs.parse(location.search.slice(1))

  const [loading, setLoading] = useState(false)
  const [pickups, setPickups] = useState([])
  const [pickupCount, setPickupCount] = useState(0)

  const [branchCode, setBranchCode] = useState(_branchCode || 'all')
  useEffect(() => setBranchCode(_branchCode), [_branchCode])

  const [[startDate, endDate], setDates] = useState([
    _startDate ? moment(_startDate, 'YYYY-MM-DD') : moment().add(7, 'day').startOf('week'),
    _endDate ? moment(_endDate, 'YYYY-MM-DD') : moment().add(7, 'day').endOf('week')
  ])
  useEffect(() => setDates([
    _startDate ? moment(_startDate, 'YYYY-MM-DD') : moment().add(7, 'day').startOf('week'),
    _endDate ? moment(_endDate, 'YYYY-MM-DD') : moment().add(7, 'day').endOf('week')
  ]), [_startDate, _endDate])

  useEffect(() => {
    const initialFetch = async () => {
      try {
        setLoading(true)
        const [{ result: _pickupCount }, { result: _pickups }] = await Promise.all([
          callAPI({
            url: '/book-pickups/count',
            query: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
              branchCode: branchCode === 'all' ? undefined : branchCode
            }
          }),
          callAPI({
            url: '/book-pickups',
            query: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
              branchCode: branchCode === 'all' ? undefined : branchCode,
              limit: LIMIT
            }
          })
        ])

        const studentChunks = await Promise.all(chunk(_pickups.map(({ studentId }) => studentId), 100)
          .map(_studentIds => callAPI({
            url: '/by-admin/students',
            query: { studentIds: _studentIds.join(','), limit: LIMIT }
          })
          ))

        const _studentMap = studentChunks.reduce((_map, { result }) => merge(_map, keyBy(result, ({ studentId }) => studentId)), {})

        setLoading(false)
        setPickupCount(_pickupCount)
        setPickups(_pickups.map(_order => ({ ..._order, ..._studentMap[_order.studentId] })))
      } catch (error) {
        console.error('Error while trying to initial fetch.', error)
        setLoading(false)
        setPickups([])
      }
    }

    initialFetch()
  }, [startDate, endDate, branchCode])

  const [statusChanging, setStatusChanging] = useState(false)
  const _onStatusChanged = async (isPicked) => {
    try {
      setStatusChanging(true)
      await callAPI({
        method: 'PUT',
        url: `/book-pickups/${activeId}/status`,
        body: {
          status: isPicked ? 'picked' : null
        }
      })
      setPickups(_orders => _orders.map(_order => _order.orderId === activeId
        ? { ..._order, bookPickedAt: isPicked ? new Date() : null }
        : _order))
      setStatusChanging(false)
      setActiveId()
    } catch (error) {
      console.error(`Error while trying to change book pickup status.`, error)
      setStatusChanging(false)
    }
  }

  const [activeId, setActiveId] = useState()
  const _columns = compact([{
    key: 'button',
    title: '',
    align: 'center',
    render: ({ orderId }) => <Button onClick={() => setActiveId(orderId)}>เปลี่ยนสถานะ</Button>
  }, {
    key: 'pickupStatus',
    title: 'สถานะการรับหนังสือ',
    align: 'center',
    render: ({ bookPickedAt }) => bookPickedAt
      ? <span style={{ color: theme.colors.green }}>รับหนังสือแล้ว</span>
      : <span style={{ color: theme.colors.red }}>ยังไม่ได้รับ</span>
  }, {
    key: 'pickupDate',
    title: 'วันที่รับหนังสือ',
    align: 'center',
    render: ({ pickupDate }) => moment(pickupDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
  }, {
    key: 'deliveryBranch',
    title: 'รับที่สาขา',
    align: 'center',
    render: ({ deliveryBranchCode }) => branchMap[deliveryBranchCode] || '-'
  }, {
    key: 'studentId',
    title: 'รหัสนักเรียน',
    align: 'center',
    render: ({ studentId }) => padStart(studentId, 6, '0')
  }, {
    key: 'studentName',
    title: 'ชื่อนักเรียน',
    width: 200,
    render: ({ studentId, firstName, lastName, nickName }) =>
      `${firstName} ${lastName}${nickName ? ` (${nickName})` : ''}`
  }, {
    key: 'semesterCode',
    title: 'ภาคเรียน',
    align: 'center',
    render: ({ semesterCode }) => semesterCode
  }, {
    key: 'classTypeName',
    title: 'ประเภทรอบ',
    align: 'center',
    render: ({ classTypeName }) => classTypeName || 'Online'
  }, {
    key: 'className',
    title: 'ชื่อรอบ',
    render: ({ className, classCode }) => className || classCode
  }])

  const activeOrder = find(pickups, ({ orderId }) => orderId === activeId) || {}
  return (
    <section>
      <GridContainer>
        <Heading title={`รับหนังสือที่สาขา`} />
        <SearchWrapper>
          <SelectWrapper>
            <span style={{ marginRight: 10 }}>กรองด้วยวันที่</span>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={setDates} />
          </SelectWrapper>
          <SelectWrapper>
            <span style={{ marginRight: 10 }}>กรองด้วยสาขา</span>
            <FormSelect
              style={{ width: 200 }}
              value={branchCode}
              onChange={e => {
                const startDateString = startDate.format('YYYY-MM-DD')
                const endDateString = endDate.format('YYYY-MM-DD')
                history.push(`/book-pickups?startDate=${startDateString}&endDate=${endDateString}&branchCode=${e.target.value}`)
              }}>
              {branchOptions.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
            </FormSelect>
          </SelectWrapper>
        </SearchWrapper>
        {loading ? (
          <ContentWrapper>
            <Spinner />
          </ContentWrapper>
        ) : (
          <ContentWrapper style={{ padding: 0 }}>
            <Table
              rowKey={({ orderId }) => orderId}
              columns={_columns}
              dataSource={pickups}
              pagination={false} />
          </ContentWrapper>
        )}
      </GridContainer>
      <Modal
        visible={activeId}
        footer={null}
        onCancel={() => setActiveId()}>
        <ModalTitle>{`เปลี่ยนสถานะการรับหนังสือของ "${activeOrder.firstName} ${activeOrder.lastName}"`}</ModalTitle>
        <ModalButton loading={statusChanging} disabled={statusChanging} type='primary' onClick={() => _onStatusChanged(true)}>รับหนังสือแล้ว</ModalButton>
        <ModalButton loading={statusChanging} disabled={statusChanging} type='danger' onClick={() => _onStatusChanged(false)}>ยังไม่ได้รับหนังสือ</ModalButton>
      </Modal>
    </section>
  )
}

export default BookPickup

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`
const ModalTitle = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
`
const ModalButton = styled(Button)`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
`
const SelectWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
`
