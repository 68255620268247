import React, { useEffect, useState } from 'react'
import { callAPI, formatComma } from 'helpers'

// Components
import styled from 'styled-components'
import theme from 'theme'
import { ChartSection, SectionContent, SectionHead, SectionBody } from './BasicComponents'
import Spinner from 'components/Spinner'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

// Hooks
import { useEditable } from 'hooks/useEditable'

// นับเฉพาะนักเรียนที่สมัครหลังจากเปิดระบบสมัครสมาชิกออนไลน์ + นักเรียนเก่าที่สมัครผ่านเว็บ (สมมติฐานคือนักเรียนเก่าต้องเคยสมัครอย่างน้อย 1 คอร์ส)
const RADIAN = Math.PI / 180
const SectionPaidStudentRatio = () => {
  useEditable()
  const [loading, setLoading] = useState(false)
  const [ratio, setRatio] = useState({})
  useEffect(() => {
    const fetchPaidStudentRatio = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/analytics/paid-student-ratio'
        })
        setRatio(result)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch paid student ratio: ${error.message}.`))
        setLoading(false)
      }
    }

    fetchPaidStudentRatio()
  }, [])

  const data = [
    { name: 'ซื้อคอร์สแล้ว', value: ratio.paid },
    { name: 'ยังไม่ได้ซื้อคอร์ส', value: ratio.total - ratio.paid }
  ]
  const COLORS = [theme.colors.secondary, theme.colors.gray]

  return (
    <ChartSection>
      <SectionContent>
        <SectionHead>แผนภาพเปรียบเทียบนักเรียนที่ซื้อคอร์สแล้ว/ยังไม่ได้ซื้อคอร์ส</SectionHead>
        {loading ? (
          <SectionBody>
            <Spinner />
          </SectionBody>
        ) : (
          <SectionBody style={{ height: 300, border: '1px solid black', position: 'relative' }}>
            <TextCenter>จากนักเรียนสมัครสมาชิกผ่านระบบออนไลน์ทั้งหมด {formatComma(ratio.total)} คน</TextCenter>
            <ResponsiveContainer width='100%' height='100%'>
              <PieChart width={400} height={200}>
                <Pie
                  isAnimationActive={false}
                  data={data}
                  cx='50%'
                  cy='50%'
                  innerRadius={60}
                  outerRadius={80}
                  dataKey='value'
                  labelLine={false}
                  label={renderCustomizedLabel}>
                  {data.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  })}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </SectionBody>
        )}
      </SectionContent>
    </ChartSection>
  )
}

export default SectionPaidStudentRatio

const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, name, outerRadius, fill, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + outerRadius * cos
  const sy = cy + outerRadius * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${name} ${formatComma(value)} คน`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`(คิดเป็น ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}
const TextCenter = styled.div`
  color: #333;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 0;
  right: 0;
`
