import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { formatComma, highlight } from 'helpers'
import { padStart, compact } from 'lodash'
import qs from 'qs'

// Components
import styled from 'styled-components'
import { Empty, Table, Input, Button } from 'antd'
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import ContentWrapper from 'components/ContentWrapper'
import Spinner from 'components/Spinner'

export default ({ loading, dataSource, count, onSearch, pageSize, onPageChange }) => {
  const location = useLocation()
  const history = useHistory()
  const { page = 1, keyword } = qs.parse(location.search.slice(1))

  const [searchKey, setSearchKey] = useState(keyword)
  const columns = _.compact([{ key: 'studentId',
    title: 'รหัสนักเรียน',
    width: 120,
    align: 'center',
    render: ({ studentId }) =>
      <div>
        {highlight(padStart(studentId, 6, '0'), keyword)}
      </div>
  }, {
    key: 'studentPicture',
    title: 'รูปนักเรียน',
    render: ({ picturePath }) => 
      <PictureWrapper>
        <Picture src={picturePath} />
        <img className='pic-hovered' src={picturePath} />
      </PictureWrapper>
  }, {
    key: 'studentName',
    title: 'ชื่อนักเรียน',
    render: ({ studentId, firstName, lastName, nickName }) =>
      <div>
        {highlight(`${firstName} ${lastName}${nickName ? ` (${nickName})` : ''}`, keyword)}
      </div>
  }, {
    key: 'email',
    title: 'อีเมล',
    render: ({ studentId, email }) =>
      <div>
        {highlight(email || '', keyword)}
      </div>
  }, {
    key: 'phone',
    title: 'เบอร์โทร',
    render: ({ studentId, phone }) =>
      <div>
        {highlight(phone || '', keyword)}
      </div>
  }, {
    key: 'gender',
    title: 'เพศ',
    width: 100,
    align: 'center',
    render: ({ studentId, gender }) =>
      <div>
        {gender === 'M' ? 'ชาย' : gender === 'F' ? 'หญิง' : 'ไม่ระบุ'}
      </div>
  }, {
    key: 'address',
    title: 'ที่อยู่',
    render: ({ studentId, fullAddress, address, building, village, subStreet, street, county, city, province, postalCode }) =>
      <div>
        {fullAddress || compact([address, building, village, subStreet, street, county, city, province, postalCode]).join(' ')}
      </div>
  }, {
    key: 'buttons',
    title: '',
    render: ({ studentId }) => (
      <div>
        <Button
          icon='edit'
          onClick={() => history.push(`/students/${studentId}`)} />
      </div>
    )
  }])

  return (
    <GridContainer>
      <Heading
        title={`ข้อมูลนักเรียน ${count ? `(${formatComma(count)})` : ''}`}
        description={keyword ? `ค้นหาด้วยคำค้น "${keyword}"` : ''} />
      <SearchWrapper>
        <Input.Search
          placeholder='ค้นหานักเรียนด้วยรหัส, ชื่อ, อีเมล หรือเบอร์โทร'
          value={searchKey}
          onChange={e => setSearchKey(e.target.value)}
          onSearch={onSearch} />
      </SearchWrapper>
      {loading ? (
        <ContentWrapper><Spinner /></ContentWrapper>
      ) : dataSource.length ? (
        <ContentWrapper style={{ padding: 0 }}>
          <Table
            rowKey={({ studentId }) => studentId}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: parseInt(page, 10),
              pageSize,
              total: count,
              onChange: onPageChange
            }} />
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>ยังไม่มีข้อมูลนักเรียน</span>} />
        </ContentWrapper>
      )}
    </GridContainer>
  )
}

const SearchWrapper = styled.div`
  width: 50%; 
  margin-bottom: 20px;
`
const PictureWrapper = styled.div`
  position: relative;
  &:hover {
    .pic-hovered { display: block; }
  }

  .pic-hovered {
    display: none;
    width: 300px;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 100%;
  }
`
const Picture = styled.img`
  width: 60px;
  height: 60px;
  display: block;
  object-fit: cover;
`