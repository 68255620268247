import React, { useEffect, useState, useRef } from 'react'
import { Form, Input, message } from 'antd'
import qs from 'qs'
import styled from 'styled-components'

// Hooks
import { useHistory, useLocation } from 'react-router-dom'
import { callAPI, getTheme } from 'helpers'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import { Empty, Button, Modal, Table } from 'antd'
import FormGroup, { Label, SelectWrapper } from 'components/FormGroup'
import Spinner from 'components/Spinner'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'

// Hooks
import { useEditable } from 'hooks/useEditable'

// Data
import roles from 'data/roles'

const Admins = () => {
  useEditable()

  const history = useHistory()
  const location = useLocation()
  const { keyword } = qs.parse(location.search.slice(1))
  const inputRef = useRef()

  const [modalVisible, setModalVisible] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({ url: '/admins/all' })
        setAdmins(result)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch admins: ${error.reason}.`))
        setLoading(false)
        setAdmins([])
      }
    }

    _initialFetch()
  }, [])

  const [searchKey, setSearchKey] = useState(keyword)
  useEffect(() => setSearchKey(keyword), [keyword])
  
  const [adminId, setAdminId] = useState()
  const [username, setUsername] = useState('')
  const [fullName, setFullName] = useState('')
  const [roleName, setRoleName] = useState()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordUpdating, setPasswordUpdating] = useState(false)
  const _onPasswordUpdate = async () => {
    if (password !== confirmPassword) return message.error('รหัสผ่านทั้งสองไม่ตรงกัน')
    try {
      setPasswordUpdating(true)
      await callAPI({
        method: 'PUT',
        url: `/admins/${adminId}/password`,
        body: { password }
      })
      setPasswordUpdating(false)
      setPasswordModalVisible(false)
    } catch (error) {
      console.error(new Error(`Error while trying to update password: ${error.reason}.`))
      setPasswordUpdating(false)
      message.error(error.reason || 'เปลี่ยนแปลงรหัสผ่านไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [adding, setAdding] = useState(false)
  const _onAdminAdd = async () => {
    if (password !== confirmPassword) return message.error('รหัสผ่านทั้งสองไม่ตรงกัน')
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/admins',
        body: {
          username,
          password,
          fullName,
          roleName
        }
      })
      setAdding(false)
      setModalVisible(false)
      setAdmins(_admins => [result].concat(_admins))
      message.success('เพิ่มข้อมูลผู้ดูแลระบบไม่สำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add a new admin: ${error.reason}.`))
      setAdding(false)
      message.error(error.reason || 'เพิ่มข้อมูลผู้ดูแลระบบไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onAdminSave = async () => {
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/admins/${adminId}`,
        body: {
          username,
          fullName,
          roleName
        }
      })
      setAdmins(_admins => _admins.map(_admin => _admin.adminId === result.adminId ? result : _admin))
      setSaving(false)
      setModalVisible(false)
      message.success('บันทึกข้อมูลผู้ดูแลระบบสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to save an adminof ID: ${adminId}`, error.reason))
      setSaving(false)
      message.error(error.reason || 'บันทึกข้อมูลผู้ดูแลระบบไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onAdminRemove = async (adminId) => {
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/admins/${adminId}`
      })
      setRemoving(false)
      setAdmins(_admins => _admins.filter(_admin => _admin.adminId !== adminId))
    } catch (error) {
      console.error(new Error(`Error while trying to remove an admin of ID ${adminId}: ${error.reason}.`))
      setRemoving(false)
      message.error(error.reason || 'ลบข้อมูลผู้ดูแลระบบไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const columns = () => [
    { title: 'ID', keyword: 'adminId', align: 'center', render: ({ adminId }) => adminId },
    { title: 'Username', keyword: 'username', align: 'center', render: ({ username }) => username },
    { title: 'ชื่อผู้ดูแลระบบ', keyword: 'fullName', align: 'center', render: ({ fullName }) => fullName },
    { title: 'ระดับ', keyword: 'role', align: 'center', render:({ roleName }) => roleName || 'ไม่ระบุ' },
    { title: '', keyword: 'actions', render: ({ adminId, username, fullName, roleName }) =>
      <>
        <Button
          disabled={adding || saving || removing}
          style={{ marginRight: 10 }}
          icon='edit'
          onClick={() => {
            setModalVisible(true)
            setAdminId(adminId)
            setUsername(username)
            setFullName(fullName)
            setRoleName(roleName)
          }} />
        <Button
          style={{ marginRight: 10 }}
          icon='lock'
          onClick={() => {
            setPasswordModalVisible(true)
            setAdminId(adminId)
          }} />
        <CustomPopconfirm
          onClick={e => e.stopPropagation()}
          title={`ยืนยันที่จะลบผู้ดูแลระบบ ${username}`}
          onConfirm={() => _onAdminRemove(adminId)}>
          <Button
            type='danger'
            icon='delete' />
        </CustomPopconfirm>
      </>
    }
  ]


  useEffect(() => {
    if (!modalVisible) {
      setAdminId()
      setUsername('')
      setPassword('')
      setFullName('')
      setRoleName('')
    }
  }, [modalVisible])
  
  useEffect(() => {
    if (!passwordModalVisible) {
      setAdminId()
      setPassword()
      setConfirmPassword()
    }
  }, [passwordModalVisible])

  const filteredAdmins = admins.filter(admin => {
    if (!keyword) return true
    const _lower = keyword.toLowerCase()
    return (admin.username || '').toLowerCase().includes(_lower) ||
    (admin.fullName || '').toLowerCase().includes(_lower)
  })
  return (
    <section>
      <GridContainer>
        <Heading
          title={`ผู้ดูแลระบบ (${admins.length})`}
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มผู้ดูแลระบบ' />
        <div style={{ position: 'relative' }}>
          <SearchWrapper>
            <Input.Search
              ref={inputRef}
              placeholder='ค้นหาด้วยชื่อผู้ดูแลระบบ, ชื่อเจ้าของบัญชี'
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
              onSearch={() => history.push(`/admins?keyword=${searchKey}`)} />
          </SearchWrapper>
        </div>
        {loading ? (
          <Body><Spinner /></Body>
        ) : filteredAdmins.length ? (
          <Body style={{ padding: 0 }}>
            <Table
              rowKey={({ adminId }) => adminId}
              columns={columns()}
              dataSource={filteredAdmins} />
          </Body>
        ) : admins.length ? (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ไม่พบข้อมูลผู้ดูแลระบบ</span>}>
              <Button type='primary' icon='search' onClick={() => {
                inputRef.current.focus()
                setSearchKey('')
              }}>ค้นหาด้วยชื่อผู้ดูแลระบบ, ชื่อเจ้าของบัญชีอื่น</Button>
            </Empty>
          </Body>
        ) : (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลผู้ดูแลระบบ</span>}>
              <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มเฉลยแรกของคุณ</Button>
            </Empty>
          </Body>
        )}
      </GridContainer>
      <Modal
        visible={modalVisible}
        title={adminId ? 'แก้ไขข้อมูลผู้ดูแลระบบ' : 'เพิ่มข้อมูลผู้ดูแลระบบ'}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!username || !fullName || !roleName || (adminId ? false : (!password || !confirmPassword))}
            loading={adminId ? saving : adding}
            onClick={adminId ? _onAdminSave : _onAdminAdd}>
            {adminId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}  
          </Button>
        ]}>
          <FormGroup>
            <Label className='required'>Username</Label>
            <Input
              type='text'
              placeholder='กรุณากรอก Username'
              value={username}
              onChange={e => setUsername(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label className='required'>ชื่อผู้ดูแลระบบ</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกชื่อผู้ดูแลระบบ'
              value={fullName}
              onChange={e => setFullName(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label className='required'>ระดับ</Label>
            <SelectWrapper>
              <FormSelect
                style={{ width: '100%' }}
                placeholder='เลือกระดับ'
                value={roleName}
                onChange={e => setRoleName(e.target.value)}>
                <option></option>
                {roles.map((role) => <option key={role} value={role}>{role}</option>)}
                <option value='Owner'>Owner</option>
              </FormSelect>
            </SelectWrapper>
          </FormGroup>
          {!adminId && (
            <>
              <FormGroup>
                <Label className='required'>รหัสผ่าน</Label>
                <Input
                  type='password'
                  placeholder='กรุณากรอกรหัสผ่าน'
                  value={password}
                  onChange={e => setPassword(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label className='required'>ยืนยันรหัสผ่าน</Label>
                <Input
                  type='password'
                  placeholder='กรุณายืนยันรหัสผ่าน'
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)} />
              </FormGroup>
            </>
          )}
        </Modal>

      <Modal
        visible={passwordModalVisible}
        title='เปลี่ยนรหัสผ่าน'
        onCancel={() => setPasswordModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setPasswordModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!password || !confirmPassword}
            loading={passwordUpdating}
            onClick={_onPasswordUpdate}>
            ยืนยันการเปลี่ยนแปลง
          </Button>
        ]}>
          <FormGroup>
            <Label className='required'>รหัสผ่าน</Label>
            <Input
              type='password'
              placeholder='กรุณากรอกรหัสผ่าน'
              value={password}
              onChange={e => setPassword(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label className='required'>ยืนยันรหัสผ่าน</Label>
            <Input
              type='password'
              placeholder='กรุณายืนยันรหัสผ่าน'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)} />
          </FormGroup>
      </Modal>
    </section>
  )
}

export default Admins

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const SearchWrapper = styled.div`
  width: 50%; 
  margin-bottom: 20px;
`
