import React, { useState, useEffect, useRef } from 'react'
import { getTheme, callAPI, formatComma, viewportHeight } from 'helpers'
import styled from 'styled-components'

// Components
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import FormGroup from 'components/FormGroup'
import { Empty, Button, Table, Modal, Input, message } from 'antd'

// Hooks
import { useEditable } from 'hooks/useEditable'

const columns = [
  { title: 'รหัสโรงเรียน', key: 'schoolCode', align: 'center', render: ({ schoolCode }) => schoolCode },
  { title: 'ชื่อโรงเรียน', key: 'schoolName', render: ({ schoolName }) => schoolName }
]

const Schools = () => {
  const editable = useEditable()
  const inputRef = useRef()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [schools, setSchools] = useState([])
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/schools'
        })
        setSchools(result)
        setLoading(false)
      } catch (error) {
        console.error('Error while trying to fetch schools.', error)
        setLoading(false)
        setSchools([])
      }
    }

    _initialFetch()
  }, [])

  const [schoolCode, setSchoolCode] = useState('')
  const [schoolName, setSchoolName] = useState('')
  useEffect(() => {
    if (!modalVisible) {
      setSchoolCode('')
      setSchoolName('')
    }
  }, [modalVisible])

  const [adding, setAdding] = useState(false)
  const _onSchoolAdd = async () => {
    if (!editable || adding) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/schools',
        body: {
          schoolCode,
          schoolName
        }
      })
      setSchools(_schools => _schools.concat([result]))
      setAdding(false)
      setModalVisible(false)
      message.success('เพิ่มข้อมูลโรงเรียนสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add a new school: ${error.message}.`))
      setAdding(false)
      message.error(error.reason || 'เพิ่มข้อมูลโรงเรียนไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const _filteredSchools = schools.filter(school => {
    if (!keyword) return true
    const _lower = keyword.toLowerCase()
    return (school.schoolName || '').toLowerCase().includes(_lower) ||
    (school.schoolCode || '').toLowerCase().includes(_lower)
  })

  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title={`ข้อมูลโรงเรียน (${schools.length})`}
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มข้อมูลโรงเรียน' />
        <SearchWrapper>
          <Input
            ref={inputRef}
            placeholder='ค้นหาด้วยชื่อโรงเรียน, รหัสโรงเรียน'
            value={keyword}
            onChange={e => setKeyword(e.target.value)} />
        </SearchWrapper>
        {loading ? (
          <Body><Spinner /></Body>
        ) : _filteredSchools.length ? (
          <Body style={{ padding: 0 }}>
            <Table
              rowKey={({ schoolId }) => schoolId}
              columns={columns}
              dataSource={_filteredSchools} />
          </Body>
        ) : schools.length ? (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ไม่พบข้อมูลโรงเรียนที่ค้นหา</span>}>
              <Button type='primary' icon='search' onClick={() => {
                inputRef.current.focus()
                setKeyword('')
              }}>ค้นหาด้วยชื่อโรงเรียน, รหัสโรงเรียนอื่น</Button>
            </Empty>
          </Body>
        ) : (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลโรงเรียน</span>}>
              <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มโรงเรียนแรกของคุณ</Button>
            </Empty>
          </Body>
        )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title='เพิ่มข้อมูลโรงเรียน'
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!schoolCode || !schoolName}
            loading={adding}
            onClick={_onSchoolAdd}>
          เพิ่มข้อมูลโรงเรียน
          </Button>
        ]}>
        <FormGroup>
          <Label>รหัสโรงเรียน</Label>
          <Input
            type='text'
            placeholder='กรุณากรอกรหัสโรงเรียน เช่น กท01'
            value={schoolCode}
            onChange={e => {
              const _code = e.target.value
              if (_code.length > 5) return
              setSchoolCode(_code)
            }} />
        </FormGroup>
        <FormGroup>
          <Label>ชื่อโรงเรียน</Label>
          <Input
            type='text'
            placeholder='กรุณากรอกชื่อโรงเรียน เช่น เกษมพิทยา'
            value={schoolName}
            onChange={e => {
              const _name = e.target.value
              if (_name.length > 100) return
              setSchoolName(_name)
            }} />
        </FormGroup>
      </Modal>
    </section>
  )
}

export default Schools

const GridContainer = styled.div`
  @media(min-width: 768px) {
    max-width: 748px;
    margin: 0 auto;
  }
  @media(max-width: 767px) {
    margin: 0px 10px;
    width: calc(100% - 20px);
  }
`
const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  width: 25%;
`
const SearchWrapper = styled.div`
  width: 50%; 
  margin-bottom: 20px;
`
