import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { callAPI, getTheme, tableWidth } from 'helpers'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import { Table, Icon, Modal, Checkbox } from 'antd'

// Hooks
import { useEditable } from 'hooks/useEditable'

// Data
import menuConfigs from 'data/menus.json'
import roleConfigs from 'data/roles.json'

const Permissions = () => {
  useEditable()
  
  const [loading, setLoading] = useState(false)
  const [allPermissions, setAllPermissions] = useState([])
  useEffect(() => {
    const fetchAllPermissions = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({ url: '/permissions/all' })
        setAllPermissions(result)
        setLoading(false)
      } catch (error) {
        console.error(`Error while trying to fetch all permissions: ${error.message}`)
        setLoading(false)
      }
    }

    fetchAllPermissions()
  }, [])

  const [saving, setSaving] = useState(false)
  const _onSave = async () => {
    if (saving) return
    try {
      setSaving(true)
      const body = {
        role: _.get(activeCell, 'role'),
        pathname: _.get(activeCell, 'menu.to'),
        loadable: loadable ? 1 : 0,
        editable: editable ? 1 : 0
      }
      await callAPI({
        method: 'PUT',
        url: '/permissions',
        body
      })
      
      setAllPermissions(_permissions => {
        if (_.find(_permissions, _p => _p.role === _.get(activeCell, 'role') && _p.pathname === _.get(activeCell, 'menu.to'))) {
          return _permissions.map(_p => {
            if (_p.role === body.role && _p.pathname === body.pathname) {
              return {
                role: _p.role,
                pathname: _p.pathname,
                loadable,
                editable
              }
            }
  
            return _p
          })
        }
        return _permissions.concat([body])
      })
      setSaving(false)
      setActiveCell()
    } catch (error) {
      console.error(`Error while trying to save: ${error.message}`)
      setSaving(false)
    }
  }

  const [activeCell, setActiveCell] = useState()
  const [loadable, setLoadable] = useState(false)
  const [editable, setEditable] = useState(false)
  useEffect(() => {
    if (activeCell) {
      setLoadable(Boolean(_.get(activeCell, 'permission.loadable')))
      setEditable(Boolean(_.get(activeCell, 'permission.editable')))
    } else {
      setLoadable(false)
      setEditable(false)
    }
  }, [activeCell])
  useEffect(() => {
    if (editable) { setLoadable(true) }
  }, [editable])
  useEffect(() => {
    if (!loadable) { setEditable(false) }
  }, [loadable])
  
  const permissionsByRole = _.groupBy(allPermissions, 'role')
  const _columns = () => roleConfigs.reduce((cols, role) => {
    return cols.concat({
      title: <div><div>สิทธิ์ของ</div><div>{role}</div></div>,
      align: 'center',
      width: 180,
      render: (menu) => {
        const permission = _.find(permissionsByRole[role] || [], ({ pathname }) => pathname === menu.to)
        if (!permission || (permission && !permission.loadable && !permission.editable)) return <Icon style={{ color: 'red', cursor: 'pointer' }} type='close' onClick={() => setActiveCell({ menu, role, permission })} />
        if (permission.loadable && !permission.editable) return <Icon style={{ color: 'green', cursor: 'pointer' }} type='check' onClick={() => setActiveCell({ menu, role, permission })} />
        return (
          <div style={{ cursor: 'pointer'}} onClick={() => setActiveCell({ menu, role, permission })}>
            <Icon style={{ color: 'green' }} type='check' />
            <Icon style={{ color: 'green' }} type='check' />
          </div>
        )
      }
    })
  }, [
    { title: 'หน้า', align: 'left', width: 300, fixed: 'left', render: ({ title }) => title },
    { title: 'กลุ่ม', align: 'left', width: 300, render: ({ category }) => category },
    { title: 'URL', align: 'left', width: 300, render: ({ to }) => to }
  ])

  const dataSource = menuConfigs.reduce((array, { title, menus }) =>
    array.concat(menus
      .filter(({ to }) => !['/admins', '/permissions'].includes(to))
      .reduce((total, menu) => {
        if ((menu.submenus || []).length) return total.concat(menu.activePathname === menu.to ? [{ ...menu, category: title }] : []).concat(menu.submenus.map(_sm => ({ ..._sm, category: `${title} > ${menu.title}` })))
        return total.concat([{ ...menu, category: title }])
      }, []))
  , [])
  return (
    <section>
      <GridContainer>
        <Heading title='การจัดการสิทธิ์'>
          <IconDescription>
            <Icon style={{ color: 'red' }} type='close' /> ไม่มีสิทธิ์
          </IconDescription>
          <Separator>|</Separator>
          <IconDescription>
            <Icon style={{ color: 'green' }} type='check' /> เข้าถึงได้
          </IconDescription>
          <Separator>|</Separator>
          <IconDescription>
            <Icon style={{ color: 'green' }} type='check' /><Icon style={{ color: 'green' }} type='check' /> แก้ไขได้
          </IconDescription>
        </Heading>
        <Body style={{ padding: 0 }}>
          <Table
            columns={_columns()}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 1800 }} />
        </Body>
      </GridContainer>
      <Modal
        title={`แก้ไขสิทธิ์ของ ${_.get(activeCell, 'role')}`}
        visible={Boolean(activeCell)}
        onOk={_onSave}
        onCancel={() => setActiveCell()}>
        <ModalRow>
          <ModalCol><strong>ระดับ</strong></ModalCol>
          <ModalCol>{_.get(activeCell, 'role')}</ModalCol>
        </ModalRow>
        <ModalRow>
          <ModalCol><strong>กลุ่มเมนู</strong></ModalCol>
          <ModalCol>{_.get(activeCell, 'menu.category')}</ModalCol>
        </ModalRow>
        <ModalRow>
          <ModalCol><strong>ชื่อเมนู</strong></ModalCol>
          <ModalCol>{_.get(activeCell, 'menu.title')}</ModalCol>
        </ModalRow>
        <ModalRow>
          <ModalCol><strong>จัดการสิทธิ์</strong></ModalCol>
          <ModalCol>
            <Checkbox checked={loadable} onChange={() => setLoadable(b => !b)}>เข้าถึงได้</Checkbox>
            <Checkbox checked={editable} onChange={() => setEditable(b => !b)}>แก้ไขข้อมูลได้</Checkbox>
          </ModalCol>
        </ModalRow>
      </Modal>
    </section>
  )
}

export default Permissions

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const ModalRow = styled.div`
  margin-bottom: 10px;
`
const ModalCol = styled.div`
  display: inline-block;
  vertical-align: top;
  &:nth-child(odd) { width: 20%; }
  &:nth-child(even) { width: 80%; }
`
const IconDescription = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
`
const Separator = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
`
