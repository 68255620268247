import styled from 'styled-components'

export default styled.div`
  @media(min-width: 1220px) {
    max-width: 1200px;
    margin: 0 auto;
  }
  @media(max-width: 1219px) {
    margin: 0px 10px;
    width: calc(100% - 20px);
  }
`
