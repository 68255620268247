import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { callAPI, formatComma, exportAsExcel } from 'helpers'
import moment from 'moment'
import { omitBy, isNil, reverse } from 'lodash'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'

// Components
import Heading from 'components/Heading'
import { Select, Table, Button } from 'antd'

// Hooks
import { useCourseOptions } from 'hooks/custom'
import { useEditable } from 'hooks/useEditable'

// JSON
import provinces from 'data/provinces.json'

const provinceMapByKey = provinces.reduce((map, { provinceKey, postalCodes }) => ({ ...map, [provinceKey]: postalCodes }), {})
const regionMap = provinces.reduce((map, { region, postalCodes }) => ({ ...map, [region]: (map[region] || []).concat(postalCodes || []) }), {})
const postalCodeMap = provinces.reduce((map, { province, postalCodes }) => postalCodes.reduce((_map, code) => ({ ..._map, [code]: province }), map), {})
const provinceOptions = provinces
  .map(({ provinceKey, province }) => ({ value: provinceKey, label: province }))
  .sort((a, b) => a.label.localeCompare(b.label))
  .slice()

const columns = [{
  key: 'studentId',
  title: 'รหัสนักเรียน',
  align: 'center',
  render: ({ studentId }) => studentId
}, {
  key: 'studentName',
  title: 'ชื่อ-สกุล',
  align: 'center',
  render: ({ firstName, lastName, nickName }) => `${firstName} ${lastName} ${nickName ? `(${nickName})` : ''}`
}, {
  key: 'province',
  title: 'จังหวัด',
  align: 'center',
  render: ({ postalCode }) => postalCodeMap[postalCode] || '-'
}, {
  key: 'currentMajor',
  title: 'ระดับชั้นเรียนปัจจุบัน',
  align: 'center',
  render: ({ grade1Year }) => {
    const grade = moment().year() + 543 - grade1Year
    return `${grade > 5 ? 'ม' : 'ป'}.${(grade % 6) + 1}`
  }
}, {
  key: 'phoneMobile',
  title: 'เบอร์โทรศัพท์มือถือ',
  align: 'center',
  render: ({ phoneMobile }, _, index, exportable) => {
    if (exportable) return phoneMobile
    return phoneMobile.split(',').map(num => <div key={num}>{`${num}`.trim()}</div>)
  }
}]

const limit = 100
const StudentNumbers = () => {
  useEditable()
  const history = useHistory()
  const location = useLocation()
  const { page = 1, province = 'all', grade1Year = 'all', courseCode = 'all', region = 'all' } = qs.parse(location.search.slice(1))
  const { loading: optionsLoading, options: courseOptions } = useCourseOptions()

  const [counting, setCounting] = useState(false)
  const [studentCount, setStudentCount] = useState(0)
  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        setCounting(true)
        const { result: { count } } = await callAPI({
          url: '/analytics/student-numbers/count',
          body: omitBy({
            postalCodes: province === 'all'
              ? (region === 'all' ? undefined : regionMap[region])
              : [province].concat(provinceMapByKey[province]),
            grade1Year: grade1Year === 'all' ? undefined : grade1Year,
            courseCode: courseCode === 'all' ? undefined : courseCode
          }, isNil)
        })
        setCounting(false)
        setStudentCount(count)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch student count: ${error.message}.`))
        setCounting(false)
      }
    }

    fetchStudentCount()
  }, [province, region, grade1Year, courseCode])

  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState([])
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true)
        const { result: _students } = await callAPI({
          url: '/analytics/student-numbers',
          body: omitBy({
            postalCodes: province === 'all'
              ? (region === 'all' ? undefined : regionMap[region])
              : [province].concat(provinceMapByKey[province]),
            grade1Year: grade1Year === 'all' ? undefined : grade1Year,
            courseCode: courseCode === 'all' ? undefined : courseCode,
            offset: (page - 1) * limit,
            limit
          }, isNil)
        })
        setLoading(false)
        setStudents(_students)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch data: ${error.message}.`))
        setLoading(false)
      }
    }

    fetchStudents()
  }, [province, region, grade1Year, courseCode, page])

  const [exporting, setExporting] = useState(false)
  const _onExport = async () => {
    try {
      setExporting(true)
      const { result } = await callAPI({
        url: '/analytics/student-numbers',
        body: omitBy({
          postalCodes: province === 'all'
            ? undefined
            : [province].concat(provinceMapByKey[province]),
          grade1Year: grade1Year === 'all' ? undefined : grade1Year,
          courseCode: courseCode === 'all' ? undefined : courseCode,
          limit: 65535
        }, isNil)
      })
      setExporting(false)
      exportAsExcel({
        columns,
        data: result,
        filename: `student-numbers_${new Date().getTime().toString(16)}.xlsx`,
        sheetName: 'Sheet1'
      })
    } catch (error) {
      console.error(new Error(`Error while trying to export: ${error.message}.`))
      setExporting(false)
    }
  }

  return (
    <div>
      <Heading title={`ข้อมูลสถิติเบอร์โทรนักเรียน${counting ? '' : ` (${formatComma(studentCount)})`}`} />
      <FilterWrapper>
        <FilterHead>ตัวกรองข้อมูล</FilterHead>
        <FilterBody>
          <FilterCol>
            <Label>กรองข้อมูลด้วยจังหวัด</Label>
            <Select
              style={{ width: '100%' }}
              value={province}
              onChange={value => history.push(`/analytics/student-numbers?province=${value}&grade1Year=${grade1Year}&courseCode=${courseCode}&page=1`)}>
              <Select.Option key='all'>แสดงทุกจังหวัด</Select.Option>
              {provinceOptions.map(({ label, value }) =>
                <Select.Option key={value} value={value}>{label}</Select.Option>
              )}
            </Select>

          </FilterCol>
          <FilterCol>
            <Label>กรองข้อมูลด้วยระดับชั้นเรียนปัจจุบัน</Label>
            <Select
              style={{ width: '100%' }}
              value={grade1Year}
              onChange={value => history.push(`/analytics/student-numbers?province=${province}&grade1Year=${value}&courseCode=${courseCode}&page=1`)}>
              <Select.Option key='all'>แสดงทุกระดับชั้นเรียน</Select.Option>
              {reverse(Array(12).fill().map((_, index) =>
                <Select.Option key={moment().year() + 543 - index}>{index < 6 ? 'ป' : 'ม'}.{(index % 6) + 1}</Select.Option>
              ))}
            </Select>
          </FilterCol>
          <FilterCol>
            <Label>กรองข้อมูลด้วยคอร์สเรียนที่เคยสมัคร</Label>
            <Select
              style={{ width: '100%' }}
              disabled={optionsLoading}
              loading={optionsLoading}
              value={courseCode}
              onChange={value => history.push(`/analytics/student-numbers?province=${province}&grade1Year=${grade1Year}&courseCode=${value}&page=1`)}>
              <Select.Option key='all'>แสดงทุกคอร์สเรียน</Select.Option>
              {courseOptions.map(({ label, value }) => <Select.Option key={value} value={value}>{value}: {label}</Select.Option>)}
            </Select>
          </FilterCol>
        </FilterBody>
      </FilterWrapper>
      <ButtonRow>
        <Button
          loading={exporting}
          icon='export'
          onClick={_onExport}>Export</Button>
      </ButtonRow>
      <Body>
        <Table
          loading={loading}
          rowKey={({ studentId }) => studentId}
          columns={columns}
          dataSource={students}
          pagination={{
            current: parseInt(page, 10),
            pageSize: limit,
            total: studentCount,
            onChange: (page) => {
              history.push(`/analytics/student-numbers?province=${province}&grade1Year=${grade1Year}&courseCode=${courseCode}&page=${page}`)
              window.scrollTo(0, 0)
            }
          }} />
      </Body>
    </div>
  )
}

export default StudentNumbers

const FilterWrapper = styled.div`
  position: relative;
  background-color: white;
  overflow: hidden;
`
const FilterHead = styled.div`
  line-height: 30px;
  padding-left: 15px;
  background-color: ${theme.colors.primary};
  color: white;
`
const FilterBody = styled.div`
  padding: 15px;
`
const FilterCol = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin-right: 2%;
  &:last-of-type { margin-right: 0; }
`
const Label = styled.div`
  color: black;
  font-weight: 700;
  margin-bottom: 5px;
`
const ButtonRow = styled.div`
  margin-top: 15px;
  text-align: right;
`
const Body = styled.div`
  margin-top: 15px;
  table {
    background-color: white;
  }
`
