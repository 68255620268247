import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { getTheme, getVimeoId, callAPI } from 'helpers'

// Components
import styled from 'styled-components'
import FormGroup from 'components/FormGroup'
import { Input } from 'antd'
import Spinner from 'components/Spinner'

export default forwardRef(({ classItem }, ref) => {
  const [loading, setLoading] = useState(false)
  const [vimeoURL, setVimeoURL] = useState()
  const [videoTitle, setVideoTitle] = useState('')
  const [videoDescription, setVideoDescription] = useState('')

  const [vimeoURLError, setVimeoURLError] = useState()
  const [videoTitleError, setVideoTitleError] = useState()
  const vimeoId = getVimeoId(vimeoURL)

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const _vimeoURLError = !vimeoURL && 'กรุณากรอก Vimeo URL'
      setVimeoURLError(_vimeoURLError)

      const _videoTitleError = !videoTitle && 'กรุณากรอกชื่อบทเรียน'
      setVideoTitleError(_videoTitleError)

      return [_vimeoURLError, _videoTitleError].some(error => error) ? false : {
        vimeoId,
        videoTitle,
        videoDescription
      }
    }
  }))

  useEffect(() => {
    if (classItem) {
      setVideoTitle(classItem.videoTitle)
      setVideoDescription(classItem.videoDescription)
      setVimeoURL(`https://vimeo.com/${classItem.vimeoId}`)
    }
  }, [classItem])

  useEffect(() => {
    const getVimeo = async () => {
      setLoading(true)
      try {
        const { result: { title, description } } = await callAPI({
          url: `/proxy/vimeo/${vimeoId}`
        })
        setVideoTitle(title)
        setVideoDescription(description)
        setLoading(false)
      } catch (error) {
        console.error('Error while trying to get vimeo info.', error)
        setLoading(false)
      }
    }
    if (vimeoId && !videoTitle && !videoDescription) {
      getVimeo()
    }
  }, [vimeoId, videoTitle, videoDescription])

  return (
    <div>
      <FormGroup>
        <Label>Vimeo URL</Label>
        <Input type='text' value={vimeoURL}
          placeholder='https://vimeo.com/•••••••••'
          onChange={e => setVimeoURL(e.target.value)} />
        {vimeoURLError && <ErrorText>{vimeoURLError}</ErrorText>}
      </FormGroup>
      {loading ? (
        <Spinner />
      ) : !vimeoId ? null : (
        <div>
          <iframe
            title={vimeoId}
            src={`https://player.vimeo.com/video/${vimeoId}`}
            width={472} height={472 * 9 / 16}
            frameBorder={0}
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen />
          <FormGroup>
            <Label>ชื่อบทเรียน</Label>
            <Input type='text' value={videoTitle}
              onChange={e => setVideoTitle(e.target.value)} />
            {videoTitleError && <ErrorText>{videoTitleError}</ErrorText>}
          </FormGroup>
          <FormGroup>
            <Label>รายละเอียด</Label>
            <Input.TextArea
              style={{ resize: 'none' }}
              rows={3} value={videoDescription}
              onChange={e => setVideoDescription(e.target.value)} />
          </FormGroup>
        </div>
      )}
    </div>
  )
})

const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  width: 25%;
`
const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
`
