module.exports = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING ? {
      apiHost: 'https://dev-api.oplus-school.com',
      cloudAPIHost: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api'
    } : {
      apiHost: 'https://api.oplus-school.com',
      cloudAPIHost: 'https://asia-east2-o-plus-22e7f.cloudfunctions.net/api'
    }
  }

  // Default config
  return {
    apiHost: 'https://api.oplus-school.com',
    // apiHost: 'https://dev-api.oplus-school.com',
    // cloudAPIHost: 'http://localhost:5001/o-plus-staging/asia-east2/api'
    cloudAPIHost: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api'
    // cloudAPIHost: 'https://asia-east2-o-plus-22e7f.cloudfunctions.net/api'
  }
  // return {
  //   apiHost: 'https://dev-api.oplus-school.com',
  //   cloudAPIHost: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api'
  // }
})()
