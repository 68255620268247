import styled from 'styled-components'
export default styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  ${props => props.required && `
    &:after {
      content: '*';
      color: ${props.theme.colors.red};
    }
  `}
`
