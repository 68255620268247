import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { callAPI } from 'helpers'

export const useCourseOptions = () => {
  const dispatch = useDispatch()
  const _courseOptions = useSelector(state => get(state, 'app.courseOptions')) || []
  const _loaded = useSelector(state => get(state, 'app.courseOptionsLoaded')) || false

  const [result, setResult] = useState({ options: _courseOptions, loading: false })

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setResult({ options: [], loading: true })
        // Fetch course options in order to create select.
        const { result: courseOptions } = await callAPI({
          method: 'GET',
          url: '/course-options'
        })

        dispatch({ type: 'COURSE_OPTIONS_UPDATED', options: courseOptions })
        setResult({ options: courseOptions, loading: false })
      } catch (error) {
        console.error('Error while trying to fetch options.', error.message)
        setResult({ options: [], loading: false })
      }
    }
    if (!_courseOptions.length && !_loaded) {
      fetchOptions()
    }
  }, [_courseOptions])

  return result
}
