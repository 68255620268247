import styled from 'styled-components'
import { getTheme } from 'helpers'

export default styled.div`
  margin-bottom: 10px;
`

export const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;

  &.required {
    &:after { content: '*'; }
  }
`

export const SelectWrapper = styled.div`
  > .hasError > .ant-select-selection {
    border-color: ${getTheme`colors.red`} !important;
  }
`

export const SearchWrapper = styled.div`
  width: 50%; 
  margin-bottom: 20px;
`