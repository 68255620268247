import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { callAPI, getTheme } from 'helpers'
import { padStart } from 'lodash'

// Components
import styled from 'styled-components'
import { Input, Button, Modal, message } from 'antd'
import FormGroup from 'components/FormGroup'

// Hooks
import { useEditable } from 'hooks/useEditable'

const StudentLoginInfo = () => {
  const match = useRouteMatch()
  const studentId = match.params.studentId
  const editable = useEditable({ studentId })

  const [password, setPassword] = useState('•••••')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [modalVisible, setModalVisible] = useState(false)

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const _onPasswordChange = async () => {
    let _errors = {
      newPassword: !newPassword && 'กรุณาระบุรหัสผ่านใหม่',
      confirmPassword: !confirmPassword && 'กรุณายืนยันรหัสผ่าน'
    }
    if (!_errors.newPassword && !_errors.confirmPassword && newPassword !== confirmPassword) {
      _errors.newPassword = 'รหัสผ่านทั้งสองไม่ตรงกัน'
      _errors.confirmPassword = 'รหัสผ่านทั้งสองไม่ตรงกัน'
    }
    setErrors(_errors)
    if (!Object.keys(_errors).some(key => _errors[key])) {
      try {
        setSubmitting(true)
        await callAPI({
          method: 'POST',
          url: `/students/${studentId}/change-password`,
          body: { password: newPassword }
        })
        setSubmitting(false)
        setPassword(newPassword)
        setModalVisible(false)
        message.success('เปลี่ยนรหัสผ่านสำเร็จ!')
      } catch (error) {
        console.error('Error while trying to change student`s password.', error)
        setSubmitting(false)
        message.error('เปลี่ยนรหัสผ่านไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
      }
    }
  }

  const _onPasswordReset = async () => {
    if (!editable) return
    try {
      setSubmitting(true)
      const { result } = await callAPI({
        method: 'POST',
        url: `/students/${studentId}/random-password`
      })
      setSubmitting(false)
      setPassword(result)
      message.success('รีเซ็ตผ่านสำเร็จ!')
    } catch (error) {
      console.error('Error while trying to reset student`s password.', error)
      setSubmitting(false)
      message.error('รีเซ็ตรหัสผ่านไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  return (
    <ContentWrapper>
      <div>
        <Label>Username</Label>
        <Value>{padStart(studentId, 6)}</Value>
      </div>
      <div>
        <Label>รหัสผ่าน</Label>
        <Value>{password}</Value>
      </div>
      {editable && (
        <div style={{ marginTop: 10 }}>
          <Button type='primary' onClick={_onPasswordReset}>รีเซ็ตรหัสผ่าน</Button>
          {/* <Button type='primary' onClick={() => setModalVisible(true)}>เปลี่ยนรหัสผ่าน</Button> */}
        </div>
      )}
      <Modal
        visible={modalVisible}
        onOk={_onPasswordChange}
        okButtonProps={{
          loading: submitting,
          disabled: submitting
        }}
        okText='เปลี่ยนรหัสผ่าน'
        onCancel={() => setModalVisible(false)}
        cancelText='ย้อนกลับ'>
        <FormGroup>
          <Label>รหัสผ่านใหม่</Label>
          <Input
            type='password'
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)} />
          {errors.newPassword && <ErrorText>{errors.newPassword}</ErrorText>}
        </FormGroup>
        <FormGroup>
          <Label>ยืนยันรหัสผ่านใหม่</Label>
          <Input
            type='password'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)} />
          {errors.confirmPassword && <ErrorText>{errors.confirmPassword}</ErrorText>}
        </FormGroup>
      </Modal>
    </ContentWrapper>
  )
}

export default StudentLoginInfo

const ContentWrapper = styled.section`
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  border: 1px solid ${getTheme`colors.gray`};
  background-color: white;
`

const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  width: 160px;
`
const Value = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  width: 160px;
`
const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
  &:before {
    content: '*';
  }
`
