import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useRouteMatch, Route } from 'react-router-dom'
import { get, find, padStart } from 'lodash'
import { callAPI } from 'helpers'
import { useSelector } from 'react-redux'

// Containers
import StudentEnrollments from './StudentEnrollments'
import StudentLoginInfo from './StudentLoginInfo'
import StudentTrials from './StudentTrials'

// Components
import styled from 'styled-components'
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import { Radio } from 'antd'

export default () => {
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const studentId = match.params.studentId
  const isOwner = useSelector(state => _.get(state, 'app.me.roleName')) === 'Owner'
  const permissions = useSelector(state => (_.get(state, 'app.me.permissions') || []).filter(({ pathname }) => pathname.startsWith('/students')))

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [nickName, setNickName] = useState('')

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({ url: `/by-admin/students/${studentId}` })
        setFirstName(result.firstName)
        setLastName(result.lastName)
        setNickName(result.nickName)
        setLoading(false)
      } catch (error) {
        console.error('Error while trying to fetch student info.', error)
        setLoading(false)
      }
    }
    fetchStudentInfo()
  }, [])

  const fullName = `${firstName} ${lastName}${nickName ? ` (${nickName})` : ''}`
  const activeKey = location.pathname.split('/').pop()
  useEffect(() => {
    if (['enrollments', 'login-info', 'trials'].indexOf(activeKey) === -1) {
      history.replace(`/students/${studentId}/enrollments`)
    }
  }, [activeKey])

  return (
    <GridContainer>
      <Heading title={fullName} description={`รหัสนักเรียน: ${padStart(studentId, 6, '0')}`} />
      <Radio.Group
        value={activeKey}
        onChange={e => history.push(`/students/${studentId}/${e.target.value}`)}>
        {(isOwner || permissions.some(({ pathname }) => pathname === '/students/:studentId/enrollments')) && (
          <Radio.Button value='enrollments'>การสมัครเรียน</Radio.Button>
        )}
        {(isOwner || permissions.some(({ pathname }) => pathname === '/students/:studentId/login-info')) && (
          <Radio.Button value='login-info'>ข้อมูล Login</Radio.Button>
        )}
        {(isOwner || permissions.some(({ pathname }) => pathname === '/students/:studentId/trials')) && (
          <Radio.Button value='trials'>ทดลองเรียน</Radio.Button>
        )}
      </Radio.Group>
      <ContentBody>
        <Route exact path='/students/:studentId/enrollments' component={StudentEnrollments} />
        <Route exact path='/students/:studentId/login-info' component={StudentLoginInfo} />
        <Route exact path='/students/:studentId/trials' component={StudentTrials} />
      </ContentBody>
    </GridContainer>
  )
}

const ContentBody = styled.div`
  margin-top: 20px;
`
