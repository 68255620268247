import React, { useState, useEffect } from 'react'
import { callAPI, getTheme, formatComma } from 'helpers'
import styled from 'styled-components'
import qs from 'qs'
import moment from 'moment'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import FormGroup, { Label, SelectWrapper } from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'
import { Empty, Button, Table, Modal, Input, message, DatePicker } from 'antd'

// Hooks
import { useCourseOptions } from 'hooks/useCourseOptions'
import { useLocation } from 'react-router-dom'
import { useEditable } from 'hooks/useEditable'

const Discounts = () => {
  const editable = useEditable()
  const location = useLocation()
  const { courseCode: _cCode } = qs.parse(location.search.slice(1))

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [discounts, setDiscounts] = useState([])
  const { options: courseOptions, loading: optionsLoading } = useCourseOptions()

  const columns = () => _.compact([
    { title: 'รหัสส่วนลด', key: 'discountCode', align: 'center', render: ({ discountCode }) => discountCode },
    { title: 'มูลค่าส่วนลด (บาท)', key: 'discountValue', align: 'center', render: ({ discountValue }) => formatComma(discountValue) },
    { title: 'จำนวนส่วนลด', key: 'quota', align: 'center', render: ({ quota }) => quota || 'ไม่กำหนด' },
    { title: 'ใช้ได้ถึงวันที่', key: 'expirationDate', align: 'center', render: ({ expirationDate }) => expirationDate ? moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'ไม่กำหนด' },
    { title: 'คอร์สเรียนที่ใช้ได้', key: 'courseCode', align: 'center', render: ({ courseCode }) => courseCode || 'ใช้ได้ทุกคอร์ส' },
    editable ? { title: '', key: 'buttons', render: ({ discountId, discountCode, discountValue, quota, expirationDate, courseCode }) =>
      <div>
        <Button onClick={() => {
          setModalVisible(true)
          setDiscountId(discountId)
          setDiscountCode(discountCode)
          setDiscountValue(discountValue)
          setQuota(quota)
          setExpirationDate(expirationDate ? moment(expirationDate, 'YYYY-MM-DD') : null)
          setCourseCode(courseCode)
        }}>แก้ไข</Button>
        <CustomPopconfirm
          onClick={e => e.stopPropagation()}
          title={`ยืนยันที่จะลบข้อมูลส่วนลด ${discountCode}`}
          onConfirm={() => _onDiscountRemove(discountId)}>
          <Button type='danger' disabled={removing} loading={removing} style={{ marginLeft: 10 }}>ลบ</Button>
        </CustomPopconfirm>
      </div>
    } : null
  ])

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/discounts'
        })
        setDiscounts(result)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch discounts: ${error.reason}`))
        setLoading(false)
        setDiscounts([])
      }
    }

    _initialFetch()
  }, [])

  const [adding, setAdding] = useState(false)
  const [discountId, setDiscountId] = useState()
  const [discountCode, setDiscountCode] = useState('')
  const [discountValue, setDiscountValue] = useState('')
  const [quota, setQuota] = useState('')
  const [expirationDate, setExpirationDate] = useState()
  const [courseCode, setCourseCode] = useState()
  const _onDiscountAdd = async () => {
    if (!editable || adding) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/discounts',
        body: {
          discountCode,
          discountValue,
          quota,
          expirationDate: expirationDate
            ? expirationDate.format('YYYY-MM-DD')
            : null,
          courseCode
        }
      })
      setDiscounts(_discounts => [result].concat(_discounts))
      setAdding(false)
      setModalVisible(false)
      message.success('เพิ่มข้อมูลส่วนลดสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add a discount: ${error.reason}`))
      setAdding(false)
      message.error(error.reason || 'เพิ่มข้อมูลส่วนลดไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onDiscountSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/discounts/${discountId}`,
        body: {
          discountCode,
          discountValue,
          quota,
          expirationDate: expirationDate
            ? expirationDate.format('YYYY-MM-DD')
            : null,
          courseCode
        }
      })
      setDiscounts(_discounts => _discounts.map(_doc => _doc.discountId === result.discountId ? result : _doc))
      setSaving(false)
      setModalVisible(false)
      message.success('บันทึกข้อมูลส่วนลดสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to save a discount with code ${discountCode}: ${error.reason}`))
      setSaving(false)
      message.error(error.reason || 'บันทึกข้อมูลส่วนลดไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onDiscountRemove = async (discountId) => {
    if (!editable || removing) return
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/discounts/${discountId}`
      })
      setRemoving(false)
      setDiscounts(_discounts => _discounts.filter(_doc => _doc.discountId !== discountId))
    } catch (error) {
      console.error(new Error(`Error while trying to remove a discount with ID ${discountId}: ${error.reason}`))
      setRemoving(false)
      message.error(error.reason || 'ลบข้อมูลส่วนลดไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  useEffect(() => {
    if (!modalVisible) {
        setDiscountId()
        setDiscountCode('')
        setDiscountValue('')
        setQuota('')
        setExpirationDate()
        setCourseCode()
    }
  }, [modalVisible])

  const disabled = !discountCode || !discountValue
  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title={`ข้อมูลส่วนลด (${discounts.length})`}
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มข้อมูลส่วนลด' />
        {loading ? (
          <Body><Spinner /></Body>
        ) : discounts.length ? (
          <Body style={{ padding: 0 }}>
            <Table
              rowKey={({ discountId }) => discountId}
              columns={columns()}
              dataSource={discounts} />
          </Body>
        ) : (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลส่วนลด</span>}>
              <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มข้อมูลส่วนลดแรกของคุณ</Button>
            </Empty>
          </Body>
        )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title={discountId ? 'แก้ไขข้อมูลส่วนลด' : 'เพิ่มข้อมูลส่วนลด'}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={disabled}
            loading={discountId ? saving : adding}
            onClick={discountId ? _onDiscountSave : _onDiscountAdd}>
            {discountId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}
          </Button>
        ]}>
          <FormGroup>
            <Label className='required'>รหัสส่วนลด</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกรหัสส่วนลด'
              value={discountCode}
              onChange={e => setDiscountCode(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label className='required'>มูลค่าส่วนลด (บาท)</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกมูลค่าส่วนลด (บาท)'
              value={discountValue}
              onChange={e => {
                const value = e.target.value
                if (value && isNaN(value)) return
                setDiscountValue(value)
              }} />
          </FormGroup>
          <FormGroup>
            <Label>จำนวนส่วนลด</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกจำนวนส่วนลด'
              value={quota}
              onChange={e => {
                const value = e.target.value
                if (value && isNaN(value)) return
                setQuota(value)
              }} />
          </FormGroup>
          <FormGroup>
            <Label>ใช้ได้ถึงวันที่</Label>
            <div>
              <DatePicker
                style={{ width: '100%' }}
                format='DD/MM/YYYY'
                value={expirationDate}
                placeholder='กรุณากรอกวันที่หมดอายุ'
                onChange={setExpirationDate} />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>คอร์สเรียน</Label>
            <SelectWrapper>
              <FormSelect
                disabled={optionsLoading}
                loading={optionsLoading}
                style={{
                  width: '100%',
                }}
                placeholder='เลือกคอร์สเรียน'
                value={courseCode}
                onChange={e => setCourseCode(e.target.value)}>
                <option value=''>ใช้ได้ทุกคอร์ส</option>
                {courseOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
              </FormSelect>
            </SelectWrapper>
          </FormGroup>
        </Modal>
    </section>
  )
}

export default Discounts

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
