import React from 'react'
import { Modal } from 'antd'

export default (props) =>
  <Modal
    closable={false}
    maskClosable={false}
    cancelButtonProps={{
      icon: 'arrow-left'
    }}
    {...props} />
