import React, { useEffect, useState } from 'react'
import { callAPI, formatComma } from 'helpers'
import { get, groupBy, sumBy, uniq } from 'lodash'
import moment from 'moment'
import theme from 'theme'

// Components
import styled from 'styled-components'
import { Table, Modal, Button } from 'antd'
import { SectionContent, SectionHead, SectionBody } from './BasicComponents'

// JSON
import provinces from 'data/provinces.json'

// Hooks
import { useEditable } from 'hooks/useEditable'

const regionMap = {
  central: 'กลาง',
  north: 'เหนือ',
  south: 'ใต้',
  northeast: 'อีสาน',
  east: 'ตะวันออก',
  west: 'ตะวันตก'
}

const SectionOrdersByRegionAndCourse = () => {
  useEditable()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState([])
  const [data, setData] = useState([])

  const [schoolsLoading, setSchoolsLoading] = useState(false)
  const [schoolNameMap, setSchoolNameMap] = useState({})

  const [modalKey, setModalKey] = useState()
  const [activeTab, setActiveTab] = useState('province')

  const columns = [
    { title: 'รหัสคอร์ส', key: 'courseCode', dataIndex: 'courseCode' },
    { title: 'ภาคกลาง', key: 'central', align: 'center', render: ({ rowKey, central }) => !central ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___central`)}>{formatComma(central)}</Clickable> },
    { title: 'ภาคเหนือ', key: 'north', align: 'center', render: ({ rowKey, north }) => !north ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___north`)}>{formatComma(north)}</Clickable> },
    { title: 'ภาคใต้', key: 'south', align: 'center', render: ({ rowKey, south }) => !south ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___south`)}>{formatComma(south)}</Clickable> },
    { title: 'ภาคอีสาน', key: 'northeast', align: 'center', render: ({ rowKey, northeast }) => !northeast ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___northeast`)}>{formatComma(northeast)}</Clickable> },
    { title: 'ภาคตะวันออก', key: 'east', align: 'center', render: ({ rowKey, east }) => !east ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___east`)}>{formatComma(east)}</Clickable> },
    { title: 'ภาคตะวันตก', key: 'west', align: 'center', render: ({ rowKey, west }) => !west ? <div>0</div> : <Clickable onClick={() => setModalKey(`${rowKey}___west`)}>{formatComma(west)}</Clickable> },
    { title: 'รวมทั้งหมด', key: 'total', align: 'center', render: ({ rowKey, central, north, south, northeast, east, west }) => <Clickable onClick={() => setModalKey(`${rowKey}___all`)}>{formatComma(central + north + south + northeast + east + west)}</Clickable> }
  ]

  const provinceColumns = [
    { title: 'จังหวัด', key: 'province', align: 'center', dataIndex: 'province' },
    { title: 'จำนวนการสมัคร', key: 'count', align: 'center', render: ({ count }) => formatComma(count) }
  ]

  const schoolColumns = [
    { title: 'โรงเรียน', key: 'school', align: 'center', dataIndex: 'school' },
    { title: 'จำนวนการสมัคร', key: 'count', align: 'center', render: ({ count }) => formatComma(count) }
  ]

  useEffect(() => {
    const fetchAggregation = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/analytics/orders-by-postal-and-course'
        })

        const resultByCourseCode = groupBy(result, 'courseCode')
        const data = Object.keys(resultByCourseCode).map(courseCode => {
          const counts = resultByCourseCode[courseCode]
          return provinces.reduce((map, { region, province }) => {
            const _sumOfEachRegionAndEachMonth = counts
              .filter(({ provinceName }) => provinceName === province)
              .reduce((data, { count, ym }) => ({
                ...data,
                [ym]: (data[ym] || 0) + count
              }), get(map, `${region}.months`) || {})

            return {
              ...map,
              [region]: { months: _sumOfEachRegionAndEachMonth }
            }
          }, { courseCode })
        })
          .map(record => {
            const regions = Object.keys(record).filter(key => key !== 'courseCode')
            const monthKeys = uniq(regions.reduce((keys, region) => keys.concat(Object.keys(record[region].months)), []).sort((a, b) => b.localeCompare(a)))
            return Object.keys(record).reduce((obj, key) => {
              if (key === 'courseCode') return { ...obj, courseCode: record.courseCode }
              const months = record[key].months
              return {
                ...obj,
                rowKey: record.courseCode,
                total: obj.total + sumBy(Object.keys(months), ym => months[ym]),
                [key]: sumBy(Object.keys(months), ym => months[ym]),
                children: monthKeys.map(ym => ({
                  rowKey: `${record.courseCode}___${ym}`,
                  courseCode: moment(ym).format('MMM YYYY'),
                  central: get(record, `central.months.${ym}`) || 0,
                  north: get(record, `north.months.${ym}`) || 0,
                  south: get(record, `south.months.${ym}`) || 0,
                  northeast: get(record, `northeast.months.${ym}`) || 0,
                  east: get(record, `east.months.${ym}`) || 0,
                  west: get(record, `west.months.${ym}`) || 0
                }))
              }
            }, { total: 0, months: {} })
          })
          .sort((a, b) => b.total - a.total)
          .slice()

        setResult(result)
        setData(data)
        setLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch order aggregation by region and course: ${error.message}.`))
        setLoading(false)
      }
    }

    const fetchSchools = async () => {
      try {
        setSchoolsLoading(true)
        const { result } = await callAPI({
          url: '/schools'
        })
        setSchoolNameMap(result.reduce((map, { schoolId, schoolName }) => ({ ...map, [schoolId]: schoolName }), {}))
        setSchoolsLoading(false)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch schools: ${error.message}.`))
        setSchoolsLoading(false)
      }
    }

    fetchAggregation()
    fetchSchools()
  }, [])

  const _getModalTitle = () => {
    const keys = (modalKey || '').split('___')
    if (keys.length === 2) {
      const [courseCode, region] = keys
      return `ข้อมูลการสมัคร${courseCode === 'sum' ? '' : `คอร์ส ${courseCode}`}${regionMap[region] ? `ของภาค${regionMap[region]}` : ''}`
    }
    if (keys.length === 3) {
      const [courseCode, ym, region] = keys
      return `ข้อมูลการสมัคร${courseCode === 'sum' ? '' : `คอร์ส ${courseCode}`} ในเดือน${moment(ym, 'YYYY-MM').format('MMM YYYY')}${regionMap[region] ? `ของภาค${regionMap[region]}` : ''}`
    }

    return ''
  }

  const _getProvinceModalData = () => {
    const keys = (modalKey || '').split('___')
    if (keys.length === 2) {
      const [_courseCode, _region] = keys
      const provinceMap = provinces
        .filter(({ region }) => _region === 'all' || region === _region)
        .reduce((map, { province }) => {
          return {
            ...map,
            [province]: result
              .filter(({ courseCode }) => _courseCode === 'sum' || courseCode === _courseCode)
              .filter(({ provinceName }) => provinceName === province)
              .reduce((sum, { count }) => sum + count, 0)
          }
        }, {})

      return Object.keys(provinceMap)
        .map(key => ({ rowKey: key, province: key, count: provinceMap[key] }))
        .filter(({ count }) => count)
        .sort((a, b) => b.count - a.count || a.province.localeCompare(b.province))
    }
    if (keys.length === 3) {
      const [_courseCode, _ym, _region] = keys
      const provinceMap = provinces
        .filter(({ region }) => _region === 'all' || region === _region)
        .reduce((map, { province }) => {
          return {
            ...map,
            [province]: result
              .filter(({ ym }) => ym === _ym)
              .filter(({ courseCode }) => _courseCode === 'sum' || courseCode === _courseCode)
              .filter(({ provinceName }) => provinceName === province)
              .reduce((sum, { count }) => sum + count, 0)
          }
        }, {})

      return Object.keys(provinceMap)
        .map(key => ({ rowKey: key, province: key, count: provinceMap[key] }))
        .filter(({ count }) => count)
        .sort((a, b) => b.count - a.count || a.province.localeCompare(b.province))
    }

    return []
  }

  const _getSchoolModalData = () => {
    const keys = (modalKey || '').split('___')
    if (keys.length === 2) {
      const [_courseCode, _region] = keys
      const regionProvinces = provinces
        .filter(({ region }) => _region === 'all' || region === _region)
        .reduce((provinces, { province }) => provinces.concat(province), []);
      const _schoolMap = result
        .filter(({ courseCode }) => _courseCode === 'sum' || courseCode === _courseCode)
        .filter(({ provinceName }) => regionProvinces.includes(provinceName))
        .reduce((map, { schoolId, count }) => ({ ...map, [schoolId]: (map[schoolId] || 0) + count }), {})

      return Object.keys(_schoolMap)
        .map(key => ({ rowKey: key, school: schoolNameMap[key] || '---ไม่ระบุโรงเรียน---', count: _schoolMap[key] }))
        .sort((a, b) => b.count - a.count || a.school.localeCompare(b.school))
    }
    if (keys.length === 3) {
      const [_courseCode, _ym, _region] = keys
      const regionProvinces = provinces
        .filter(({ region }) => _region === 'all' || region === _region)
        .reduce((provinces, { province }) => provinces.concat(province), []);
      const _schoolMap = result
        .filter(({ ym }) => ym === _ym)
        .filter(({ courseCode }) => _courseCode === 'sum' || courseCode === _courseCode)
        .filter(({ provinceName }) => regionProvinces.includes(provinceName))
        .reduce((map, { schoolId, count }) => ({ ...map, [schoolId]: (map[schoolId] || 0) + count }), {})

      return Object.keys(_schoolMap)
        .map(key => ({ rowKey: key, school: schoolNameMap[key] || '---ไม่ระบุโรงเรียน---', count: _schoolMap[key] }))
        .sort((a, b) => b.count - a.count || a.school.localeCompare(b.school))
    }

    return []
  }

  const sumRow = data.reduce((row, { central, north, south, northeast, east, west }) => ({
    ...row,
    central: row.central + central,
    north: row.north + north,
    south: row.south + south,
    northeast: row.northeast + northeast,
    east: row.east + east,
    west: row.west + west
  }), { rowKey: 'sum', courseCode: 'รวมทั้งหมด', central: 0, north: 0, south: 0, northeast: 0, east: 0, west: 0 })

  return (
    <ContentWrapper>
      <SectionContent>
        <SectionHead>ตารางเปรียบเทียบการสมัครคอร์สเรียนแต่ละภูมิภาค</SectionHead>
        <SectionBody>
          <TableWrapper>
            <Table
              rowKey={({ rowKey }) => rowKey}
              loading={loading || schoolsLoading}
              columns={columns}
              dataSource={data.concat([sumRow])}
              pagination={false} />
          </TableWrapper>
        </SectionBody>
        <Modal
          title={_getModalTitle()}
          visible={Boolean(modalKey)}
          onCancel={() => {
            setModalKey()
            setActiveTab('province')
          }}
          footer={null}>
          <div style={{ marginBottom: 20 }}>
            <Button shape='round' type={activeTab === 'province' ? 'primary' : 'default'} onClick={() => setActiveTab('province')}>เรียงตามจังหวัด</Button>
            <Button style={{ marginLeft: 10 }} shape='round' type={activeTab === 'school' ? 'primary' : 'default'} onClick={() => setActiveTab('school')}>เรียงตามโรงเรียน</Button>
          </div>
          <Table
            rowKey={({ rowKey }) => rowKey}
            columns={activeTab === 'province' ? provinceColumns : schoolColumns}
            dataSource={activeTab === 'province' ? _getProvinceModalData() : _getSchoolModalData()}
            pagination={false} />
        </Modal>
      </SectionContent>

    </ContentWrapper>
  )
}

export default SectionOrdersByRegionAndCourse

const ContentWrapper = styled.section`
  background-color: white;
`
const TableWrapper = styled.div`
  .expanded-table {
    margin: 20px 0px !important;
    table {
      th {
        background-color: ${theme.colors.primary};
        color: white;
      }
    }
  }
`
const Clickable = styled.div`
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`
