import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { useLocation, Link, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

// Components
import GridContainer from 'components/GridContainer'

// Containers
import Dashboard from 'containers/analytics/Dashboard'
import StudentNumbers from 'containers/analytics/StudentNumbers'
import Enrollments from 'containers/analytics/Enrollments'

const Analytics = () => {
  const location = useLocation()
  const isOwner = useSelector(state => _.get(state, 'app.me.roleName')) === 'Owner'
  const permissions = useSelector(state => (_.get(state, 'app.me.permissions') || []).filter(({ pathname }) => pathname.startsWith('/analytics')))

  return (
    <section>
      <SubNavigationBar>
        <GridContainer>
          {(isOwner || permissions.some(({ pathname }) => pathname === '/analytics/dashboard')) && (
            <Link to='/analytics/dashboard'>
              <NavLink isActive={location.pathname.includes('/analytics/dashboard')}>Dashboard</NavLink>
            </Link>
          )}
          {(isOwner || permissions.some(({ pathname }) => pathname === '/analytics/student-numbers')) && (
            <Link to='/analytics/student-numbers'>
              <NavLink isActive={location.pathname.includes('/analytics/student-numbers')}>เบอร์โทรนักเรียน</NavLink>
            </Link>
          )}
          {(isOwner || permissions.some(({ pathname }) => pathname === '/analytics/enrollments')) && (
            <Link to='/analytics/enrollments'>
              <NavLink isActive={location.pathname.includes('/analytics/enrollments')}>การสมัครคอร์สเรียน</NavLink>
            </Link>
          )}
        </GridContainer>
      </SubNavigationBar>
      <GridContainer>
        <Switch>
          <Route exact path='/analytics/dashboard' component={Dashboard} />
          <Route exact path='/analytics/student-numbers' component={StudentNumbers} />
          <Route exact path='/analytics/enrollments' component={Enrollments} />
        </Switch>
      </GridContainer>
    </section>
  )
}

export default Analytics

const SubNavigationBar = styled.div`
  background-color: ${theme.colors.secondary};
  color: white;
  height: 40px;
  line-height: 40px;
`
const NavLink = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 40px;
  padding: 0px 20px;
  color: white;
  transition: opacity 0.3s ease-in-out;
  &:hover { opacity: 0.8; }

  ${props => props.isActive && `
    text-decoration: underline;
  `}
`
