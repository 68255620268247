import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { callAPI, formatComma, formatQuota } from 'helpers'
import moment from 'moment'
import { get, find, compact } from 'lodash'
import { useSelector } from 'react-redux'

// Components
import styled from 'styled-components'
import Spinner from 'components/Spinner'
import { Button, Table, Modal, DatePicker, Input, message } from 'antd'
import CustomPopconfirm from 'components/CustomPopconfirm'
import FormGroup from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import Label from 'components/Label'

// Hooks
import useOnlineClassOptions from 'hooks/useOnlineClassOptions'
import { useEditable } from 'hooks/useEditable'

export default () => {
  const match = useRouteMatch()
  const studentId = match.params.studentId
  const editable = useEditable({ studentId })
  const { options: classOptions } = useOnlineClassOptions()

  const [loading, setLoading] = useState(false)
  const [enrollments, setEnrollments] = useState([])
  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/enrollments',
          query: { studentId }
        })
        setLoading(false)
        setEnrollments(result)
      } catch (error) {
        console.error('Error while trying to fetch enrollments.', error)
        setLoading(false)
      }
    }
    fetchEnrollments()
  }, [])

  const [modalVisible, setModalVisible] = useState(false)
  const [editedEnrollmentId, setEditedEnrollmentId] = useState()
  const [expirationMoment, setExpirationMoment] = useState()
  const [additionalQuota, setAdditionalQuota] = useState(0)
  const [classId, setClassId] = useState()

  useEffect(() => {
    if (modalVisible) {
      const { expirationDate, classId, additionalQuota } = find(enrollments, ({ enrollmentId }) => enrollmentId === editedEnrollmentId) || {}
      setExpirationMoment(expirationDate
        ? moment(expirationDate, 'YYYY-MM-DD')
        : moment().add(1, 'year'))
      setClassId(classId)
      setAdditionalQuota(additionalQuota)
    } else {
      setEditedEnrollmentId()
      setExpirationMoment()
      setClassId()
    }
  }, [modalVisible])

  const [adding, setAdding] = useState(false)
  const _onEnrollmentAdd = async () => {
    if (!editable) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/enrollments',
        body: {
          studentId,
          expirationDate: expirationMoment.format('YYYY-MM-DD'),
          classId
        }
      })
      setEnrollments(_enrollments => [result].concat(_enrollments))
      setModalVisible(false)
      setAdding(false)
      message.success('เพิ่มข้อมูลใบสมัครสำเร็จ')
    } catch (error) {
      console.error('Error while trying to add a new enrollment.', error)
      setAdding(false)
      message.error('เพิ่มข้อมูลใบสมัครไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onEnrollmentSave = async () => {
    if (!editable) return
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/enrollments/${editedEnrollmentId}`,
        body: {
          classId,
          expirationDate: expirationMoment.format('YYYY-MM-DD'),
          additionalQuota
        }
      })
      setEnrollments(_enrollments => _enrollments.map(_enrollment =>
        _enrollment.enrollmentId === editedEnrollmentId
          ? { ...result, expirationDate: result.expirationDate }
          : _enrollment
      ))
      setModalVisible(false)
      setEditedEnrollmentId()
      setSaving(false)
      message.success('บันทึกข้อมูลใบสมัครสำเร็จ!')
    } catch (error) {
      console.error('Error while trying to save an enrollment.', error)
      setSaving(false)
      message.error('บันทึกข้อมูลใบสมัครไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState({})
  const _onEnrollmentRemove = async (_enrollmentId, isOffline) => {
    if (!editable) return
    try {
      setRemoving({ ...removing, [_enrollmentId]: true })
      await callAPI({
        method: 'DELETE',
        url: isOffline
          ? `/offline-enrollments/${_enrollmentId}`
          : `/enrollments/${_enrollmentId}`
      })
      setEnrollments(_enrollments => _enrollments.filter(({ enrollmentId }) => enrollmentId !== _enrollmentId))
      setRemoving({ ...removing, [_enrollmentId]: false })
      message.success('ลบข้อมูลใบสมัครสำเร็จ!')
    } catch (error) {
      console.error('Error whie trying to remove an enrollment.', error)
      setRemoving({ ...removing, [_enrollmentId]: false })
      message.error('ลบข้อมูลใบสมัครไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [printing, setPrinting] = useState({})
  const _onPdfPrint = async (_enrollmentId) => {
    if (Object.keys(printing).some(key => printing[key])) return
    try {
      setPrinting({ ...printing, [_enrollmentId]: true })
      const ua = window.navigator.userAgent
      const { result } = await callAPI({
        method: 'POST',
        url: `/enrollments/${_enrollmentId}/card-pdf`
      })
      if (ua.includes('Safari') && !ua.includes('Chrome')) {
        window.location.href = result.url;
      } else {
        window.open(result.url, '_blank');
      }
      setPrinting({ ...printing, [_enrollmentId]: false })
    } catch (error) {
      console.error(new Error(`Error while trying to print pdf.`, error))
      setPrinting({ ...printing, [_enrollmentId]: false })
    }
  }

  const columns = compact([
    { key: 'enrollmentId', title: 'เลขที่ใบสมัคร', align: 'center', render: ({ enrollmentId }) => enrollmentId },
    { key: 'expirationDate', title: 'วันที่หมดอายุ', align: 'center', render: ({ expirationDate }) => expirationDate ? moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'},
    { key: 'semesterCode', title: 'ภาคเรียน', align: 'center', render: ({ semesterCode }) => semesterCode },
    { key: 'classCode', title: 'รหัสวิชา', align: 'center', render: ({ classCode }) => classCode },
    { key: 'className', title: 'ชื่อรอบ', render: ({ className }) => className },
    editable ? { key: 'classFee', title: 'ค่าเรียน', render: ({ classFee }) => formatComma(classFee) } : null,
    { key: 'totalDurations', title: 'จำนวนชั่วโมงที่ใช้ไป', align: 'center', render: ({ classCode, quotaUsed }) => classCode.startsWith('OP1') ? formatQuota(quotaUsed) : '-' },
    { key: 'totalQuota', title: 'จำนวนชั่วโมงทั้งหมด', align: 'center', render: ({ quotaLimit }) => quotaLimit ? `${quotaLimit} ชั่วโมง` : '-'},
    { key: 'additionalQuota', title: 'จำนวนชั่วโมงเพิ่มเติม', align: 'center', render: ({ additionalQuota }) => `${additionalQuota || 0} ชั่วโมง`},
    editable ? {
      key: 'buttons',
      title: '',
      width: 165,
      render: ({ enrollmentId, seatCode, isOffline }) => {
        const _removing = removing[enrollmentId]
        return (
          <div>
            <Button
              disabled={_removing}
              style={{ marginRight: 10 }}
              icon='edit'
              onClick={() => {
                setModalVisible(true)
                setEditedEnrollmentId(enrollmentId)
              }} />
            <Button
              icon='printer'
              loading={printing[enrollmentId]}
              disabled={!seatCode}
              style={{ marginRight: 10 }}
              onClick={seatCode ? () => _onPdfPrint(enrollmentId) : () => {}} />
            <CustomPopconfirm
              disabled={_removing}
              title={`ยืนยันที่จะลบใบสมัครเลขที่ ${enrollmentId} ?`}
              onConfirm={() => _onEnrollmentRemove(enrollmentId, isOffline)}>
              <Button loading={_removing} disabled={_removing} type='danger' icon='delete' />
            </CustomPopconfirm>
          </div>
        )
      }
    } : {
      key: 'buttons',
      title: '',
      width: 165,
      render: ({ enrollmentId, seatCode }) => {
        return (
          <div>
            <Button
              icon='printer'
              loading={printing[enrollmentId]}
              disabled={!seatCode}
              style={{ marginRight: 10 }}
              onClick={seatCode ? () => _onPdfPrint(enrollmentId) : () => {}} />
          </div>
        )
      }
    }
  ])

  if (loading) return <section><Spinner /></section>
  return (
    <section>
      {editable && (
        <ButtonRow>
          <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มใบสมัคร</Button>
        </ButtonRow>
      )}
      <Table
        style={{ backgroundColor: 'white' }}
        rowKey={({ enrollmentId }) => enrollmentId}
        columns={columns}
        dataSource={enrollments}
        pagination={false} />
      <Modal
        visible={modalVisible}
        title={editedEnrollmentId ? `แก้ไขใบสมัครเลขที่ ${editedEnrollmentId}` : 'เพิ่มใบสมัคร'}
        okText='บันทึกข้อมูล'
        okButtonProps={{
          loading: editedEnrollmentId ? saving : adding,
          disabled: editedEnrollmentId ? saving : adding
        }}
        onOk={editedEnrollmentId ? _onEnrollmentSave : _onEnrollmentAdd}
        cancelText='ย้อนกลับ'
        onCancel={() => setModalVisible(false)}>
        <FormGroup>
          <Label>รหัสวิชา</Label>
          <div>
            <FormSelect
              style={{ width: '100%' }}
              value={classId}
              onChange={e => setClassId(e.target.value)}>
              <option value=''>กรุณาเลือกรหัสวิชา</option>
              {classOptions.map(({ label, value, classCode }) => <option key={value} value={value}>{classCode}: {label}</option>)}
            </FormSelect>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>วันที่หมดอายุ</Label>
          <div>
            <DatePicker
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
              value={expirationMoment}
              onChange={_moment => setExpirationMoment(_moment)} />
          </div>
        </FormGroup>
        <FormGroup>
          <Label>จำนวนชั่วโมงเพิ่มเติม</Label>
          <div>
            <Input type='text'
              value={additionalQuota}
              onChange={e => {
                const num = e.target.value
                if (num && isNaN(num)) return
                setAdditionalQuota(num ? parseInt(num, 10) : 0)
              }} />
          </div>
        </FormGroup>
      </Modal>
    </section>
  )
}

const ButtonRow = styled.div`
  text-align: right;
  margin-bottom: 15px;
`
