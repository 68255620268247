import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { callAPI, formatComma, exportAsExcel } from 'helpers'

// Components
import theme from 'theme'
import styled from 'styled-components'
import Heading from 'components/Heading'
import { Select, DatePicker, Table, Button } from 'antd'
import { omitBy, isNil } from 'lodash'

// Hooks
import { useCourseOptions } from 'hooks/custom'
import { useEditable } from 'hooks/useEditable'

const classTypeMap = {
  Online: 'ออนไลน์',
  Private: 'ไพรเวท',
  Live: 'รอบสด'
}
const DATE_FORMAT = 'YYYY-MM-DD'
const columns = [ {
  key: 'dateString',
  title: 'วันที่สมัคร',
  align: 'center',
  render: ({ dateString }) => moment(dateString, DATE_FORMAT).format('DD/MM/YYYY')
}, {
  key: 'orderId',
  title: 'รหัสคำสั่งซื้อ',
  align: 'center',
  render: ({ orderId }) => orderId
}, {
  key: 'classTypeName',
  title: 'ประเภทรอบ',
  align: 'center',
  render: ({ classTypeName }) => classTypeMap[classTypeName]
}, {
  key: 'courseCode',
  title: 'รหัสคอร์ส',
  align: 'center',
  render: ({ courseCode }) => courseCode
}, {
  key: 'classCode',
  title: 'รหัสวิชา',
  align: 'center',
  render: ({ classCode }) => classCode
}, {
  key: 'semesterCode',
  title: 'ภาคการศึกษา',
  align: 'center',
  render: ({ semesterCode }) => semesterCode
}, {
  key: 'fullName',
  title: 'ชื่อ-สกุลนักเรียน',
  align: 'left',
  render: ({ firstName, lastName }) => `${firstName} ${lastName}`
}, {
  key: 'phoneMobile',
  title: 'เบอร์โทรศัพท์',
  align: 'left',
  render: ({ phoneMobile }) => phoneMobile
}]

const limit = 100
const Enrollments = () => {
  useEditable()
  const history = useHistory()
  const location = useLocation()
  const {
    page = 1,
    courseCode = 'all',
    classTypeName = 'all',
    startDate = moment().add(-1, 'month').format(DATE_FORMAT),
    endDate = moment().format(DATE_FORMAT)
  } = qs.parse(location.search.slice(1))
  const { loading: optionsLoading, options: courseOptions } = useCourseOptions()

  const [counting, setCounting] = useState(false)
  const [orderCount, setOrderCount] = useState(0)
  useEffect(() => {
    const fetchOrderCount = async () => {
      try {
        setCounting(true)
        const { result: { count } } = await callAPI({
          url: '/analytics/enrollment-orders/count',
          body: omitBy({
            classTypeName,
            courseCode: courseCode === 'all' ? undefined : courseCode,
            startDate,
            endDate
          }, isNil)
        })
        setCounting(false)
        setOrderCount(count)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch order count: ${error.message}.`))
        setCounting(false)
      }
    }

    fetchOrderCount()
  }, [courseCode, classTypeName, startDate, endDate])

  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true)
        setOrders([])
        const { result } = await callAPI({
          url: '/analytics/enrollment-orders',
          body: omitBy({
            courseCode: courseCode === 'all' ? undefined : courseCode,
            classTypeName,
            startDate,
            endDate,
            offset: (page - 1) * limit,
            limit
          }, isNil)
        })
        setLoading(false)
        setOrders(result)
      } catch (error) {
        console.error(new Error(`Error while trying to fetch orders: ${error.message}.`))
        setLoading(false)
      }
    }

    fetchOrders()
  }, [courseCode, classTypeName, startDate, endDate, page])

  const [exporting, setExporting] = useState(false)
  const _onExport = async () => {
    try {
      setExporting(true)
      const { result } = await callAPI({
        url: '/analytics/enrollment-orders',
        body: omitBy({
          courseCode: courseCode === 'all' ? undefined : courseCode,
          classTypeName,
          startDate,
          endDate
        }, isNil)
      })
      setExporting(false)
      exportAsExcel({
        columns,
        data: result,
        filename: `enrollments_${new Date().getTime().toString(16)}.xlsx`,
        sheetName: 'Sheet1'
      })
    } catch (error) {
      console.error(new Error(`Error while trying to export: ${error.message}.`))
      setExporting(false)
    }
  }

  return (
    <div>
      <Heading title={`ข้อมูลสถิติการสมัครคอร์สเรียน${counting ? '' : ` (จำนวนคนสมัครทั้งหมด ${formatComma(orderCount)} คน)`}`} />
      <FilterWrapper>
        <FilterHead>ตัวกรองข้อมูล</FilterHead>
        <FilterBody>
          <FilterCol>
            <Label>กรองข้อมูลด้วยคอร์สเรียน</Label>
            <Select
              style={{ width: '100%' }}
              disabled={optionsLoading}
              loading={optionsLoading}
              value={courseCode}
              onChange={value => history.push(`/analytics/enrollments?courseCode=${value}&classTypeName=${classTypeName}&startDate=${startDate}&endDate=${endDate}&page=1`)}>
              <Select.Option key='all'>แสดงทุกคอร์สเรียน</Select.Option>
              {courseOptions.map(({ label, value }) => <Select.Option key={value} value={value}>{value}: {label}</Select.Option>)}
            </Select>
          </FilterCol>
          <FilterCol>
            <Label>กรองข้อมูลด้วยประเภทรอบ</Label>
            <Select
              style={{ width: '100%' }}
              value={classTypeName}
              onChange={value => history.push(`/analytics/enrollments?courseCode=${courseCode}&classTypeName=${value}&startDate=${startDate}&endDate=${endDate}&page=1`)}>
              <Select.Option key='all'>แสดงทุกประเภท</Select.Option>
              <Select.Option key='live'>รอบสด</Select.Option>
              <Select.Option key='private'>ไพรเวท</Select.Option>
              <Select.Option key='online'>ออนไลน์</Select.Option>
            </Select>
          </FilterCol>
          <FilterCol>
            <Label>กรองข้อมูลด้วยช่วงเวลา</Label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              value={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
              onChange={([_start, _end]) => {
                const startDate = _start.format(DATE_FORMAT)
                const endDate = _end.format(DATE_FORMAT)
                history.push(`/analytics/enrollments?courseCode=${courseCode}&classTypeName=${classTypeName}&startDate=${startDate}&endDate=${endDate}&page=1`)
              }} />
          </FilterCol>
        </FilterBody>
      </FilterWrapper>
      <ButtonRow>
        <Button
          loading={exporting}
          icon='export'
          onClick={_onExport}>Export</Button>
      </ButtonRow>
      <Body>
        <Table
          loading={loading}
          rowKey={({ orderId }) => orderId}
          columns={columns}
          dataSource={orders}
          pagination={{
            current: parseInt(page, 10),
            pageSize: limit,
            total: orderCount,
            onChange: (page) => {
              history.push(`/analytics/enrollments?courseCode=${courseCode}&classTypeName=${classTypeName}&startDate=${startDate}&endDate=${endDate}&page=${page}`)
              window.scrollTo(0, 0)
            }
          }} />
      </Body>
    </div>
  )
}

export default Enrollments

const FilterWrapper = styled.div`
  position: relative;
  background-color: white;
  overflow: hidden;
`
const FilterHead = styled.div`
  line-height: 30px;
  padding-left: 15px;
  background-color: ${theme.colors.primary};
  color: white;
`
const FilterBody = styled.div`
  padding: 15px;
`
const FilterCol = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin-right: 2%;
  &:last-of-type { margin-right: 0; }
`
const Label = styled.div`
  color: black;
  font-weight: 700;
  margin-bottom: 5px;
`
const ButtonRow = styled.div`
  margin-top: 15px;
  text-align: right;
`
const Body = styled.div`
  margin-top: 15px;
  table {
    background-color: white;
  }
`
