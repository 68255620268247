import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { callAPI } from 'helpers'

export const useExamOptions = () => {
  const dispatch = useDispatch()
  const _examOptions = useSelector(state => get(state, 'app.examOptions')) || []
  const _loaded = useSelector(state => get(state, 'app.examOptionsLoaded')) || false

  const [result, setResult] = useState({ options: _examOptions, loading: false })

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setResult({ options: [], loading: true })
        // Fetch exam options in order to create select.
        const { result: examOptions } = await callAPI({
          method: 'GET',
          url: '/exam-options'
        })

        dispatch({ type: 'EXAM_OPTIONS_UPDATED', options: examOptions })
        setResult({ options: examOptions, loading: false })
      } catch (error) {
        console.error('Error while trying to fetch options.', error.message)
        setResult({ options: [], loading: false })
      }
    }
    if (!_examOptions.length && !_loaded) {
      fetchOptions()
    }
  }, [_examOptions])

  return result
}
