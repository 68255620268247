import React, { useState, useEffect, useRef } from 'react'
import { getTheme, callAPI } from 'helpers'
import styled from 'styled-components'
import _ from 'lodash'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import FormGroup, { Label, SelectWrapper, SearchWrapper } from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'
import { Empty, Button, Table, Modal, Input, message } from 'antd'

// Hooks
import { useEditable } from 'hooks/useEditable'

const SEARCH_PLACEHOLDER = 'ค้นหาด้วยรหัสวิชา, ชื่อวิชา, รายละเอียดวิชา'
const Courses = () => {
  const editable = useEditable()
  const location = useLocation()
  const history = useHistory()
  const inputRef = useRef()

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [courses, setCourses] = useState([])


  const { keyword } = qs.parse(location.search.slice(1))
  const [searchKey, setSearchKey] = useState(keyword)

  const columns = () => _.compact([
    { title: 'รหัสวิชา', key: 'courseCode', align: 'center', render: ({ courseCode }) => courseCode },
    { title: 'ชื่อวิชา', key: 'courseName', render: ({ courseName }) => courseName },
    { title: 'รายละเอียดวิชา', key: 'courseDescription', render: ({ courseDescription }) => courseDescription },
    { title: 'สถานะ', key: 'status', align: 'center', render: ({ status }) => status === 'A' ? 'ใช้งาน' : status === 'I' ? 'ไม่ใช้งาน' : '-' },
    editable ? { title: '',
      key: 'buttons',
      render: ({ courseId, courseName, courseCode, courseDescription, status }) =>
        <div>
          <Button onClick={() => {
            setCourseId(courseId)
            setCourseName(courseName)
            setCourseCode(courseCode)
            setCourseDescription(courseDescription)
            setStatus(status)
            setModalVisible(true)
          }}>แก้ไข</Button>
          <CustomPopconfirm
            onClick={e => e.stopPropagation()}
            title={`ยืนยันที่จะลบวิชา ${courseCode}`}
            onConfirm={() => _onCourseRemove(courseId)}>
            <Button type='danger' disabled={removing} loading={removing} style={{ marginLeft: 10 }}>
              ลบ
            </Button>
          </CustomPopconfirm>
        </div>
    } : null
  ])

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/courses'
        })
        setCourses(result)
        setLoading(false)
      } catch (error) {
        console.error('Error while trying to fetch courses.', error)
        setLoading(false)
        setCourses([])
      }
    }

    _initialFetch()
  }, [])

  const [adding, setAdding] = useState(false)
  const [courseId, setCourseId] = useState('')
  const [courseCode, setCourseCode] = useState('')
  const [courseName, setCourseName] = useState('')
  const [courseDescription, setCourseDescription] = useState('')
  const [status, setStatus] = useState('')
  const _onCourseAdd = async () => {
    if (!editable || adding) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/courses',
        body: {
          courseCode,
          courseName,
          courseDescription,
          status
        }
      })
      setCourses(_courses => _courses.concat([result]))
      setAdding(false)
      setModalVisible(false)
      message.success('เพิ่มข้อมูลวิชาสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add a course: ${error.reason}.`))
      setAdding(false)
      message.error(error.reason || 'เพิ่มข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onCourseSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result } = await callAPI({
        method: 'PUT',
        url: `/courses/${courseId}`,
        body: {
          courseName,
          courseDescription,
          status
        }
      })
      setCourses(_courses => _courses.map(_course => _course.courseId === courseId ? result : _course))
      setSaving(false)
      setModalVisible(false)
      message.success('บันทึกข้อมูลวิชาสำเร็จ')
    } catch (error) {
      console.error(`Error while trying to save course: ${courseCode}`, error.reason)
      setSaving(false)
      message.error(error.reason || 'บันทึกข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onCourseRemove = async (courseId) => {
    if (!editable || removing) return
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/courses/${courseId}`
      })
      setRemoving(false)
      setCourses(_courses => _courses.filter(_course => _course.courseId !== courseId))
    } catch (error) {
      console.error(`Error while trying to remove a course of ID ${courseId}`, error.reason)
      setRemoving(false)
      message.error(error.reason || 'ลบข้อมูลวิชาไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  useEffect(() => {
    if (!modalVisible) {
      setCourseId()
      setCourseCode('')
      setCourseName('')
      setCourseDescription('')
      setStatus('')
    }
  }, [modalVisible])

  const disabled = !courseCode || !courseName
  const filteredCourses = courses.filter(({ courseCode, courseName, courseDescription }) => {
    return !keyword || [courseCode, courseName, courseDescription].some(value => value && value.includes(keyword))
  })
  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title={`ข้อมูลวิชา (${courses.length})`}
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มข้อมูลวิชา'/>
        <SearchWrapper>
          <Input.Search
            ref={inputRef}
            placeholder={SEARCH_PLACEHOLDER}
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
            onSearch={() => history.push(`/courses?keyword=${searchKey}`)} />
        </SearchWrapper>
      {loading ? (
        <Body><Spinner /></Body>
      ) : filteredCourses.length ? (
        <Body style={{ padding: 0 }}>
          <Table
            rowKey={({ courseId }) => courseId}
            columns={columns()}
            dataSource={filteredCourses}
            pagination={false} />
        </Body>
      ) : courses.length ? (
        <Body>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>ไม่พบข้อมูลวิชาที่ค้นหา</span>}>
            <Button type='primary' icon='search' onClick={() => {
              inputRef.current.focus()
              setKeyword('')
            }}>{SEARCH_PLACEHOLDER}อื่น</Button>
          </Empty>
        </Body>
      ) : (
        <Body>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>ยังไม่มีข้อมูลวิชา</span>}>
            <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มวิชาแรกของคุณ</Button>
          </Empty>
        </Body>
      )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title={courseId ? 'แก้ไขข้อมูลวิชา' : 'เพิ่มข้อมูลวิชา'}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={disabled}
            loading={courseId ? saving : adding}
            onClick={courseId ? _onCourseSave : _onCourseAdd}>
            {courseId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}
          </Button>
        ]}>
        <FormGroup>
          <Label className='required'>รหัสวิชา</Label>
          <Input  
            type='text'
            placeholder='กรุณากรอกรหัสวิชา'
            disabled={courseId}
            value={courseCode}
            onChange={e => setCourseCode(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label className='required'>ชื่อวิชา</Label>
          <Input
            type='text'
            placeholder='กรุณากรอกชื่อวิชา'
            value={courseName}
            onChange={e => setCourseName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>รายละเอียดวิชา</Label>
          <Input
            type='text'
            placeholder='กรุณากรอกรายละเอียดวิชา'
            value={courseDescription}
            onChange={e => setCourseDescription(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label className='required'>สถานะ</Label>
          <SelectWrapper>
            <FormSelect
              style={{ width: '100%' }}
              placeholder='เลือกสถานะ'
              value={status}
              onChange={e => setStatus(e.target.value)}>
              <option value=''></option>
              <option value='A'>ใช้งาน</option>
              <option value='I'>ไม่ใช้งาน</option>
            </FormSelect>
          </SelectWrapper>
        </FormGroup>
      </Modal>
    </section>
  )  
}

export default Courses

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
