import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getTheme } from 'helpers'
import { useExamOptions } from 'hooks/custom'
import { padStart } from 'lodash'

// Components
import styled from 'styled-components'
import FormGroup from 'components/FormGroup'
import FormSelect from 'components/FormSelect'

export default forwardRef(({ classItem, classId, courseCode }, ref) => {
  const history = useHistory()
  const [classExamId, setClassExamId] = useState()
  const [classExamIdError, setClassExamIdError] = useState()
  const { options: examOptions, loading: optionsLoading } = useExamOptions()

  useEffect(() => {
    if (classItem) {
      setClassExamId(classItem.classExamId)
    }
  }, [classItem])

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const _classExamIdError = !classExamId && 'กรุณาเลือกแบบทดสอบ'
      setClassExamIdError(_classExamIdError)
      return [_classExamIdError].some(error => error) ? false : { classExamId }
    }
  }))

  return (
    <div>
      <FormGroup>
        <Label>ชื่อแบบทดสอบ</Label>
        <div>
          <FormSelect
            className={classExamIdError ? 'hasError' : ''}
            disabled={optionsLoading}
            loading={optionsLoading}
            style={{ width: '100%' }}
            placeholder='กรุณาเลือกแบบทดสอบ'
            value={optionsLoading ? null : classExamId}
            onChange={e => {
              const _classExamId = e.target.value
              if (_classExamId === 'create') {
                history.push('/exams/create')
              } else {
                setClassExamId(_classExamId)
              }
            }}>
            <option value='create'>+ เพิ่มแบบทดสอบ</option>
            {examOptions.map(({ label, value }) => <option key={value} value={value}>{padStart(value, 4, '0')}: {label}</option>)}
          </FormSelect>
          {classExamIdError && <ErrorText>{classExamIdError}</ErrorText>}
        </div>
      </FormGroup>
    </div>
  )
})

const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  width: 25%;
`
const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
`
