import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { callAPI, formatComma } from 'helpers'

export default () => {
  const dispatch = useDispatch()
  const _onlineClassOptions = useSelector(state => get(state, 'app.onlineClassOptions')) || []
  const _loaded = useSelector(state => get(state, 'app.onlineClassOptionsLoaded')) || false

  const [result, setResult] = useState({ options: _onlineClassOptions, loaded: false })

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { result } = await callAPI({
          method: 'GET',
          url: '/online-classes'
        })
        const classOptions = result.map(({ semesterCode, classId, classCode, courseName, classFee }) => ({
          label: `${semesterCode} - ${courseName} (${formatComma(classFee)})`,
          value: classId,
          classId,
          classCode,
          semesterCode
        }))

        dispatch({ type: 'ONLINE_CLASS_OPTIONS_UPDATED', options: classOptions })
        setResult({ options: classOptions, loading: false })
      } catch (error) {
        console.error('Error while trying to fetch online class options.', error)
        setResult({ options: [], loading: false })
      }
    }

    if (!_onlineClassOptions.length && !_loaded) {
      fetchOptions()
    }
  }, [_onlineClassOptions.length])

  return result
}
