import React, { useEffect, useState } from 'react'
import { callAPI, formatComma } from 'helpers'
import moment from 'moment'
import { sumBy } from 'lodash'

// Components
import styled from 'styled-components'
import theme from 'theme'
import { ChartSection, SectionContent, SectionHead, SectionBody } from './BasicComponents'
import Spinner from 'components/Spinner'
import { LineChart, Line, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { DatePicker } from 'antd'

// Hooks
import { useEditable } from 'hooks/useEditable'

const SectionOrdersByMonth = () => {
  useEditable()
  const [startMonth, setStartMonth] = useState(moment().add(-11, 'month').format('YYYY-MM'))
  const [endMonth, setEndMonth] = useState(moment().format('YYYY-MM'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/analytics/orders-by-months',
          query: {
            startMonth,
            endMonth
          }
        })
        setData(result.map(({ ym, count }) => ({
          name: moment(ym, 'YYYY-MM').format('MMM YY'),
          count: count
        })))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchOrders()
  }, [startMonth, endMonth])

  return (
    <ChartSection>
      <SectionContent>
        <SectionHead style={{ position: 'relative' }}>
          <div>แผนภาพแสดงการสมัครคอร์สรายเดือน</div>
          <RangePickerWrapper>
            <DatePicker.MonthPicker
              style={{ width: 110, display: 'inline-block', verticalAlign: 'top' }}
              format='MMM YYYY'
              disabledDate={current => current && (current < moment('2020-04', 'YYYY-MM') || current > moment().endOf('day'))}
              onChange={(_date) => setStartMonth(_date.format('YYYY-MM'))}
              value={moment(startMonth, 'YYYY-MM')} />
            <Hyphen>-</Hyphen>
            <DatePicker.MonthPicker
              style={{ width: 110, display: 'inline-block', verticalAlign: 'top' }}
              format='MMM YYYY'
              disabledDate={current => current && (current < moment('2020-04', 'YYYY-MM') || current > moment().endOf('day'))}
              onChange={(_date) => setEndMonth(_date.format('YYYY-MM'))}
              value={moment(endMonth, 'YYYY-MM')} />
          </RangePickerWrapper>
        </SectionHead>
        {loading ? (
          <SectionBody>
            <Spinner />
          </SectionBody>
        ) : (
          <SectionBody style={{ height: 300, padding: 20, paddingTop: 40, border: '1px solid black', position: 'relative' }}>
            <TextCenter>จากการสมัครคอร์สผ่านระบบออนไลน์ทั้งหมด {formatComma(sumBy(data, 'count'))} ครั้ง</TextCenter>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart width={400} height={200} data={data}>
                <XAxis dataKey='name' scale='point' padding={{ left: 10, right: 10 }} />
                <CartesianGrid strokeDasharray='3 3' />
                <Line dataKey='count' fill={theme.colors.secondary} label={{ position: 'top' }} />
              </LineChart>
            </ResponsiveContainer>
          </SectionBody>
        )}
      </SectionContent>
    </ChartSection>
  )
}

export default SectionOrdersByMonth

const TextCenter = styled.div`
  color: #333;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
const RangePickerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`
const Hyphen = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 20px;
  text-align: center;
  font-weight: 400;
`
