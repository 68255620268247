import React, { useState, useRef } from 'react'

// Components
import CustomModal from 'components/CustomModal'
import ClassItemForm from './ClassItemForm'
import { callAPI } from 'helpers'

export default ({ visible, classId, nextSequence, onCreate, onCancel }) => {
  const itemFormRef = useRef()
  const [creating, setCreating] = useState()

  if (!visible) return null

  const _onItemCreate = async () => {
    const formData = itemFormRef.current.getFormData()
    if (formData) {
      const { selectedRadio, vimeoId, videoTitle, videoDescription, classExamId } = formData
      if (selectedRadio === 'video') {
        try {
          setCreating(true)
          const { result: newItem } = await callAPI({
            method: 'POST',
            url: `/online-classes/${classId}/items`,
            body: {
              itemType: 'video',
              vimeoId,
              videoTitle,
              videoDescription,
              nextSequence
            }
          })
          setCreating(false)
          onCreate(newItem)
        } catch (error) {
          console.error('Error while trying to create new class video item.', error)
          setCreating(false)
        }
      } else if (selectedRadio === 'exam') {
        try {
          setCreating(true)
          const { result: newItem } = await callAPI({
            method: 'POST',
            url: `/online-classes/${classId}/items`,
            body: {
              itemType: 'exam',
              classExamId,
              nextSequence
            }
          })
          setCreating(false)
          onCreate(newItem)
        } catch (error) {
          console.error('Error while trying to create new class exam item.', error)
          setCreating(false)
        }
      }
    }
  }

  return (
    <CustomModal visible
      title='เพิ่มบทเรียน / แบบทดสอบ'
      cancelText='ย้อนกลับ'
      okText='เพิ่ม'
      okButtonProps={{
        loading: creating,
        icon: 'plus'
      }}
      onCancel={onCancel}
      onOk={_onItemCreate}>
      <ClassItemForm ref={itemFormRef} />
    </CustomModal>
  )
}
