import styled from 'styled-components'
const FormSelect = styled.select`
  height: 32px;
  color: rgba(0,0,0,0.65);
  background-color: white;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all .3s;
  outline: none;
  text-indent: 8px;
`

export default FormSelect
