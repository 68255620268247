import React from 'react'
import { get, omitBy, isNil, inRange, padStart } from 'lodash'
import Cookies from 'js-cookie'
import URL from 'url'
import config from 'config'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import FileSaver from 'file-saver'

export const getTheme = ([path]) => props => get(props, `theme.${path}`)
export const callAPI = async ({ fullURL, mssql, url, method, query, body }) => {
  const response = await fetch( // eslint-disable-line
    `${fullURL || `${mssql ? config.apiHost : config.cloudAPIHost}${encodeURI(url)}`}${URL.format({ query })}`,
    omitBy({
      method: method || (body ? 'POST' : 'GET'),
      headers: omitBy({
        'Content-Type': 'application/json; charset=utf-8',
        'access_token': Cookies.get('accessToken')
      }, isNil),
      body: body ? JSON.stringify(body) : undefined
    }, isNil)
  )
  const json = await response.json()
  if (response.status === 200) {
    return Promise.resolve(json)
  } else if (response.status === 401) {
    // if (!(['login', 'me']).some(key => url.indexOf(key) > -1)) {
    //   window.location.href = '/login'
    // }
  } else {
    const reason = (json || {}).reason
    if (reason.includes(`${response.status} -`)) {
      return Promise.reject({ statusCode: response.status, reason: get(reason.match(/\"reason\":\"(.*?)\"/), 1) }) // eslint-disable-line
    }
    return Promise.reject({ statusCode: response.status, reason }) // eslint-disable-line
  }
}

export const formatComma = (number = '') =>
  (isNaN(number) ? number.replace(/,/g, '') : `${number}`).replace(/./g, (c, i, a) => i && c !== '.' && ((a.length - i) % 3 === 0) ? ',' + c : c)

export const getVimeoId = (url = '') => {
  const match = url.match(/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/)
  return (match && match.length > 5 && match[5]) ? match[5] : ''
}
export const formatThaiDuration = (duration) => {
  const hours = Math.floor(duration / 3600)
  const hourString = hours ? `${hours} ชั่วโมง ` : ''
  const minutes = Math.floor(duration / 60) % 60
  const minuteString = (hours || minutes) ? `${minutes} นาที ` : ''
  const seconds = duration % 60
  return `${hourString}${minuteString}${seconds} วินาที`
}

export const uploadFile = async (file) => {
  if (!file) return Promise.resolve(null)
  try {
    const { url } = await callAPI({
      method: 'POST',
      url: '/s3/signed-url',
      body: {
        key: `${new Date().getTime().toString(16)}.${file.type.split('/').pop()}`,
        contentType: file.type
      }
    })
    return uploadToS3({ url, file })
  } catch (error) {
    console.error('Error while trying to upload image to S3', error.message)
  }
}

export const uploadToS3 = ({ url, file, contentType, contentEncoding }) => {
  let xhr = new XMLHttpRequest() // eslint-disable-line
  xhr.open('PUT', url, true)
  if (contentType) { xhr.setRequestHeader('Content-Type', contentType) }
  xhr.send(file)
  return new Promise((resolve, reject) => {
    xhr.onload = (e) => {
      console.log(e, xhr)
      if (xhr.status === 200) {
        resolve(url.split('?')[0])
      } else {
        reject(xhr.status)
      }
    }
  })
}

export const viewportWidth = () => {
  if (typeof document === 'undefined' || typeof window === 'undefined') return 0
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}

export const viewportHeight = () => {
  if (typeof document === 'undefined' || typeof window === 'undefined') return 0
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}

export const wordsWithStyles = (keywords, string) => {
  const words = keywords
    .reduce((data, word) => {
      // Create an array of indexes of bold string.
      const _start = string.indexOf(word)
      if (_start === -1) return data

      const _end = _start + word.length
      if (data.some(({ start, end }) => inRange(_start, start, end) || inRange(_end, start, end))) {
        return data.map(({ start, end }) => {
          if (inRange(_start, start, end)) return { start, end: _end, word: string.slice(start, _end) }
          if (inRange(_end, start, end)) return { start: _start, end, word: string.slice(_start, end) }
          return { start, end, word: string.slice(start, end) }
        })
      }
      return data.concat([{ start: _start, end: _end, word: string.slice(_start, _end) }])
    }, [])
    .sort((a, b) => a.start - b.start)
    .slice()
    .reduce((_words, { start, end }, index, array) => {
      // Create an array of words with bold style.
      const lastWord = index === array.length - 1 && end < string.length ? [{ word: string.slice(end, string.length), bold: false }] : []

      if (!index) {
        return (start > 0
          ? [
            { word: string.slice(0, start), bold: false },
            { word: string.slice(start, end), bold: true }
          ]
          : [{ word: string.slice(start, end), bold: true }]
        ).concat(lastWord)
      }

      const { end: prevEnd } = array[index - 1]
      return _words.concat(
        [
          { word: string.slice(prevEnd, start), bold: false },
          { word: string.slice(start, end), bold: true }
        ].concat(lastWord)
      )
    }, [])

  if (!words.length) return [{ word: string, bold: false }]
  return words
}

export const renderBoldString = ({ word, bold }, index) =>
  (bold ? <strong key={index} style={{ color: '#00a1d6' }}>{word}</strong> : <span key={index}>{word}</span>)

export const highlight = (string, keyword) =>
  wordsWithStyles([keyword], string).map(renderBoldString)

export const formatQuota = (quota) => {
  if (!quota || isNaN(quota)) return '00:00:00'
  const quotaValue = parseInt(quota, 10)
  const hh = padStart(Math.floor(quotaValue / 3600), 2, '0')
  const mm = padStart(Math.floor((quotaValue % 3600) / 60), 2, '0')
  const ss = padStart(Math.floor(quotaValue % 60), 2, '0')
  return `${hh}:${mm}:${ss}`
}

export const exportAsExcel = ({ columns, data, filename, sheetName }) => {
  return new Promise((resolve) => {
    const workbook = new Excel.Workbook()
    workbook.created = new Date()
    const sheet = workbook.addWorksheet(sheetName)
    const _columns = columns.reduce((columns, col) => {
      if (!col.children) return columns.concat([col])
      return columns.concat(col.children.map(child => ({ ...child, title: `${col.title} - ${child.title}` })))
    }, [])
    sheet.columns = _columns.map(({ title, key, width }) => ({
      header: title,
      key,
      width: ((width || 0) / 4) || 30
    }))
    const row = sheet.getRow(1)
    row.font = {
      size: 16,
      bold: true
    }
    row.alignment = { vertical: 'middle', horizontal: 'center' }
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF7F7F7' }
    }

    data.forEach((datum, index) => {
      const newRow = sheet.addRow(_columns.map(({ title, render }) => render(datum, datum, index, true)))
      newRow.eachCell(cell => {
        cell.alignment = { vertical: 'middle', horizontal: isNaN(cell) ? 'left' : 'center', wrapText: true }
      })
    })
    workbook.xlsx.writeBuffer()
      .then(buffer => {
        FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename) // eslint-disable-line
        resolve()
      })
  })
}

export const tableWidth = (columns) =>
  (columns || []).reduce((totalWidth, col) => {
    if (col.children) {
      return totalWidth + col.children.reduce((_width, child) => _width + child.width, 0)
    }
    return totalWidth + (col.width || 100)
  }, 0)
