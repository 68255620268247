import React from 'react'
import { Spin, Icon } from 'antd'

export default ({ children, text, style = {}, iconStyle = {} }) =>
  <Spin
    tip={text || 'กำลังโหลด...'}
    indicator={<Icon type='loading' style={{ fontSize: 24, marginBottom: 15, ...iconStyle }} spin />}
    style={{
      display: 'block',
      textAlign: 'center',
      margin: children ? 0 : '30px 0px',
      ...style
    }}>{children}</Spin>
