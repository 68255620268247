import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, Route } from 'react-router-dom'
import qs from 'qs'
import { callAPI } from 'helpers'

// Containers
import StudentList from './StudentList'
import StudentDetail from './StudentDetail'

// Hooks
import { useEditable } from 'hooks/useEditable'

const LIMIT = 100

export default () => {
  useEditable()
  const history = useHistory()
  const location = useLocation()
  const [studentCount, setStudentCount] = useState(0)
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(false)
  const { page = 1, keyword } = qs.parse(location.search.slice(1))

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true)
        const [{ result: _studentCount }, { result: _students }] = await Promise.all([
          callAPI({
            url: '/students/count',
            query: { keyword }
          }),
          callAPI({
            url: '/students',
            query: { offset: (page - 1) * LIMIT, limit: LIMIT, keyword }
          })
        ])
        setLoading(false)
        setStudentCount(_studentCount)
        setStudents(_students)
      } catch (error) {
        console.error('Error while trying to initial fetch.', error)
        setLoading(false)
        setStudents([])
      }
    }

    fetchStudents()
  }, [page, keyword])

  const _onStudentsSearch = async (keyword) => {
    try {
      history.push(`/students?keyword=${keyword}`)
      setLoading(true)
      const { result: _students } = await callAPI({
        url: '/students',
        query: { offset: 0, limit: 100, keyword }
      })
      setStudents(_students)
      setLoading(false)
    } catch (error) {
      console.error('Error while trying to search students using specified keyword.', error)
      setLoading(false)
    }
  }

  const _onPageChange = (page) =>
    history.push(`/students?page=${page}`)

  return (
    <section>
      {location.pathname === '/students' &&
        <StudentList
          pageSize={LIMIT}
          onPageChange={_onPageChange}
          onSearch={_onStudentsSearch}
          loading={loading}
          count={studentCount}
          dataSource={students} />
      }
      <Route path='/students/:studentId' component={StudentDetail} />
    </section>
  )
}
