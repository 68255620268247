import React from 'react'
import styled from 'styled-components'
import { getTheme } from 'helpers'
import { Button } from 'antd'

export default function ({ title, description, disabled, buttonProps, buttonTitle, children }) {
  return (
    <Heading>
      <HeadingTitle>{title}</HeadingTitle>
      {description && <HeadingDescription>{description}</HeadingDescription>}
      {!disabled && buttonProps && (
        <CustomButton icon='plus' {...buttonProps}>
          <span>{buttonTitle}</span>
        </CustomButton>
      )}
      {children}
    </Heading>
  )
}

const Heading = styled.div`
  padding: 20px 0px;
  position: relative;
`
const HeadingTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  padding: 0px;
`
const HeadingDescription = styled.h2`
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  color: ${getTheme`colors.gray`};
`
const CustomButton = styled(Button)`
  @media(min-width: ${getTheme`media.desktop`}) {
    position: absolute !important;
    top: 20px;
    right: 0px;
  }
  @media(max-width: ${getTheme`media.mobile`}) {
    margin-top: 10px;
  }
`
