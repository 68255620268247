import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { get } from 'lodash'

// Components
import styled from 'styled-components'
import { Radio } from 'antd'
import ClassItemFormVideoTab from 'components/classes/ClassItemFormVideoTab'
import ClassItemFormExamTab from 'components/classes/ClassItemFormExamTab'

export default forwardRef(({ hideRadios, classItem }, ref) => {
  const videoFormRef = useRef()
  const examFormRef = useRef()

  const [selectedRadio, setSelectedRadio] = useState('video')

  useEffect(() => {
    if (get(classItem, 'classVideoId')) {
      setSelectedRadio('video')
    } else if (get(classItem, 'classExamId')) {
      setSelectedRadio('exam')
    }
  }, [classItem])

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const formData = selectedRadio === 'video'
        ? videoFormRef.current.getFormData()
        : examFormRef.current.getFormData()

      return Boolean(formData) && {
        selectedRadio,
        ...formData
      }
    }
  }))

  return (
    <div>
      {!hideRadios && (
        <Radio.Group onChange={e => setSelectedRadio(e.target.value)} value={selectedRadio}>
          <Radio value='video'>บทเรียน</Radio>
          <Radio value='exam'>แบบทดสอบ</Radio>
        </Radio.Group>
      )}
      <ModalBody>
        {selectedRadio === 'video' ? (
          <ClassItemFormVideoTab
            ref={videoFormRef}
            classItem={classItem} />
        ) : (
          <ClassItemFormExamTab
            ref={examFormRef}
            classItem={classItem} />
        )}
      </ModalBody>
    </div>
  )
})

const ModalBody = styled.div`
  padding: 10px 0
`
