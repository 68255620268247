import React, { useState, useEffect, useRef } from 'react'
import { getTheme, callAPI, uploadFile } from 'helpers'
import styled from 'styled-components'
import qs from 'qs'
import moment from 'moment'

// Components
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import Spinner from 'components/Spinner'
import FormGroup, { Label, SelectWrapper } from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'
import { Empty, Button, Table, Modal, Input, message } from 'antd'

// Hooks
import { useCourseOptions } from 'hooks/custom'
import { useHistory, useLocation } from 'react-router-dom'
import { useEditable } from 'hooks/useEditable'

const Documents = () => {
  const editable = useEditable()
  const history = useHistory()
  const location = useLocation()
  const { keyword, courseCode: _fCode } = qs.parse(location.search.slice(1))
  const inputRef = useRef()

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const { options: courseOptions, loading: optionsLoading } = useCourseOptions()

  const [searchKey, setSearchKey] = useState(keyword)
  useEffect(() => setSearchKey(keyword), [keyword])

  const columns = () => _.compact([
    { title: 'รหัสเอกสาร', key: 'documentId', align: 'center', render: ({ documentId }) => documentId },
    { title: 'ชื่อเอกสาร', key: 'documentName', render: ({ documentName }) => documentName },
    { title: 'รหัสคอร์ส', key: 'courseCode', align: 'center', render: ({ courseCode }) => courseCode },
    { title: 'ขนาดไฟล์', key: 'fileSize', align: 'center', render: ({ fileSize }) => `${(fileSize / 1024 / 1024).toFixed(2)} MB` },
    { title: 'ไฟล์แนบ', key: 'file', align: 'center', render: ({ filePath }) => <a target='_blank' href={filePath}><Button icon='eye'>เปิดดูไฟล์</Button></a>},
    { title: 'วันที่อัพโหลด', key: 'createdAt', render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY')},
    editable ? { title: '',
      key: 'buttons',
      render: ({ documentId, documentName, courseCode, filePath }) =>
        <div>
          <Button onClick={() => {
            setDocumentId(documentId)
            setDocumentName(documentName)
            setCourseCode(courseCode)
            setFilePath(filePath)
            setModalVisible(true)
          }}>แก้ไข</Button>
          <CustomPopconfirm
              onClick={e => e.stopPropagation()}
              title={`ยืนยันที่จะลบเอกสารเฉลย ${documentName}`}
              onConfirm={() => _onDocumentRemove(documentId)}>
              <Button type='danger' disabled={removing} loading={removing} style={{ marginLeft: 10 }}>ลบ</Button>
          </CustomPopconfirm>
        </div>
    } : null
  ])

  useEffect(() => {
    const _initialFetch = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/documents',
          query: { documentType: 'answer', courseCode: _fCode }
        })
        setDocuments(result)
        setLoading(false)
      } catch (error) {
        console.error('Error while trying to fetch documents.', error)
        setLoading(false)
        setDocuments([])
      }
    }

    _initialFetch()
  }, [_fCode])

  const [adding, setAdding] = useState(false)
  const [documentId, setDocumentId] = useState()
  const [documentName, setDocumentName] = useState('')
  const [courseCode, setCourseCode] = useState('')
  const [file, setFile] = useState()
  const [filePath, setFilePath] = useState()
  const _onDocumentAdd = async () => {
    try {
      setAdding(true)
      const filePath = await uploadFile(file)
      const { result } = await callAPI({
        method: 'POST',
        url: '/documents',
        body: {
          documentName,
          courseCode,
          filePath,
          fileSize: file.size
        }
      })
      setDocuments(_documents => [result].concat(_documents))
      setAdding(false)
      setModalVisible(false)
      message.success('เพิ่มเอกสารเฉลยสำเร็จ')
    } catch (error) {
      console.error(new Error(`Error while trying to add a document: ${error.message}.`))
      setAdding(false)
      message.error('เพิ่มเอกสารไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [saving, setSaving] = useState(false)
  const _onDocumentSave = async () => {
    try {
      setSaving(true)
      const filePath = file ? await uploadFile(file) : null
      const fileSize = file ? file.size : null
      const { result } = await callAPI({
        method: 'PUT',
        url: `/documents/${documentId}`,
        body: {
          documentName,
          courseCode,
          filePath,
          fileSize
        }
      })
      setDocuments(_documents => _documents.map(_doc => _doc.documentId === result.documentId ? result : _doc))
      setSaving(false)
      setModalVisible(false)
      message.success('บันทึกเอกสารเฉลยสำเร็จ')
    } catch (error) {
      console.error(`Error while trying to save a document of ID: ${documentId}`, error.message)
      setSaving(false)
      message.error('บันทึกเอกสารเฉลยไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onDocumentRemove = async (documentId) => {
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/documents/${documentId}`
      })
      setRemoving(false)
      setDocuments(_documents => _documents.filter(_doc => _doc.documentId !== documentId))
      message.success('ลบเอกสารเฉลยสำเร็จ')
    } catch (error) {
      console.error(`Error while trying to remove a document of ID ${documentId}`, error.message)
      setRemoving(false)
      message.error(`ลบเอกสารไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง`)
    }
  }

  useEffect(() => {
    if (!modalVisible) {
      setDocumentId()
      setDocumentName('')
      setCourseCode('')
      setFile()
      setFilePath()
    }
  }, [modalVisible])

  const disabled = !documentName || !courseCode || (!file && !filePath) || adding || saving
  const filteredDocuments = documents.filter(doc => !keyword || doc.documentName.includes(keyword))
  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title={`เฉลยการบ้าน/เฉลยข้อสอบ (${documents.length})`}
          buttonProps={{
            onClick: () => setModalVisible(true)
          }}
          buttonTitle='เพิ่มเฉลยการบ้าน/เฉลยข้อสอบ' />
        <div style={{ position: 'relative' }}>
          <SearchWrapper>
            <Input.Search
              ref={inputRef}
              placeholder='ค้นหาชื่อเอกสารเฉลย'
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
              onSearch={() => history.push(`/answers?keyword=${searchKey}&courseCode=${_fCode}`)} />
          </SearchWrapper>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <span>ค้นหาด้วยรหัสคอร์สเรียน</span>
            <FormSelect
              loading={optionsLoading}
              style={{ marginLeft: 10, paddingLeft: 5, width: 200 }}
              placeholder='คอร์สเรียน'
              value={_fCode}
              onChange={e => history.push(`/answers?keyword=${searchKey}&courseCode=${e.target.value}`)}>
              <option value=''>แสดงทั้งหมด</option>
              {courseOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
            </FormSelect>
          </div>
        </div>
        {loading ? (
          <Body><Spinner /></Body>
        ) : filteredDocuments.length ? (
          <Body style={{ padding: 0 }}>
            <Table
              rowKey={({ documentId }) => documentId}
              columns={columns()}
              dataSource={filteredDocuments} />
          </Body>
        ) : documents.length ? (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ไม่พบข้อมูลเฉลยที่ค้นหา</span>}>
              <Button type='primary' icon='search' onClick={() => {
                inputRef.current.focus()
                setSearchKey('')
              }}>ค้นหาด้วยชื่อเอกสารเฉลยอื่น</Button>
            </Empty>
          </Body>
        ) : (
          <Body>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลเฉลย</span>}>
              <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มเฉลยแรกของคุณ</Button>
            </Empty>
          </Body>
        )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title={documentId ? 'แก้ไขข้อมูลเฉลย' : 'เพิ่มข้อมูลเฉลย'}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setModalVisible(false)}>ย้อนกลับ</Button>,
          <Button
            key='submit'
            type='primary'
            disabled={disabled}
            loading={documentId ? saving : adding}
            onClick={documentId ? _onDocumentSave : _onDocumentAdd}>
            {documentId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}
          </Button>
        ]}>
          <FormGroup>
            <Label className='required'>ชื่อเอกสารเฉลย</Label>
            <Input
              type='text'
              placeholder='กรุณากรอกชื่อเอกสารเฉลยการบ้าน/เฉลยข้อสอบ'
              value={documentName}
              onChange={e => setDocumentName(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label className='required'>คอร์สเรียน</Label>
            <SelectWrapper>
              <FormSelect
                disabled={optionsLoading}
                loading={optionsLoading}
                style={{ width: '100%' }}
                placeholder='เลือกคอร์สเรียน'
                value={courseCode}
                onChange={e => setCourseCode(e.target.value)}>
                <option value=''></option>
                {courseOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
              </FormSelect>
            </SelectWrapper>
          </FormGroup>
          <FormGroup>
            <Label className={!filePath && 'required'}>อัพโหลดเอกสารเฉลย</Label>
            <div>
              {filePath && (
                <a target='_blank' href={filePath}>
                  <Button style={{ marginRight: 10 }} type='eye'>เปิดดูไฟล์</Button>
                </a>
              )}
              <input type='file'
                onChange={e => setFile(e.target.files[0])} />
            </div>
          </FormGroup>
        </Modal>
    </section>
  )
}

export default Documents

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const SearchWrapper = styled.div`
  width: 50%; 
  margin-bottom: 20px;
`
