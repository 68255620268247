import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { callAPI } from 'helpers'
import { compact, groupBy, maxBy } from 'lodash'
import moment from 'moment'

// Components
import styled from 'styled-components'
import Spinner from 'components/Spinner'
import { Button, Table, Modal, DatePicker, message } from 'antd'
import useOnlineClassOptions from 'hooks/useOnlineClassOptions'
import FormGroup from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import Label from 'components/Label'
import CustomPopconfirm from 'components/CustomPopconfirm'

// Hooks
import { useEditable } from 'hooks/useEditable'

const StudentTrialClasses = () => {
  const match = useRouteMatch()
  const studentId = match.params.studentId
  const editable = useEditable({ studentId })
  const { options: classOptions } = useOnlineClassOptions()

  const [loading, setLoading] = useState(false)
  const [trials, setTrials] = useState([])
  useEffect(() => {
    const fetchTrials = async () => {
      try {
        setLoading(true)
        const { result } = await callAPI({
          url: '/trials',
          query: { studentId }
        })
        setLoading(false)
        setTrials(result)
      } catch (error) {
        console.error('Error while trying to fetch trial classes.', error)
        setLoading(false)
      }
    }
    fetchTrials()
  }, [])

  const [modalVisible, setModalVisible] = useState(false)
  const [expirationMoment, setExpirationMoment] = useState()
  const [classId, setClassId] = useState()

  useEffect(() => {
    setExpirationMoment(moment().add(1, 'year'))
    setClassId()
  }, [modalVisible])

  const [adding, setAdding] = useState(false)
  const _onTrialAdd = async () => {
    if (!editable) return
    try {
      setAdding(true)
      const { result } = await callAPI({
        method: 'POST',
        url: '/trials',
        body: {
          studentId,
          expirationDate: expirationMoment.format('YYYY-MM-DD'),
          classId
        }
      })
      setTrials(_trials => [result].concat(_trials))
      setModalVisible(false)
      setAdding(false)
      message.success('เพิ่มข้อมูลการทดลองเรียนสำเร็จ')
    } catch (error) {
      console.error('Error while trying to add a new trial.', error)
      setAdding(false)
      message.error('เพิ่มข้อมูลการทดลองเรียนไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState({})
  const _onTrialRemove = async (_trialId) => {
    if (!editable) return
    try {
      setRemoving({ ...removing, [_trialId]: true })
      await callAPI({
        method: 'DELETE',
        url: `/trials/${_trialId}`
      })
      setTrials(_trials => _trials.filter(({ enrollmentId }) => enrollmentId !== _trialId))
      setRemoving({ ...removing, [_trialId]: false })
      message.success('ลบข้อมูลการทดลองเรียนสำเร็จ!')
    } catch (error) {
      console.error('Error whie trying to remove an enrollment.', error)
      setRemoving({ ...removing, [_trialId]: false })
      message.error('ลบข้อมูลการทดลองเรียนไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const columns = compact([
    { key: 'trialId', title: 'รหัสทดลองเรียน', align: 'center', render: ({ enrollmentId }) => enrollmentId },
    { key: 'expirationDate', title: 'วันที่หมดอายุ', align: 'center', render: ({ expirationDate }) => moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY') },
    { key: 'semesterCode', title: 'ภาคเรียน', align: 'center', render: ({ semesterCode }) => semesterCode },
    { key: 'classCode', title: 'รหัสวิชา', align: 'center', render: ({ classCode }) => classCode },
    { key: 'className', title: 'ชื่อรอบ', render: ({ className }) => className },
    {
      key: 'buttons',
      title: '',
      width: 110,
      render: ({ enrollmentId }) => {
        const _removing = removing[enrollmentId]
        if (!editable) return null
        return (
          <div>
            <CustomPopconfirm
              disabled={_removing}
              title={`ยืนยันที่จะลบการทดลองเรียนรหัส ${enrollmentId} ?`}
              onConfirm={() => _onTrialRemove(enrollmentId)}>
              <Button loading={_removing} disabled={_removing} type='danger' icon='delete' />
            </CustomPopconfirm>
          </div>
        )
      }
    }
  ])

  const optionGroups = groupBy(classOptions, 'classCode')
  const filteredClassOptions = Object.keys(optionGroups).map(key => {
    return maxBy(optionGroups[key], 'label')
  })
    .slice()
    .sort((a, b) => a.classCode.localeCompare(b.classCode))

  if (loading) return <section><Spinner /></section>
  return (
    <section>
      <ButtonRow>
        {editable && (
          <Button
            disabled={trials.length >= 5}
            type='primary'
            icon='plus'
            onClick={() => setModalVisible(true)}>เพิ่มข้อมูลทดลองเรียน</Button>
        )}
      </ButtonRow>
      <Table
        style={{ backgroundColor: 'white' }}
        rowKey={({ enrollmentId }) => enrollmentId}
        columns={columns}
        dataSource={trials}
        pagination={false} />
      <Modal
        visible={modalVisible}
        title='เพิ่มการทดลองเรียน'
        okText='บันทึกข้อมูล'
        okButtonProps={{
          loading: adding,
          disabled: adding
        }}
        onOk={_onTrialAdd}
        cancelText='ย้อนกลับ'
        onCancel={() => setModalVisible(false)}>
        <FormGroup>
          <Label>วันที่หมดอายุ</Label>
          <div>
            <DatePicker
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
              value={expirationMoment}
              onChange={_moment => setExpirationMoment(_moment)} />
          </div>
        </FormGroup>
        <FormGroup>
          <Label>รหัสวิชา</Label>
          <div>
            <FormSelect
              style={{ width: '100%' }}
              value={classId}
              onChange={e => setClassId(e.target.value)}>
              <option />
              {filteredClassOptions.map(({ label, value, classCode }) => <option key={value} value={value}>{classCode}: {label}</option>)}
            </FormSelect>
          </div>
        </FormGroup>
      </Modal>
    </section>
  )
}

export default StudentTrialClasses

const ButtonRow = styled.div`
  text-align: right;
  margin-bottom: 15px;
`
