import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Login from 'containers/Login'
import './index.css'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './antd.min.css'

import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'

import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const middleware = []
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Route exact path='/login' component={Login} />
        <Route path='/' component={App} />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)
