import React from 'react'
import { Icon, Popconfirm } from 'antd'

export default (props) =>
  <Popconfirm
    icon={<Icon type='question-circle-o' style={{ color: '#d9534f' }} />}
    okType={props.okType || 'danger'}
    okText={props.okText || 'ตกลง'}
    cancelText='ยกเลิก'
    {...props} />
