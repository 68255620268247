import React from 'react'

// Components
import Heading from 'components/Heading'

import SectionPaidStudentRatio from 'components/analytics/dashboard/SectionPaidStudentRatio'
import SectionOrdersByMonth from 'components/analytics/dashboard/SectionOrdersByMonth'
import SectionOrdersByRegionAndCourse from 'components/analytics/dashboard/SectionOrdersByRegionAndCourse'

// Hooks
import { useEditable } from 'hooks/useEditable'

const Dashboard = () => {
  useEditable()
  return (
    <div>
      <Heading title='Dashboard' />
      <section>
        <SectionPaidStudentRatio />
        <SectionOrdersByMonth />
        <SectionOrdersByRegionAndCourse />
      </section>
    </div>
  )
}

export default Dashboard
