const INITIAL_STATE = {
  courseOptions: [],
  examOptions: [],
  onlineclassOptions: [],
  me: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COURSE_OPTIONS_UPDATED':
      return { ...state, courseOptions: action.options || [], courseOptionsLoaded: true }
    case 'EXAM_OPTIONS_UPDATED':
      return { ...state, examOptions: action.options || [], examOptionsLoaded: true }
    case 'ONLINE_CLASS_OPTIONS_UPDATED':
      return { ...state, onlineclassOptions: action.options || [], onlineclassOptionsLoaded: true }
    case 'UPDATE_ME':
      return { ...state, me: action.me }
    default:
      return state
  }
}
