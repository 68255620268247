import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getTheme, callAPI, formatComma, viewportHeight } from 'helpers'
import theme from 'theme'
import { get, find } from 'lodash'
import moment from 'moment'
import qs from 'qs'

// Containers
import ClassDetail from 'containers/ClassDetail'

// Components
import InfiniteScroll from 'react-infinite-scroller'
import Spinner from 'components/Spinner'
import GridContainer from 'components/GridContainer'
import Heading from 'components/Heading'
import FormGroup from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import CustomPopconfirm from 'components/CustomPopconfirm'
import { Empty, Button, Table, Modal, Icon, Input, DatePicker, Radio, message } from 'antd'

// Hooks
import { useCourseOptions } from 'hooks/custom'
import { useEditable } from 'hooks/useEditable'

const LIMIT = 1000

export default function () {
  const editable = useEditable()
  const location = useLocation()
  const history = useHistory()

  const { loading: optionsLoading, options: courseOptions } = useCourseOptions()
  const [loading, setLoading] = useState(false)
  const [onlineClasses, setClasses] = useState([])
  const [classCount, setClassCount] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  // Modal state.
  const [classId, setClassId] = useState()
  const [courseCode, setCourseCode] = useState('')
  const [classFee, setClassFee] = useState('')
  const [{ classStartDate, classEndDate }, setClassPeriod] = useState({ classStartDate: null, classEndDate: null })
  const [expirationMoment, setExpirationMoment] = useState('')
  const [isActive, setActive] = useState(1)
  const [quotaLimit, setQuotaLimit] = useState('')
  const [quotaLimitLoading, setQuotaLimitLoading] = useState(false)

  const thisYear = moment().year() + 543
  const semesterOptions = Array(10).fill()
    .map((_, index) => index)
    .reduce((array, key) => array.concat([
      { label: `ปิดเทอม Summer ปี ${thisYear - 6 + key}`, value: `${thisYear - 6 + key}/1` },
      { label: `เปิดเทอม 1 ปี ${thisYear - 6 + key}`, value: `${thisYear - 6 + key}/2` },
      { label: `ปิดเทอม ต.ค. ปี ${thisYear - 6 + key}`, value: `${thisYear - 6 + key}/3` },
      { label: `เปิดเทอม 2 ปี ${thisYear - 6 + key}`, value: `${thisYear - 6 + key}/4` }
    ]), [])
  const [semesterCode, setSemesterCode] = useState(get(semesterOptions, '0.value'))

  const currentPage = parseInt(get(qs.parse(location.search.slice(1)), 'page') || 1, 10)
  const offset = (currentPage - 1) * LIMIT

  useEffect(() => {
    const initialFetch = async () => {
      try {
        setLoading(true)
        const [{ result: _classCount }, { result: _classes }] = await Promise.all([
          callAPI({ url: '/online-classes/count' }),
          callAPI({ url: '/online-classes', query: { offset, limit: LIMIT } })
        ])
        setLoading(false)
        setClassCount(_classCount)
        setClasses(_classes)
        setHasMore(_classes.length === LIMIT)

        if (offset > 0 && _classCount && !_classes.length) { history.replace('/online-classes') }
      } catch (error) {
        console.error('Error while trying to fetch online courses.', error)
        setLoading(false)
        setClasses([])
      }
    }

    initialFetch()
  }, [])

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        setLoading(true)
        const { result: _classes } = await callAPI({
          url: '/online-classes',
          query: { offset, limit: LIMIT }
        })
        setLoading(false)
        setClasses(_classes)
      } catch (error) {
        console.error('Error while trying to fetch classes.', error)
        setLoading(false)
        setClasses([])
      }
    }

    fetchClasses()
  }, [offset])

  const _onLoadMore = async () => {
    if (loading || !hasMore) return
    try {
      setLoading(true)
      const { result: _classes } = await callAPI({
        url: '/online-classes',
        query: { offset: onlineClasses.length, limit: LIMIT }
      })

      setLoading(false)
      setClasses(_classes)
      setHasMore(_classes.length === LIMIT)
    } catch (error) {
      console.error('Error while trying to load more.', error)
      setLoading(false)
      setClasses([])
    }
  }

  const _onOpenModal = () => {
    setModalVisible(true)
  }
  const _onCloseModal = () => {
    setModalVisible(false)
  }

  const [adding, setAdding] = useState(false)
  const _onClassAdd = async () => {
    if (!editable || adding) return
    try {
      setAdding(true)
      const { result: newClass } = await callAPI({
        method: 'POST',
        url: '/online-classes',
        body: {
          courseCode,
          semesterCode,
          classFee,
          classStartDate: classStartDate.format('YYYY-MM-DD'),
          classEndDate: classEndDate.format('YYYY-MM-DD'),
          expirationDate: expirationMoment
            ? expirationMoment.format('YYYY-MM-DD')
            : null,
          quotaLimit
        }
      })
      setAdding(false)
      setModalVisible(false)
      setClasses([newClass].concat(onlineClasses))
      message.success(`เพิ่มคอร์สออนไลน์ ${courseCode} สำเร็จ`)
    } catch (error) {
      console.error('Error while trying to add new online class.', error)
      setAdding(false)
      message.error(`เพิ่มคอร์สออนไลน์ ${courseCode} ไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง`)
    }
  }

  const [saving, setSaving] = useState(false)
  const _onClassSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result: savedClass } = await callAPI({
        method: 'PUT',
        url: `/online-classes/${classId}`,
        body: {
          courseCode,
          semesterCode,
          classFee,
          classStartDate: classStartDate.format('YYYY-MM-DD'),
          classEndDate: classEndDate.format('YYYY-MM-DD'),
          expirationDate: expirationMoment
            ? expirationMoment.format('YYYY-MM-DD')
            : null,
          quotaLimit,
          isActive
        }
      })
      setSaving(false)
      setModalVisible(false)
      setClasses(_classes => _classes.map(_class => _class.classId === savedClass.classId ? savedClass : _class))
      message.success('บันทึกคอร์สสำเร็จ')
    } catch (error) {
      console.error('Error while tring to save an online class.', error)
      setSaving(false)
      message.error('บันทึกคอร์สออนไลน์ไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onClassRemove = async (_classId) => {
    if (!editable || removing) return
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/online-classes/${_classId}`
      })
      setRemoving(false)
      setClasses(_classes => _classes.filter(_class => _class.classId !== _classId))
    } catch (error) {
      console.error('Error while trying to remove an online class.', error)
      setRemoving(false)
      message.error('ลบคอร์สออนไลน์ไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const _onRowClick = (_classId) => {
    if (!editable) return
    setModalVisible(true)
    const { courseCode, semesterCode, classFee, classStartDate, classEndDate, quotaLimit, expirationDate, isActive } = find(onlineClasses, ({ classId }) => _classId === classId)
    setClassId(_classId)
    setCourseCode(courseCode)
    setSemesterCode(semesterCode)
    setClassFee(classFee ? parseInt(classFee, 10) : '')
    setClassPeriod({
      classStartDate: classStartDate ? moment(classStartDate, 'YYYY-MM-DD') : null,
      classEndDate: classEndDate ? moment(classEndDate, 'YYYY-MM-DD') : null
    })
    setExpirationMoment(expirationDate ? moment(expirationDate, 'YYYY-MM-DD') : null)
    setQuotaLimit(quotaLimit || '')
    setActive(isActive)
  }

  useEffect(() => {
    const fetchQuotaLimit = async () => {
      try {
        setQuotaLimitLoading(true)
        const { result: { quotaLimit } } = await callAPI({
          url: `/online-classes/${classId}/quota-limit`
        })
        setQuotaLimit(quotaLimit)
        setQuotaLimitLoading(false)
        setClasses(_classes => _classes.map(_class => _class.classId === classId
          ? { ..._class, quotaLimit: _class.quotaLimit || quotaLimit }
          : _class
        ))
      } catch (error) {
        console.error('Error while trying to fetch total duration.', error)
        setQuotaLimitLoading(false)
      }
    }
    if (classId && !quotaLimit) { fetchQuotaLimit() }
  }, [classId])

  const columns = _.compact([
    { title: 'รหัสรอบ', key: 'classCode', render: ({ classId, classCode }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{classCode}</Col> },
    { title: 'ภาคเรียน', key: 'semesterCode', render: ({ classId, semesterCode }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{semesterCode}</Col> },
    { title: 'ชื่อวิชา', key: 'course', render: ({ classId, courseCode, courseName }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{`${courseCode}: ${courseName}`}</Col> },
    { title: 'ค่าเรียน', key: 'classFee', render: ({ classId, classFee }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{formatComma(classFee)}</Col> },
    { title: 'จำนวนชั่วโมง', key: 'quotaLimit', align: 'center', render: ({ classId, quotaLimit }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{quotaLimit || '-'}</Col> },
    { title: 'จากวันที่', key: 'classStartDate', render: ({ classId, classStartDate }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{moment(classStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Col> },
    { title: 'ถึงวันที่', key: 'classEndDate', render: ({ classId, classEndDate }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{moment(classEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Col> },
    { title: 'วันหมดอายุ', key: 'expirationDate', align: 'center', render: ({ classId, expirationDate }) => <Col className={editable ? 'enabled' : ''} onClick={() => _onRowClick(classId)}>{expirationDate ? moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</Col> },
    {
      title: 'สถานะ',
      key: 'isActive',
      align: 'center',
      render: ({ _id, isActive }) => isActive
        ? <Col className={editable ? 'enabled' : ''} style={{ color: theme.colors.green }} onClick={() => _onRowClick(_id)}>เปิดใช้งาน</Col>
        : <Col className={editable ? 'enabled' : ''} style={{ color: theme.colors.red }} onClick={() => _onRowClick(_id)}>ไม่เปิดใช้งาน</Col>
    }, editable ? {
    title: '',
    key: 'buttons',
    width: 110,
    render: ({ classId, classCode, semesterCode }) => (
      <div>
        <Button
          disabled={removing}
          style={{ marginRight: 10 }}
          icon='edit'
          onClick={() => history.push(`/online-classes/${classId}`)} />
        <CustomPopconfirm
          disabled={removing}
          title={`ยืนยันที่จะลบรอบ ${classCode} ของภาคเรียน ${semesterCode} ?`}
          icon={<Icon type='question-circle-o' />}
          onConfirm={() => _onClassRemove(classId)}>
          <Button loading={removing} disabled={removing} type='danger' icon='delete' />
        </CustomPopconfirm>
      </div>
    )
  } : null])

  return (
    <section>
      {location.pathname === '/online-classes' && (
        <GridContainer>
          <Heading
            disabled={!editable}
            title={`คอร์สออนไลน์ ${classCount ? `(${classCount})` : ''}`}
            buttonProps={{
              onClick: () => _onOpenModal(),
              disabled: optionsLoading
            }}
            buttonTitle='เพิ่มคอร์สออนไลน์' />
          {loading ? (
            <Body><Spinner /></Body>
          ) : onlineClasses.length ? (
            <Body style={{ padding: 0 }}>
              <Desktop>
                <Table
                  rowKey={({ classId }) => classId}
                  columns={columns}
                  dataSource={onlineClasses}
                  pagination={false} />
              </Desktop>
              <Mobile>
                <InfiniteScroll
                  initialLoad={false}
                  threshold={viewportHeight() / 3}
                  loadMore={_onLoadMore}
                  hasMore={hasMore}>
                  {onlineClasses.map(_class =>
                    <ClassItem
                      key={_class.classId}
                      class={_class}
                      onEdit={() => history.push(`/online-classes/${_class.classId}`)}
                      onRemove={() => _onClassRemove(_class.classId)}
                      removing={removing === _class.classId} />
                  )}
                </InfiniteScroll>
              </Mobile>
            </Body>
          ) : (
            <Body>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>ยังไม่มีข้อมูลคอร์สออนไลน์</span>}>
                {editable && (
                  <Button type='primary' icon='plus' onClick={() => _onOpenModal()}>เพิ่มคอร์สออนไลน์แรกของคุณ</Button>
                )}
              </Empty>
            </Body>
          )}
          <Modal visible={modalVisible}
            title={classId ? 'แก้ไขคอร์สออนไลน์' : 'เพิ่มคอร์สออนไลน์'}
            onCancel={_onCloseModal}
            footer={[
              <Button key='cancel' onClick={_onCloseModal}>ย้อนกลับ</Button>,
              <Button key='submit' type='primary'
                loading={classId ? saving : adding}
                onClick={classId ? _onClassSave : _onClassAdd}>
                {classId ? 'บันทึกข้อมูล' : 'เพิ่มคอร์สออนไลน์'}
              </Button>
            ]}>
            <FormGroup>
              <Label>วิชา</Label>
              <FormSelect
                style={{ width: '75%' }}
                value={courseCode}
                onChange={e => setCourseCode(e.target.value)}>
                {courseOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
              </FormSelect>
            </FormGroup>
            <FormGroup>
              <Label>ภาคเรียน</Label>
              <FormSelect
                style={{ width: '75%' }}
                value={semesterCode}
                onChange={e => setSemesterCode(e.target.value)}>
                {semesterOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
              </FormSelect>
            </FormGroup>
            <FormGroup>
              <Label>ค่าเรียน</Label>
              <Input style={{ width: '75%' }}
                value={classFee}
                onChange={e => {
                  const _classFee = e.target.value
                  if (_classFee && isNaN(_classFee)) return
                  setClassFee(_classFee)
                }} />
            </FormGroup>
            <FormGroup>
              <Label>ช่วงเวลา</Label>
              <DatePicker.RangePicker
                style={{ width: '75%' }}
                format='DD/MM/YYYY'
                placeholder={['วันที่เริ่มต้นการเรียน', 'วันที่สิ้นสุดการขาย']}
                value={[classStartDate, classEndDate]}
                onChange={([classStartDate, classEndDate]) => setClassPeriod({ classStartDate, classEndDate })} />
            </FormGroup>
            <FormGroup>
              <Label>จำนวนชั่วโมง</Label>
              <Input style={{ width: '75%' }}
                addonBefore={
                  !quotaLimitLoading
                    ? null
                    : <Spinner text=' '
                      iconStyle={{ fontSize: 16, marginBottom: 0 }}
                      style={{ margin: 0 }} />}
                disabled={quotaLimitLoading}
                value={quotaLimit}
                onChange={e => {
                  const _duration = e.target.value
                  if (_duration && isNaN(_duration)) return
                  setQuotaLimit(_duration)
                }} />
            </FormGroup>
            <FormGroup>
              <Label>วันหมดอายุ</Label>
              <DatePicker
                placeholder='เลือกวันหมดอายุ (ถ้ามี)'
                style={{ width: '75%' }}
                format='DD/MM/YYYY'
                value={expirationMoment}
                onChange={_moment => setExpirationMoment(_moment)} />
            </FormGroup>
            {Boolean(classId) && (
              <FormGroup>
                <Label>สถานะการใช้งาน</Label>
                <Radio.Group onChange={(e) => setActive(e.target.value)} value={isActive}>
                  <Radio value={1}>ใช้งาน</Radio>
                  <Radio value={0}>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </FormGroup>
            )}
          </Modal>
        </GridContainer>
      )}
      <Route exact path='/online-classes/:classId' component={ClassDetail} />
    </section>
  )
}

const Body = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.border`};
  padding: 20px;
`
const Label = styled.div`
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  font-weight: bold;
  width: 25%;
`

const Desktop = styled.div`
  @media(max-width: ${getTheme`media.mobile`}) { display: none; }
`
const Mobile = styled.div`
  @media(min-width: ${getTheme`media.desktop`}) { display: none; }
`

const ClassItem = ({ onEdit, onRemove, removing, class: { classCode, title, semesterCode, courseCode, courseName, classFee, classStartDate, classEndDate } }) => {
  return (
    <ExamItemWrapper>
      <ExamItemRow>
        <ExamItemLabel>รหัสรอบ</ExamItemLabel>
        <ExamItemValue>{classCode}</ExamItemValue>
      </ExamItemRow>
      <ExamItemRow>
        <ExamItemLabel>ภาคเรียน</ExamItemLabel>
        <ExamItemValue>{semesterCode}</ExamItemValue>
      </ExamItemRow>
      <ExamItemRow>
        <ExamItemLabel>ชื่อวิชา</ExamItemLabel>
        <ExamItemValue>{courseCode}: {courseName}</ExamItemValue>
      </ExamItemRow>
      <ExamItemRow>
        <ExamItemLabel>ค่าเรียน</ExamItemLabel>
        <ExamItemValue>{classFee}</ExamItemValue>
      </ExamItemRow>
      <ExamItemRow>
        <ExamItemLabel>จากวันที่</ExamItemLabel>
        <ExamItemValue>{moment(classStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</ExamItemValue>
      </ExamItemRow>
      <ExamItemRow>
        <ExamItemLabel>ถึงวันที่</ExamItemLabel>
        <ExamItemValue>{moment(classEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</ExamItemValue>
      </ExamItemRow>
      <div style={{ marginTop: 15 }}>
        <Button
          style={{ width: 'calc(50% - 5px)', maxWidth: 160, marginRight: 10 }}
          icon='edit'
          onClick={onEdit}>
          <span>แก้ไขคอร์ส</span>
        </Button>
        <CustomPopconfirm
          title={`ยืนยันที่จะลบคอร์ส "${title}" ?`}
          icon={<Icon type='question-circle-o' />}
          onConfirm={onRemove}>
          <Button
            style={{ width: 'calc(50% - 5px)', maxWidth: 160 }}
            type='danger'
            icon='delete'
            loading={removing}
            disabled={removing}>
            <span>ลบคอร์ส</span>
          </Button>
        </CustomPopconfirm>
      </div>
    </ExamItemWrapper>
  )
}
const ExamItemWrapper = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${getTheme`colors.border`};
  background-color: white;
`
const ExamItemRow = styled.div`
  line-height: 30px;
`
const ExamItemLabel = styled.div`
  display: inline-block;
  vertical-align: top;
  color: black;
  font-weight: 700;
  width: 50%;
`
const ExamItemValue = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`
const Col = styled.div`
  &.enabled {
    cursor: pointer;
    &:hover { text-decoration: underline; }
  }
`
