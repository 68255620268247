import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useEditable = (map = {}) => {
  const location = useLocation()
  const history = useHistory()

  if (Object.keys(map).length) {
    const permission = useSelector(state =>
      _.find(
        _.get(state, 'app.me.permissions') || [],
        ({ pathname }) => location.pathname === Object.keys(map).reduce((string, key) => string.replace(`:${key}`, map[key]), pathname)
      )
    )
    const role = useSelector(state => _.get(state, 'app.me.roleName'))
    const loadable =  role === 'Owner' || _.get(permission, 'loadable')
    // useEffect(() => {
    //   if (!loadable) { history.replace('/profile') }
    // }, [history, loadable])
  
    return role === 'Owner' || Boolean(_.get(permission, 'editable'))
  }

  const permission = useSelector(state => _.find(_.get(state, 'app.me.permissions') || [], ({ pathname }) => location.pathname.startsWith(pathname)))
  const role = useSelector(state => _.get(state, 'app.me.roleName'))
  const loadable =  role === 'Owner' || _.get(permission, 'loadable')
  // useEffect(() => {
  //   if (!loadable) { history.replace('/profile') }
  // }, [history, loadable])

  return role === 'Owner' || Boolean(_.get(permission, 'editable'))
}