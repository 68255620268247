import React, { useState, useRef } from 'react'
import { get } from 'lodash'

// Components
import CustomModal from 'components/CustomModal'
import ClassItemForm from './ClassItemForm'
import { callAPI } from 'helpers'

export default ({ classItem, onSave, onCancel }) => {
  const itemFormRef = useRef()
  const [saving, setSaving] = useState()

  if (!classItem) return null

  const classId = get(classItem, 'classId')
  const classItemId = get(classItem, 'classItemId')
  const _onItemSave = async () => {
    const formData = itemFormRef.current.getFormData()
    if (formData) {
      const { selectedRadio, vimeoId, videoTitle, videoDescription, classExamId } = formData
      if (selectedRadio === 'video') {
        try {
          setSaving(true)
          const { result: savedItem } = await callAPI({
            method: 'PUT',
            url: `online-classes/${classId}/items/${classItemId}`,
            body: {
              vimeoId,
              videoTitle,
              videoDescription
            }
          })
          setSaving(false)
          onSave(savedItem)
        } catch (error) {
          console.error('Error while trying to save a class video item.', error)
          setSaving(false)
        }
      } else if (selectedRadio === 'exam') {
        try {
          setSaving(true)
          const { result: savedItem } = await callAPI({
            method: 'PUT',
            url: `online-classes/${classId}/items/${classItemId}`,
            body: { classExamId }
          })
          setSaving(false)
          onSave(savedItem)
        } catch (error) {
          console.error('Error while trying to save a class exam item.', error)
          setSaving(false)
        }
      }
    }
  }

  return (
    <CustomModal visible
      title='แก้ไขบทเรียน / แบบทดสอบ'
      cancelText='ย้อนกลับ'
      okText='บันทึกข้อมูล'
      okButtonProps={{
        loading: saving,
        icon: 'save'
      }}
      onCancel={onCancel}
      onOk={_onItemSave}>
      <ClassItemForm
        ref={itemFormRef}
        hideRadios
        classItem={classItem} />
    </CustomModal>
  )
}
